<template>
  <div style="display: flex; height: 700px">
    <div style="margin-top: 0px">
      <el-form ref="form" :model="form" label-width="190px">
        <el-form-item label="设备名称："> {{ form.deviceName }}</el-form-item>
        <el-form-item label="健康度：">
          <el-form-item v-if="form.healthDegree">
            {{ form.healthDegree }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="合规状态：">
          <el-form-item v-if="form.isViolation == 0"> 违规 </el-form-item>
          <el-form-item v-else-if="form.isViolation == 1"> 正常 </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.isViolation == 1 ? "正常" : "违规" }} -->
        </el-form-item>
        <el-form-item label="设备越狱：">
          <el-form-item v-if="form.isJailBreak == '1'"> 已越狱（Root） </el-form-item>
          <el-form-item v-else-if="form.isJailBreak == '0'"> 正常 </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.isJailBreak == 1 ? "正常" : "已越狱（Root）" }} -->
        </el-form-item>
        <el-form-item label="设备状态：">
          <el-form-item v-if="form.status == 5"> 新建 </el-form-item>
          <el-form-item v-if="form.status == 15"> 加入 </el-form-item>
          <el-form-item v-if="form.status == 25"> 注销 </el-form-item>
          <el-form-item v-if="form.status == 35"> 停用 </el-form-item>
        </el-form-item>
        <!-- <el-form-item label="绑定状态：">
          <el-form-item v-if="form.bindStatus == 1"> 绑定 </el-form-item>
          <el-form-item v-if="form.bindStatus == 0"> 解绑 </el-form-item> 
        </el-form-item> -->
        <el-form-item label="在线状态：">
          <el-form-item v-if="form.deviceConIMStatus.conStatus == 0"> 离线 </el-form-item>
          <el-form-item v-else-if="form.deviceConIMStatus.conStatus == 1">
            在线
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
        </el-form-item>
        <el-form-item label="隧道状态：">
          <el-form-item v-if="form.tunnelStatus == 0"> 禁用 </el-form-item>
          <el-form-item v-else-if="form.tunnelStatus == 1">
            启用
          </el-form-item>
        </el-form-item>

        <el-form-item label="蓝牙：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.bluetooth == '0'">
            关闭
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.bluetooth == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
        </el-form-item>
        <el-form-item label="蓝牙：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.bluetooth == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.bluetooth == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
        </el-form-item>

        <el-form-item label="WLAN网络：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.wifi == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.wifi == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
        </el-form-item>
        <el-form-item label="WLAN网络：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.wifi == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.wifi == '1'"> 开启 </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
        </el-form-item>

        <el-form-item label="移动网络：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.datalink == '0'">
            关闭
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.datalink == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.dataLink == 1 ? "开启" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="移动网络：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.datalink == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.datalink == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.dataLink == 1 ? "开启" : "关闭" }} -->
        </el-form-item>

        <el-form-item label="个人热点：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.hotspot == '0'">
            关闭
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.hotspot == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.hotspot == 1 ? "开启" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="个人热点：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.hotspot == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.hotspot == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.hotspot == 1 ? "开启" : "关闭" }} -->
        </el-form-item>

        <el-form-item label="系统定位：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.location == '0'">
            关闭
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.location == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> {{ 'N/A'}} </el-form-item>
          <!-- {{form.location == 1 ? "开启" : "关闭"}} -->
        </el-form-item>
        <el-form-item label="系统定位：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.location == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.location == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> {{ 'N/A'}} </el-form-item>
          <!-- {{form.location == 1 ? "开启" : "关闭"}} -->
        </el-form-item>

        <el-form-item label="USB调试：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.usb == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.usb == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> {{ 'N/A'}} </el-form-item>
          <!-- {{ form.usb == 1 ? "开启" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="USB调试：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.usb == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.usb == '1'"> 开启 </el-form-item>
          <el-form-item v-else> {{ 'N/A'}} </el-form-item>
          <!-- {{ form.usb == 1 ? "开启" : "关闭" }} -->
        </el-form-item>

        <el-form-item label="允许模拟位置：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.simulationlocation == '1'">开启
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.simulationlocation == '0'">
            关闭
          </el-form-item>
          <el-form-item v-else> {{ 'N/A'}} </el-form-item>
          <!-- {{ form.mockLocation == 1 ? "开启" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="允许模拟位置：" v-if="user.osId == 10">
          <!-- 以前字段simulationlocation    现在字段 simulatlocation -->
          <el-form-item v-if="form.iosHardwareInfo.simulatlocation == '1'">开启
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.simulatlocation == '0'">
            关闭
          </el-form-item>
          <el-form-item v-else> {{ 'N/A'}} </el-form-item>
          <!-- {{ form.mockLocation == 1 ? "开启" : "关闭" }} -->
        </el-form-item>

        <el-form-item label="模拟器：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.vm == '0'">正常 </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.vm == '1'">
            模拟器
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.vm == 1 ? "正常" : "关闭" }} -->
        </el-form-item>

        <el-form-item label="模拟器：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.vm == '0'">正常 </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.vm == '1'"> 模拟器 </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.vm == 1 ? "正常" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="设备伪造：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.fake == '0'">正常 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.fake == '1'">
            伪造
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
          <!-- <el-form-item v-else> 未知 </el-form-item> -->
          <!-- {{ form.vm == 1 ? "正常" : "关闭" }} -->
        </el-form-item>

      </el-form>
    </div>
    <div style="margin-top: 0px">
      <el-form ref="form" :model="form" label-width="300px">
        <el-form-item label="系统时间：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.systemTime == '0'"> 正常 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.systemTime == '1'">
            异常
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.rom == 1 ? "正常" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="系统时间：" v-if="user.osId == 10">
          <el-form-item v-if="deviceInfo_bank_ios.systemTime == '0'"> 正常 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank_ios.systemTime == '1'">
            异常
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.rom == 1 ? "正常" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="macOS：" v-if="user.osId == 10">
          <el-form-item v-if="deviceInfo_bank_ios.macOS == '0'"> 正常 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank_ios.macOS == '1'">
            异常
          </el-form-item>
          <el-form-item v-else> 正常 </el-form-item>
        </el-form-item>
        <el-form-item label="VPN：" v-if="user.osId == 10">
          <el-form-item v-if="deviceInfo_bank_ios.vpn == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank_ios.vpn == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 关闭 </el-form-item>
        </el-form-item>
        <el-form-item label="锁屏密码：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.screenPassword == '0'"> 开启 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.screenPassword == '1'">
            关闭
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.rom == 1 ? "正常" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="定制ROM：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.rom == '0'"> 正常 </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.rom == '1'">
            定制ROM
          </el-form-item>
          <el-form-item v-else> 未知 </el-form-item>
          <!-- {{ form.rom == 1 ? "正常" : "关闭" }} -->
        </el-form-item>
        <el-form-item label="Windows：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.windows == '0'"> 正常 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.windows == '1'">
            异常
          </el-form-item>
          <el-form-item v-else> 异常 </el-form-item>
        </el-form-item>
        <el-form-item label="VPN：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.vpn == '0'"> 关闭 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.vpn == '1'">
            开启
          </el-form-item>
          <el-form-item v-else> 关闭 </el-form-item>
        </el-form-item>
        <el-form-item label="云手机：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.cloudPhone == '0'"> 正常 </el-form-item>
          <el-form-item v-else-if="deviceInfo_bank.cloudPhone == '1'">
            异常
          </el-form-item>
          <el-form-item v-else> 异常 </el-form-item>
        </el-form-item>
        <el-form-item label="设备存储空间：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo">
            {{ form.iosHardwareInfo.devicecapacity }}
          </el-form-item>
        </el-form-item>
        <el-form-item label="设备可用存储空间：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo">
            {{ form.iosHardwareInfo.availabledevicecapacity }}
          </el-form-item>
        </el-form-item>

        <el-form-item label="客户端版本：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo">
            {{ form.androidHardwareInfo.clientversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="客户端版本：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.clientInfo">
            {{ clientInfo_ios.version }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="设备管理器：">
          <!-- 15已激活 20未激活 25设备脱离管控 -->
          <el-form-item v-if="form.isActive == 15"> 已激活 </el-form-item>
          <el-form-item v-else-if="form.isActive == 20"> 未激活 </el-form-item>
          <el-form-item v-else-if="form.isActive == 25"> 设备脱离管控 </el-form-item>
          <!-- <el-form-item v-else> </el-form-item> -->
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="设备厂商：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.brand">
            {{ form.androidHardwareInfo.brand }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>

        </el-form-item>
        <el-form-item label="设备厂商：" v-if="user.osId == 10">{{ 'Apple' }}
        </el-form-item>

        <el-form-item label="设备型号：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.model">
            {{ form.androidHardwareInfo.model }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>

        </el-form-item>
        <el-form-item label="设备型号：" v-if="user.osId == 10">
          <el-form-item v-if=" form.iosHardwareInfo.model">
            {{  form.iosHardwareInfo.model }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>

        </el-form-item>

        <el-form-item label="序列号：" v-if="user.osId == 20">
          <!-- androidHardwareInfo 原来  v-if -->
          <el-form-item v-if="form.androidHardwareInfo.serial">
            {{ form.androidHardwareInfo.serial }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="序列号：" v-if="user.osId == 10">
          <!-- form.iosHardwareInfo原来  v-if -->
          <el-form-item v-if="form.iosHardwareInfo.serialnumber">
            {{ form.iosHardwareInfo.serialnumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="操作系统：">
          <el-form-item v-if="form.deviceOs.osType">
            {{ form.deviceOs.osType }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="系统名称：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.osName">
            {{ hardwareInfo_bank.osName }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>

        </el-form-item>

        <el-form-item label="系统版本：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.releaseVersion">
            {{ form.androidHardwareInfo.releaseVersion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="系统版本：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.osversion">
            {{ form.iosHardwareInfo.osversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="电池电量：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.battery ">
            {{ form.androidHardwareInfo.battery }}%
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="电池电量：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.batterylevel">
            {{ form.iosHardwareInfo.batterylevel }}%
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="内存使用：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.availmemorysize">
            {{ form.androidHardwareInfo.availmemorysize }}，{{memoryUsage}}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="上网方式：" v-if="user.osId == 10">
          {{$filter.surfInterType(form.iosHardwareInfo.surftype)}}
          <!-- <el-form-item v-if="form.iosHardwareInfo">
            {{ form.iosHardwareInfo.currentcarriernetwork }}
          </el-form-item> -->
          <!-- <el-form-item v-if="form.iosHardwareInfo.surftype == 0">
            无网络
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.surftype == 1 ">
            WIFI
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.surftype == 2">
            2G
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.surftype == 3">
            3G
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.surftype == 4">
            4G
          </el-form-item>
          <el-form-item v-else-if="form.iosHardwareInfo.surftype == 5">
            5G
          </el-form-item> -->

          <!-- <el-form-item v-else-if="form.iosHardwareInfo.surftype == 1000">
            以太网
          </el-form-item> -->
        </el-form-item>
        <!-- 原始的代码 -->
        <!-- <el-form-item label="手机号码：" v-if="user.osId == 20">{{ form.androidHardwareInfo.line1Number }}
        </el-form-item>
        <el-form-item label="手机号码：" v-if="user.osId == 10">{{ form.iosHardwareInfo.phoneNumber }}
        </el-form-item> -->
        <el-form-item label="手机号码：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo.phoneNumber">{{ form.iosHardwareInfo.phoneNumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="IMEI：" v-if="user.osId == 10">
          <!--form.iosHardwareInfo  原来 v-if IMEI   -->
          <el-form-item v-if="form.iosHardwareInfo.imei">
            {{ form.iosHardwareInfo.imei }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="UDID：" v-if="user.osId == 10">
          <el-form-item v-if="form.iosHardwareInfo">
            {{ form.iosHardwareInfo.udid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 0px">
      <el-form ref="form" :model="form" label-width="200px">
        <el-form-item label="存储使用：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.availdatasize">
            {{ form.androidHardwareInfo.availdatasize }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="上网方式：" v-if="user.osId == 20">
          {{$filter.surfInterType(form.androidHardwareInfo.surftype)}}
          <!-- <el-form-item v-if="form.androidHardwareInfo.surftype == 0">
            无网络
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.surftype == 1">
            WIFI
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.surftype == 2">
            2G
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.surftype == 3">
            3G
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.surftype == 4">
            4G
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.surftype == 5">
            5G
          </el-form-item>
          <el-form-item v-else-if="form.androidHardwareInfo.surftype == 1000">
            以太网
          </el-form-item> -->
        </el-form-item>
        <el-form-item label="手机号码：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.line1Number">{{ form.androidHardwareInfo.line1Number }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="SDK版本：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo.sdk ">
            {{ form.androidHardwareInfo.sdk }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="时区：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.timeZone">
            {{ deviceInfo_bank.timeZone }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="IMEI：" v-if="user.osId == 20">
          <!-- form.androidHardwareInfo -->
          <el-form-item v-if="form.androidHardwareInfo.deviceid">
            {{ form.androidHardwareInfo.deviceid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="UDID：" v-if="user.osId == 20">
          <el-form-item v-if="form.androidHardwareInfo">
            {{ form.androidHardwareInfo.udid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="AndroidID：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.androidId ">
            {{ hardwareInfo_bank.androidId }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //内存使用率
      memoryUsage: '',
      form: {
        androidHardwareInfo: {
          availdatasize: '',
          availmemorysize: '',
          battery: '',
          board: '',
          bootLoader: '',
          brand: '',
          clientversion: '',
          codeName: '',
          cpuAbi: '',
          cpuAbi2: '',
          dataState: '',
          device: '',
          deviceId: '',
          deviceSoftwareVersion: '',
          display: '',
          fingerPrint: '',
          groupIdLevel1: '',
          hardware: '',
          hasIccCard: '',
          host: '',
          incremental: '',
          line1Number: '',
          manufacturer: '',
          mmsUAProfUrl: '',
          mmsUserAgent: '',
          model: '',
          netWorkType: '',
          networkCountryIso: '',
          networkOperatorName: '',
          networkRoaming: '',
          os: '',
          phoneType: '',
          product: '',
          radio: '',
          releaseVersion: '',
          releaseVersionId: '',
          sdk: '',
          sdkInt: '',
          serial: '',
          simCountryIso: '',
          simOperator: '',
          simOperatorName: '',
          simSerialNumber: '',
          simState: '',
          subscriberId: '',
          surfType: '',
          tags: '',
          time: '',
          timeStr: '',
          totalDataSize: '',
          totalMemorySize: '',
          tuser: '',
          type: '',
          udid: '',
          updateTime: '',
          voiceMailAlphaTag: '',
          voiceMailNumber: ''
        },
        iosHardwareInfo: {
          availabledevicecapacity: '',
          batterylevel: '',
          bluetooth: '',
          bluetoothmac: '',
          buildversion: '',
          carriersettingsversion: '',
          cellulartechnology: '',
          currentcarriernetwork: '',
          currentmcc: '',
          currentmnc: '',
          datalink: '',
          dataroamingenabled: '',
          devicecapacity: '',
          devicename: '',
          hotspot: '',
          iccid: '',
          imei: '',
          isroaming: '',
          location: '',
          meid: '',
          model: '',
          modelname: '',
          modemfirmwareversion: '',
          osversion: '',
          phonenumber: '',
          productname: '',
          rom: '',
          root: '',
          serialnumber: '',
          simcarriernetwork: '',
          simmcc: '',
          simmnc: '',
          simulatlocation: '',
          subscribercarriernetwork: '',
          subscribermcc: '',
          subscribermnc: '',
          surftype: '',
          udid: '',
          updatetime: '',
          usb: '',
          vm: '',
          voiceroamingenabled: '',
          wifi: '',
          wifimac: ''
        },
        androidSoftwareInfo: {
          appCacheSize: '',
          appDataSize: '',
          appName: '',
          appSize: '',
          askStatus: '',
          firstInstallTime: '',
          id: '',
          isSystem: '',
          lastUpdateTime: '',
          lastUpdateTimeStr: '',
          packageName: '',
          purgeStatus: '',
          scanScore: '',
          udid: '',
          updateTime: '',
          versionCode: '',
          versionName: ''
        },
        assistantAppStatus: '',
        belong: '',
        brand: '',
        deviceConIMStatus: {
          conStatus: '',
          id: '',
          lastLoginTime: '',
          lastUpdateTime: '',
          udid: '65e39a0131d78f1983c785c7e60d70e7561a2d9d'
        },
        createTime: '',
        deviceGroup: {
          fullName: ''
        },
        deviceName: '',
        deviceOs: {
          id: '',
          osType: ''
        },
        enrollmentTime: '',
        hardwareUpdateTime: '',
        id: '',
        installed: '',
        isActive: '',
        isJailBreak: '',
        isViolation: '',
        mode: '',
        model: '',
        signLongOffline: '',
        signStatus: '',
        status: '',
        bindStatus: '',
        surfType: '',
        uninstallCode: '',
        violationreason: ''
      },
      user: {
        osId: ''
      },
      clientInfo_ios:'',//ios客户端版本
      // 农行新增
      deviceInfo_bank: {},
      hardwareInfo_bank: {},
      deviceInfo_bank_ios: {}, //
    }
  },
  created() {
    // let user = JSON.parse(window.sessionStorage.getItem("user"));
    // this.getlist(user);
  },
  mounted() {
    setTimeout(() => {
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      this.user = user
      this.getlist(user)
    }, 1000)
  },

  methods: {
    async getlist(user) {
      // console..log(user)
      var param = {
        deviceId: user.id.toString(),
        osType: user.osId == 20 ? 'Android' : 'iOS',
        deviceUDID: user.deviceUDID
        // deviceId: user.id.toString(),
        // deviceUDID: user.deviceUDID,
        // osType: user.osId == 20 ? "Android" : "iOS",
        // status: user.status.toString(),
      }
      // console..log(param)
      // const res = await this.$axios.post("/api/device/basedeviceInfo.do", param, true);
      const res = await this.$axios.post(
        '/httpServe/device/baseDeviceInfo',
        param,
        true
      )
      // console..log(res.data, '详情')
      // console.log(res.data.iosHardwareInfo, 'ios详情新增字段')
      this.form = res.data

      if (res.data == null) {
        this.form = {}
      }

      if (user.osId == 20) {
        if (res.data.androidHardwareInfo) {
          if (res.data.androidHardwareInfo.deviceInfo) {
            this.deviceInfo_bank = Object.assign(
              eval('(' + res.data.androidHardwareInfo.deviceInfo + ')')
            )
          }

          if (res.data.androidHardwareInfo.hardwareInfo) {
            this.hardwareInfo_bank = Object.assign(
              eval('(' + res.data.androidHardwareInfo.hardwareInfo + ')')
            )
            console.log(this.hardwareInfo_bank, '  this.hardwareInfo_bank')
          }
        }

        // // //内存使用数
        if (res.data.androidHardwareInfo.availmemorysize != null) {
          var availmemorysize =
            ((this.form.androidHardwareInfo.availmemorysize /
              1024 /
              1024 /
              1024) *
              10) /
            10.0
          var num = new Number(availmemorysize)
          // 内存总条数
          var totalmemorysize =
            ((this.form.androidHardwareInfo.totalmemorysize /
              1024 /
              1024 /
              1024) *
              10) /
            10.0
          var newnum = new Number(totalmemorysize)
          // 内存使用率

          this.memoryUsage = this.$common.getPercent(num, newnum)

          this.form.androidHardwareInfo.availmemorysize =
            num.toFixed('1') + 'GB/' + newnum.toFixed('1') + 'GB'
          // console..log(this.form.androidHardwareInfo.availmemorysize, '内存使用')
        } else if (res.data.androidHardwareInfo.availmemorysize == null) {
          this.form.androidHardwareInfo.availmemorysize =
            '0.0' + 'GB/' + '0.0' + 'GB'
        }
        // 存储使用
        if (res.data.androidHardwareInfo.availdatasize != null) {
          // 存储使用
          var availdatasize =
            ((this.form.androidHardwareInfo.availdatasize /
              1024 /
              1024 /
              1024) *
              10) /
            10.0
          var avail = new Number(availdatasize)
          // 存储总数
          var totaldatasize =
            ((this.form.androidHardwareInfo.totaldatasize /
              1024 /
              1024 /
              1024) *
              10) /
            10.0
          var totalnum = new Number(totaldatasize)
          this.form.androidHardwareInfo.availdatasize =
            avail.toFixed('1') + 'GB/' + totalnum.toFixed('1') + 'GB'
          // console..log(this.form.androidHardwareInfo.availdatasize, '存储使用')
          // this.$forceUpdate()
        } else if (res.data.androidHardwareInfo.availdatasize == null) {
          this.form.androidHardwareInfo.availdatasize =
            '0.0' + 'GB/' + '0.0' + 'GB'
        }
      } else if (user.osId == 10) {
        if (res.data.iosHardwareInfo) {
          if (res.data.iosHardwareInfo.clientInfo) {
            this.clientInfo_ios = Object.assign(
              eval('(' + res.data.iosHardwareInfo.clientInfo + ')')
            )
          }
          if (res.data.iosHardwareInfo.deviceInfo) {
            this.deviceInfo_bank_ios = Object.assign(
              eval('(' + res.data.iosHardwareInfo.deviceInfo + ')')
            )
          }
        }
        console.log(res.data, 'ios详情新增字段')
        // //设备内存使用数
        var devicecapacity =
          ((this.form.iosHardwareInfo.devicecapacity / 1024 / 1024 / 1024) *
            10) /
          10.0
        var nums = new Number(devicecapacity)
        this.form.iosHardwareInfo.devicecapacity = nums.toFixed('1') + 'GB'
        // console..log(this.form.iosHardwareInfo.devicecapacity, '设备内存使用')

        // 设备存储使用
        var availabledevicecapacity =
          ((this.form.iosHardwareInfo.availabledevicecapacity /
            1024 /
            1024 /
            1024) *
            10) /
          10.0
        var avails = new Number(availabledevicecapacity)
        this.form.iosHardwareInfo.availabledevicecapacity =
          avails.toFixed('1') + 'GB'
        // console..log(  this.form.iosHardwareInfo.availabledevicecapacity,  '设备存储使用'  )
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px !important;
}
.el-form-item__label {
  color: #000000 !important;
}
.wrap .group .msg_l0 {
  color: #807e7e !important;
}
</style>
<style></style>
