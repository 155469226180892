<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane v-if="BasicinforTabs" :label="$t('DeviceManage.Device.Basicinformation')" name="1">
        <jbxx v-if="user.osId==10 || user.osId==20" :key="timer1"></jbxx>
        <!-- v6.2.0新增  Windows 30 内容展示 / v6.3.0新增  Linux 40 内容展示-->
        <winBasicinfor v-if="user.osId==30 || user.osId==40" :key="timer1"></winBasicinfor>
      </el-tab-pane>
      <el-tab-pane v-if="HardwareInforTabs" :label="$t('DeviceManage.Device.HardwareInformation')" name="2">
        <yjxx v-if="user.osId==10 || user.osId==20" :key="timer2"></yjxx>
        <!-- v6.2.0新增  Windows 30 内容展示 / v6.3.0新增  Linux 40 内容展示-->
        <winHardwareInfor v-if="user.osId==30 || user.osId==40" :key="timer2"></winHardwareInfor>
      </el-tab-pane>
      <el-tab-pane v-if="ApplicationInforTabs" :label="$t('DeviceManage.Device.ApplicationInformation')" name="3">
        <yingy v-if="user.osId==10 || user.osId==20" :key="timer3"></yingy>
        <!-- v6.2.0新增  Windows 30 内容展示 / v6.3.0新增  Linux 40 内容展示-->
        <winApplication v-if="user.osId==30 || user.osId==40" :key="timer3"></winApplication>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
// 基本信息
import jbxx from "./Equipment/jbxx.vue";
// 硬件信息
import yjxx from "./Equipment/yjxx.vue";
// 应用信息
import yingy from "./Equipment/yingy.vue";
// 策略信息
import clxx from "./Equipment/clxx.vue";
// 风险信息
import fxxx from "./Equipment/fxxx.vue";
// 审计信息
import sjxx from "./Equipment/sjxx.vue";
// 设备轨迹
import sbgj from "./Equipment/sbgj.vue";
// 设备轨迹
import sbml from "./Equipment/sbml.vue";
// ------v6.2.0新增  Windows 内容展示
import winBasicinfor from "./Equipment/winBasicinfor.vue";//windows 基本信息
import winHardwareInfor from "./Equipment/winHardwareInfor.vue";//windows 硬件信息
import winApplication from "./Equipment/winApplication.vue";//windows 应用信息

export default {
  components: { jbxx, yjxx, yingy, clxx, fxxx, sbgj, sbml, sjxx,winBasicinfor,winHardwareInfor,winApplication },
  data() {
    return {
      flagMode:true,
      mode:vueConfig.mode,
      timer1: "",
      timer2: "",
      timer3: "",
      timer4: "",
      timer5: "",
      timer6: "",
      timer7: "",
      timer8: "",
      activeName: "1",
      user: "",
      BasicinforTabs:false,//基本信息
      HardwareInforTabs:false,//硬件信息
      ApplicationInforTabs:false,//应用信息
    };
  },
  created() {   
  },
  mounted() {
    setTimeout(() => {
      let user = JSON.parse(window.sessionStorage.getItem("user"));
      this.user = user;
      // 10--iOS / 20--Android / 30--Windows
      if(this.user.osId==10){
        this.BasicinforTabs=true;
        this.HardwareInforTabs=true;
      }else if(this.user.osId==20){
        this.BasicinforTabs=true;
        this.HardwareInforTabs=true;
      }else if(this.user.osId==30 || this.user.osId==40){
        this.BasicinforTabs=true;
        this.HardwareInforTabs=true;
        this.ApplicationInforTabs=true;
      }
      if(this.mode == 'byod') {
        if(this.mode == 'byod'&&user.isActive == 20){
          this.flagMode = false
        }else {
          this.flagMode = true
        } 
      }
    }, 100);
  },

  methods: {
    sun(data) {
      this.$emit("su", data);
    },
    handleLoad(data) {
      let name = data.name;
      if (name == 1) {
        this.timer1 = new Date().getTime();
      } else if (name == 2) {
        this.timer2 = new Date().getTime();
      } else if (name == 3) {
        this.timer3 = new Date().getTime();
      } else if (name == 4) {
        this.timer4 = new Date().getTime();
      } else if (name == 5) {
        this.timer5 = new Date().getTime();
      } else if (name == 6) {
        this.timer6 = new Date().getTime();
      } else if (name == 7) {
        this.timer7 = new Date().getTime();
      } else if (name == 8) {
        this.timer8 = new Date().getTime();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}
::v-deep .el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs__item:hover {
  color: #000;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
</style>
<style>
.el-input1 {
  width: 200px;
}
.el-input2 {
  width: 200px;
  margin-left: 10px;
}
.btn {
  margin-left: 10px;
  margin-right: 1px;
}
.el-tabs__item.is-active {
  color: #000;
}
.el-button + .el-button {
  margin-left: 0px;
}
</style>
