<template>
  <div style="height: 600px">
    <div class="lll" style="margin-left: 20px">
      <div id="container" style="width: 1000px; height: 500px; margin: 20px;position: absolute;"></div>
      <div id="panel"></div>
      <el-form
        ref="ruleForm"
        label-width="800px"
        class="demo-ruleForm"
        style="margin-top: 10px;margin-left: 320px;"
      >
        <el-form-item label="开始时间：" prop="name">
          <!-- <el-input size="small" style="width: 240px"></el-input> -->
          <el-date-picker :clearable="false"
            v-model="value1"
            type="date"
            placeholder="选择日期"
            size="small"
            style="width: 240px"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" prop="name">
          <!-- <el-input size="small" style="width: 240px"></el-input> -->
          <el-date-picker :clearable="false"
            v-model="value2"
            type="date"
            placeholder="选择日期"
            size="small"
            style="width: 240px"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="submitForm">查询</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            :loading="loading.listloading"
            size="mini"
            @click="Refresh()"
            >获取定位</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      times:'',
      flag: 1,
      value1: new Date(),
      value2: new Date(),
      user: {},
      loading: {
        listloading: false,
      },
      timer: null,
      motime: "",
      // inRefresh: [116.4,39.92],
      inRefresh: [116.397428, 39.90923],
      numArray:0,
    };
  },
  watch: {
    value1(val, oldVal) {
      console.log(val);
      if (!val) {
        this.value1 = "";
      }
    },
    value2(val, oldVal) {
      console.log(val);
      if (!val) {
        this.value2 = "";
      }
    },
  },
  watch: {
    times: {
      handler: function (newVal, oldVal) { 
        console.log(newVal,'---新值', oldVal,'，旧值')
        if (newVal) {
        this.times = newVal;
        this.time = this.times
        console.log(this.time,'----最新的值监听')
        // this.allMethods()
      }
      deep: true
      // immediate: true,
    },
},
},
  mounted: function () {
    let user = JSON.parse(window.sessionStorage.getItem("user"));
    let time = window.localStorage.getItem("time");
    console.log(time,'---mounted  获取  time')
    this.user = user;
    this.time = time;
    console.log(this.time,'没刷新')
    this.map(user);
  },

  methods: {
    init: function (res) {
      var that = this;
      //基本地图加载
      var map = new AMap.Map("container", {
        resizeEnable: true,
        // center: [116.397428, 39.90923], //地图中心点
        center: that.inRefresh, //地图中心点
        zoom: 17, //地图显示的缩放级别3-18
      });
      // console.log(map, "地图跳转");
      window.map = map;
      //构造路线导航类
      var driving = new AMap.Driving({
        map: map,
        panel: "panel",
        ferry: 1, // 是否可以使用轮渡,
        policy: AMap.DrivingPolicy.LEAST_TIME,
      });
      var arr = [];
      // var lat1 = res.data[0].latitude;
      // var log1 = res.data[0].longitude;
      // var lat3 = res.data[res.data.length - 1].latitude;
      // var log3 = res.data[res.data.length - 1].longitude;
      res.data.forEach((i, k) => {
        arr.push(new AMap.LngLat(i.longitude, i.latitude));
      });
      var polyline = new AMap.Polyline({
        path: arr, // 设置线覆盖物路径
        strokeColor: "#1bac2e", // 线颜色
        //   strokeOpacity: 1, // 线透明度
        strokeWeight: 6, // 线宽
        // strokeStyle: "solid", // 线样式
        //   strokeDasharray: [10, 5], // 补充线样式
        //   geodesic: true, // 绘制大地线
        showDir: true, //是否显示箭头
      });
      polyline.setMap(map);

      // 创建一个 icon
      var endIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/map-marker-j.png"),
        imageSize: new AMap.Size(30, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      // 创建一个 icon
      var kaiIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/map-marker-q.png"),
        imageSize: new AMap.Size(30, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      // 创建一个 icon
      var weiIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/map-marker-z.png"),
        imageSize: new AMap.Size(30, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) });

      res.data.forEach((i, k) => {
        if (k == 0) {
          var ar = [i.longitude, i.latitude];
          this.inRefresh = ar;
          var marker = new AMap.Marker({
            position: ar,
            icon: kaiIcon,
            map: map,
          });
        } else if (k == res.data.length - 1) {
          var ar = [i.longitude, i.latitude];
          this.inRefresh = ar;
          var marker = new AMap.Marker({
            position: ar,
            icon: weiIcon,
            map: map,
          });
        } else {
          var ar = [i.longitude, i.latitude];
          this.inRefresh = ar;
          var marker = new AMap.Marker({
            position: ar,
            icon: endIcon,
            map: map,
          });
        }
        marker.content = i.uploadTime;
        marker.on("mouseover", markerClick);
        marker.emit("click", { target: marker });
      });
      //   提示时间框
      var arrs = [];
      res.data.forEach((i, k) => {
        if (k == 0 || k == res.data.length - 1) {
          // alert(1);
        } else {
          arrs.push(new AMap.LngLat(i.longitude, i.latitude));
        }
      });
      function markerClick(e) {
        // console.log(e, "-----eeee");
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, e.target.getPosition());
      }
      map.setFitView();
    },
    // 查询
    async submitForm() {
      let time = window.localStorage.getItem("time");
      this.time = time;
      var val1 = this.$moment(this.value1).unix();
      var val2 = this.$moment(this.value2).unix();
      if (val1 > val2) {
        this.$message({
          message: '开始时间不能大于结束时间',
          type: 'error',
          offset: 100
        })
        return false
      }
      // if (this.value1 == "" && this.value2 == "") {
      //   var params = {
      //     deviceId: this.user.id,
      //     startTime: "",
      //     endTime: "",
      //     // startTime: this.$moment(this.value1).format("YYYY-MM-DD"),
      //     // endTime: this.$moment(this.value2).format("YYYY-MM-DD"),
      //   };
      //   const res = await this.$axios.post(
      //     "/httpServe/location/selectList",
      //     params,
      //     true
      //   );
      //   this.refre(time);
      //   let result = res.data;
      //   let arr = [];
      //   result.forEach((ele) => {
      //     arr.push({
      //       x: ele.latitude,
      //       y: ele.longitude,
      //     });
      //   });
      //   // console.log();
      //   this.init(res);
      // } else {
        var params = {
          deviceId: this.user.id,
          // startTime: this.value1.split(" ")[0],
          // endTime: this.value2.split(" ")[0],
          startTime: this.$moment(this.value1).format("YYYY-MM-DD"),
          endTime: this.$moment(this.value2).format("YYYY-MM-DD"),
        };
        const res = await this.$axios.post(
          "/httpServe/location/selectList",
          params,
          true
        );
        this.refre(time);
        let result = res.data;
        let arr = [];
        result.forEach((ele) => {
          arr.push({
            x: ele.latitude,
            y: ele.longitude,
          });
        });
        // console.log();
        this.init(res);
      // }
    },
    // 请求接口
    async map() {
      let time = window.localStorage.getItem("time");
      this.time = time;
      var val1 = this.$moment(this.value1).unix();
      var val2 = this.$moment(this.value2).unix();
      if (val1 > val2) {
        this.$message({
          message: '开始时间不能大于结束时间',
          type: 'error',
          offset: 100
        })
        return false
      }
      var params = {
        deviceId: this.user.id,
        deviceUDID: this.user.deviceUDID,
        osType: this.user.osId == 20 ? "Android" : "iOS",
        status: this.user.status,
        // startTime: this.value1.split(" ")[0],
        // endTime: this.value2.split(" ")[0],
        startTime: this.$moment(this.value1).format("YYYY-MM-DD"),
          endTime: this.$moment(this.value2).format("YYYY-MM-DD"),
      };
      // console.log(params, "地图参数---");
      const res = await this.$axios.post("/httpServe/location/selectList", params, true);
      let result = res.data;
      let arr = [];
      result.forEach((ele) => {
        arr.push({
          x: ele.latitude,
          y: ele.longitude,
        });
      });
      // console.log();
      this.init(res);
      if(res.code == 200) {
        this.refre(time);
      }
      // this.viaMarker.forEach()
    },
    // 获取定位 Refresh
    Refresh() {
      this.numArray=0
      // this.$moment().format('YYYY-MM-DD HH:mm:ss')
      var time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("Historicaltime", time);
      console.log(time, "获取定位时间");
      var param = {
        type: "uploadlocation", //标记状态
        deviceUDID: this.user.deviceUDID,
      };
      this.$confirm("此操作将获取设备实时定位，确定要获取定位？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      }).then(async () => {
        const res = await this.$axios.post("/httpServe/device/command/exec", param, true);
        this.loading.listloading = true;
        // console.log(res, "定位");
        // console.log(res);
        this.timer = setInterval(() => {
          this.numArray++;
          console.log(this.numArray,"开始时间");
          // console.log("开始");
          // 需要执行的方法
          this.refre(time);
          if (this.numArray >= 12) {
          clearInterval(this.timer);
          this.loading.listloading = false;
        }
        }, 10000);
        return;
      });
    },
    // 定位接口
    async refre(time) {
      window.localStorage.setItem('time', time)
      var param = {
        deviceId: String(this.user.id),
        // updateTime: "2022-06-30 11:04:25",
        updateTime: time?"":"",
      };
      const res = await this.$axios.post(
        "/httpServe/devicePosition/selectDevicePosition",
        param,
        true
      );
      // console.log(res);
      if (res.data != null) {
        clearInterval(this.timer);
        this.loading.listloading = false;
      }
      // 创建一个 icon   最近定位
      var latelyIcon = new AMap.Icon({
        size: new AMap.Size(100, 34),
        image: require("../../../../images/ico_zjdw.png"),
        imageSize: new AMap.Size(40, 35),
        imageOffset: new AMap.Pixel(0, 0),
      });
      var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) });
      // console.log(res.data);
      var arr = [];
      arr.push(res.data);
      console.log(arr,'-----------------');
      arr.forEach((i, k) => {
        console.log(i.longitude, "ifdwefwsed");
        console.log(i.latitude, "ifdwefwsed");
        // if (k == 0) {
          var ars = [i.longitude, i.latitude];
          this.inRefresh = ars;
          console.log(this.inRefresh, "经纬度");
          // this.map();
          var marker = new AMap.Marker({
            position: ars,
            icon: latelyIcon,
            map: map,
          });
        // }
        marker.content = i.uploadTime;
        // console.log(marker.content, "内容");
        marker.on("mouseover", markerClick);
        marker.emit("click", { target: marker });
        window.map.clearMap(marker);
        window.map.add(marker);
      });
      //   提示时间框
      var arrs = [];
      arrs.push(res.data);
      arrs.forEach((i, k) => {
        // console.log(i,'----------');
        // console.log(k,'----------');
        if (k == 0) {
          // alert(1);
        } else {
          arrs.push(new AMap.LngLat(i.longitude, i.latitude));
        }
      });
      // console.log(arrs);

      function markerClick(e) {
        // console.log(e, "-----eeee");
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, e.target.getPosition());
      }
      map.setFitView();
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tear {
  width: 35px;
  height: 35px;
  border-radius: 0 50% 50% 50%;
  /* border: 1px solid #56bd77; */
  transform: rotate(-135deg);
  margin-top: 20px;
  background: #56bd77;
}
::v-deep .tears {
  color: white;
  font-size: 12px;
  transform: rotate(-135deg);
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.amap-icon img {
  width: 25px;
  height: 34px;
}
::v-deep .amap-lib-marker-from {
  display: none;
}
::v-deep .amap-lib-marker-from {
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/start.png) no-repeat;
  cursor: pointer;
  display: none;
}
::v-deep .amap-lib-marker-to {
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/end.png) no-repeat;
  cursor: pointer;
  display: none;
}
</style>
<style scoped>
::v-deep .amap-icon img {
  width: 25px;
  height: 34px;
}
#panel {
  display: none;
}
::v-deep .info-title {
  font-weight: bolder;
  color: #000;
  font-size: 14px;
  width: 250px;
  line-height: 26px;
  padding: 0 0 0 6px;
}
::v-deep .info-content {
  width: 250px;
  padding: 4px;
  color: #666666;
  line-height: 23px;
  font: 12px Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", Arial;
}
::v-deep .info-content img {
  float: left;
  margin: 3px;
}
::v-deep .amap-info-combo .keyword-input {
  height: auto;
}
::v-deep .amap-lib-marker-from {
  display: none;
}
::v-deep .amap-lib-marker-from {
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/start.png) no-repeat;
  cursor: pointer;
  display: none;
}
::v-deep .amap-lib-marker-to {
  background: url(https://webapi.amap.com/theme/v1.3/markers/n/end.png) no-repeat;
  cursor: pointer;
  display: none;
}
</style>
