<template>
  <div style="display: flex; height: 700px">
    <div style="margin-top: 10px">
      <el-form ref="form" :model="form" label-width="190px">
        <el-form-item label="屏幕分辨率：" v-if="user.osId == 10">
          <el-form-item v-if="form.hardwareInfo">
            {{ form.hardwareInfo.screen }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="IMEI：" v-if="user.osId == 10">
          <el-form-item v-if="form.imei">
            {{ form.imei }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="MEID：" v-if="user.osId == 10">
          <el-form-item v-if="form.meid">
            {{ form.meid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="ICCID：" v-if="user.osId == 10">
          <el-form-item v-if="form.iccid">
            {{ form.iccid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="SIM国家代码：" v-if="user.osId == 10">
          <el-form-item v-if="form.simmcc">
            {{ form.simmcc }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="当前运营商网络：" v-if="user.osId == 10">
          <el-form-item v-if="form.currentcarriernetwork">
            {{ form.currentcarriernetwork }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="当前网络运营商：" v-if="user.osId == 10">
          <el-form-item v-if="form.simcarriernetwork">
            {{ form.simcarriernetwork }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="网络运营商代码：" v-if="user.osId == 10">
          <el-form-item v-if="form.subscribermnc">
            {{ form.subscribermnc }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="运营商设置版本：" v-if="user.osId == 10">
          <el-form-item v-if="form.carriersettingsversion">
            {{ form.carriersettingsversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="网络运营商国家代码：" v-if="user.osId == 10">
          <el-form-item v-if="form.subscribermcc">
            {{ form.subscribermcc }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="手机制式：" v-if="user.osId == 20">
          <el-form-item v-if="form.phonetype">
            {{ form.phonetype }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="屏幕分辨率：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank">
            {{ hardwareInfo_bank.screen }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="网络类型：" v-if="user.osId == 20">
          <el-form-item v-if="form.networktype">
            {{ form.networktype }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="设备参数：" v-if="user.osId == 20">
          <el-form-item v-if="form.device">
            {{ form.device }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="手机厂商：" v-if="user.osId == 20">
          <el-form-item v-if="form.product">
            {{ form.product }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="硬件名称：" v-if="user.osId == 20">
          <el-form-item v-if="form.hardware">
            {{ form.hardware }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="软件版本号：" v-if="user.osId == 20">
          <el-form-item v-if="form.devicesoftwareversion">
            {{ form.devicesoftwareversion }}
          </el-form-item>
          <!-- <el-form-item v-else-if="form.devicesoftwareversion==null">

          </el-form-item> -->
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="手机用户ID：" v-if="user.osId == 20">
          <el-form-item v-if="form.subscriberid">
            {{ form.subscriberid }}
          </el-form-item>

          <!-- <el-form-item v-else-if="form.subscriberid==null">

          </el-form-item> -->
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="硬件制造商：" v-if="user.osId == 20">
          <el-form-item v-if="form.manufacturer">
            {{ form.manufacturer }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="系统定制商：" v-if="user.osId == 20">
          <el-form-item v-if="form.brand">
            {{ form.brand }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="硬件串行号：" v-if="user.osId == 20">
          <el-form-item v-if="form.serial">
            {{ form.serial }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="语音信息号码：" v-if="user.osId == 20">
          <el-form-item v-if="form.voicemailnumber">
            {{ form.voicemailnumber }}
          </el-form-item>
          <!-- <el-form-item v-else-if="form.voicemailnumber==null">

          </el-form-item> -->
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="网络运营商名称：" v-if="user.osId == 20">
          <el-form-item v-if="form.networkoperatorname">
            {{ form.networkoperatorname }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="网络供应商国别：" v-if="user.osId == 20">
          <el-form-item v-if="form.networkcountryiso">
            {{ form.networkcountryiso }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="基站位置：" v-if="user.osId == 20">
          <el-form-item v-if="telephoneinfo_bank.cellCode">
            {{ telephoneinfo_bank.cellCode }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="基站编号：" v-if="user.osId == 20">
          <el-form-item v-if="telephoneinfo_bank.cellID">
            {{ telephoneinfo_bank.cellID }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin-top: 10px">
      <el-form ref="form" :model="form" label-width="300px">

        <el-form-item label="设备序列号：" v-if="user.osId == 10">
          <el-form-item v-if="form.serialnumber">
            {{ form.serialnumber }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="网络运行商：" v-if="user.osId == 10">
          <el-form-item v-if="form.cellulartechnology">
            {{ form.cellulartechnology }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="设备固件版本：" v-if="user.osId == 10">
          <el-form-item v-if="form.modemfirmwareversion">
            {{ form.modemfirmwareversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="蓝牙物理地址：" v-if="user.osId == 10">
          <el-form-item v-if="form.bluetoothmac">
            {{ form.bluetoothmac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="注册的运营商：" v-if="user.osId == 10">
          <el-form-item v-if="form.subscribercarriernetwork">
            {{ form.subscribercarriernetwork }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="是否允许漫游：" v-if="user.osId == 10">
          <el-form-item v-if="form.isroaming">
            {{ form.isroaming }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="WiFi物理地址：" v-if="user.osId == 10">
          <el-form-item v-if="form.wifimac">
            {{ form.wifimac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="SIM运营商代码：" v-if="user.osId == 10">
          <el-form-item v-if="form.simmnc">
            {{ form.simmnc }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="CPU架构：" v-if="user.osId == 10">
          <el-form-item>
            {{'ARM'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="主板：" v-if="user.osId == 20">
          <el-form-item v-if="form.board">
            {{ form.board }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="版本：" v-if="user.osId == 20">
          <el-form-item v-if="form.releaseversionid">
            {{ form.releaseversionid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="是否有SIM卡：" v-if="user.osId == 20">
          <el-form-item v-if="form.hasicccard">
            {{ form.hasicccard == 1 ? "是" : "否" }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="SIM卡国别：" v-if="user.osId == 20">
          <el-form-item v-if="form.simcountryiso">
            {{ form.simcountryiso }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="SIM卡状态：" v-if="user.osId == 20">
          <el-form-item v-if="form.simstate">
            <!-- <el-form-item v-if="form.simstate">
              form.simstate
            </el-form-item> -->
            <el-form-item v-if="form.simstate == 0">
              未知
            </el-form-item>
            <el-form-item v-if="form.simstate == 1">
              无SIM卡
            </el-form-item>
            <el-form-item v-if="form.simstate == 2">
              锁定
            </el-form-item>
            <el-form-item v-if="form.simstate == 3">
              锁定
            </el-form-item>
            <el-form-item v-if="form.simstate == 4">
              锁定
            </el-form-item>
            <el-form-item v-if="form.simstate == 5">
              良好
            </el-form-item>
            <el-form-item v-if="form.simstate == 6">
              未就绪
            </el-form-item>
            <el-form-item v-if="form.simstate == 7">
              出错
            </el-form-item>
            <el-form-item v-if="form.simstate == 8">
              出错
            </el-form-item>
            <el-form-item v-if="form.simstate == 9">
              被限制
            </el-form-item>
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>

        </el-form-item>

        <el-form-item label="SIM卡序列号：" v-if="user.osId == 20">
          <el-form-item v-if="form.simserialnumber">
            {{ form.simserialnumber }}
          </el-form-item>
          <!-- <el-form-item v-else-if="form.simserialnumber==null">

          </el-form-item> -->
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="SIM卡供应商：" v-if="user.osId == 20">
          <el-form-item v-if="form.simoperatorname">
            {{ form.simoperatorname }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="BUILD时间：" v-if="user.osId == 20">
          <el-form-item v-if="form.time">
            {{ form.time }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="BUILD描述标签：" v-if="user.osId == 20">
          <el-form-item v-if="form.tags">
            {{ form.tags }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="CPU使用率：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.cpuRate">
            {{ deviceInfo_bank.cpuRate +'%'}}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="CPU型号：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.cpu">
            {{ hardwareInfo_bank.cpu }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="CPU架构：" v-if="user.osId == 20">
          <el-form-item v-if="form.cpuabi">
            {{ form.cpuabi }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="CPU架构2：" v-if="user.osId == 20">
          <el-form-item v-if="form.cpuabi2">
            {{ form.cpuabi2 }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="手机设备ID（IMEI）：" v-if="user.osId == 20">
          <el-form-item v-if="form.deviceid">
            {{ form.deviceid }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="用户可见的版本字符串：" v-if="user.osId == 20">
          <el-form-item v-if="form.releaseversion">
            {{ form.releaseversion }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="WIFI信号强度：" v-if="user.osId == 20">
          <el-form-item v-if="telephoneinfo_bank.wifiSignalLevel">
            {{ telephoneinfo_bank.wifiSignalLevel }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

      </el-form>
    </div>
    <div style="margin-top: 10px">
      <el-form ref="form" :model="form" label-width="300px">
        <el-form-item label="网卡MAC：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.netMac">
            {{ hardwareInfo_bank.netMac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="蓝牙MAC：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.bluetoothMac">
            {{ hardwareInfo_bank.bluetoothMac }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <!-- IMSI -->
        <el-form-item label="IMSI：" v-if="user.osId == 20">
          <el-form-item v-if="hardwareInfo_bank.imsi">
            {{ hardwareInfo_bank.imsi }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="按压力度(hPa)：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.press">
            {{ deviceInfo_bank.press }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="行为轨迹：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.track">
            {{ deviceInfo_bank.track }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="加速度传感器(m/s²)：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.accelerated">
            {{ deviceInfo_bank.accelerated}}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
        <el-form-item label="温度传感器(℃)：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.temperature">
            {{ deviceInfo_bank.temperature}}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>

        <el-form-item label="陀螺仪(rad/h)：" v-if="user.osId == 20">
          <el-form-item v-if="deviceInfo_bank.gyro">
            {{ deviceInfo_bank.gyro }}
          </el-form-item>
          <el-form-item v-else>
            {{ 'N/A'}}
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      form: {},
      user: {
        osId: ''
      },
      // 农行新增
      hardwareInfo_bank: {},
      telephoneinfo_bank: {},
      deviceInfo_bank: {}
    }
  },

  created() {},

  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    this.getlist(user)
  },

  methods: {
    async getlist(user) {
      var param = {
        deviceId: user.id,
        deviceUDID: user.deviceUDID,
        osType: user.osId == 20 ? 'Android' : 'iOS'
        // status: user.status,
      }
      // var param = {
      //   deviceId: '158',
      //   osType: 'Android',
      //   deviceUDID: '83c16aec64195b0b5b2410dc1c90121f2bd63f0c'
      // }
      // //// console..log(data);
      const res = await this.$axios.post(
        // "api/webHardware/deviceHardwareInfo.do",
        // 'httpServe/webHardware/deviceHardwareInfo',
        '/httpServe/webHardware/deviceHardwareInfo',
        param,
        true
      )
      console.log(res.data, 'telephoneInfo')

      if (user.osId == 20) {
        this.form = res.data
        console.log(res.data)
        // 字符串转对象
        if (res.data.hardwareInfo) {
          this.hardwareInfo_bank = Object.assign(
            eval('(' + res.data.hardwareInfo + ')')
          )
        }
        console.log(this.hardwareInfo_bank, 'hardwareInfo_bank2')
        if (res.data.deviceInfo) {
          this.deviceInfo_bank = Object.assign(
            eval('(' + res.data.deviceInfo + ')')
          )
        }
        console.log(
          // res.data.deviceInfo,
          this.deviceInfo_bank.accelerated,
          'this.deviceInfo_bank'
        )
        if (this.deviceInfo_bank) {
          // 加速度传感器
          if (this.deviceInfo_bank.accelerated) {
            var str = this.deviceInfo_bank.accelerated.split(',')
            this.deviceInfo_bank.accelerated = str.join('，')
          }
          //陀螺仪
          if (this.deviceInfo_bank.gyro) {
            var str = this.deviceInfo_bank.gyro.split(',')
            console.log(str, '陀螺仪')
            this.deviceInfo_bank.gyro = str.join('，')
          }
          //行为轨迹
          if (this.telephoneinfo_bank.track) {
            let arr1 = this.deviceInfo_bank.track.reduce(
              (total, currentValue) => {
                return total.concat(currentValue)
              }
            )
            this.deviceInfo_bank.track = JSON.parse(arr1).toString()
          }
        }
        if (res.data.telephoneInfo) {
          // var newObj
          this.telephoneinfo_bank = Object.assign(
            eval('(' + res.data.telephoneInfo + ')')
          )
        }
      } else if (user.osId == 10) {
        res.data.time = moment(parseInt(res.data.time)).format(
          'YYYY-MM-DD HH:mm:ss'
        )

        if (res.data.hardwareInfo) {
          res.data.hardwareInfo = Object.assign(
            eval('(' + res.data.hardwareInfo + ')')
          )
        }
        console.log(res.data.hardwareInfo)
        this.form = res.data
        console.log(this.form, '屏幕分片率')
      }
      if (res.data.time) {
        res.data.time = moment(parseInt(res.data.time)).format('YYYY-MM-DD')
        // YYYY-MM-DD HH:mm:ss

        this.form = res.data
        console.log(this.form, '屏幕分片率')
        this.form = res.data
      }

      //   //// console..log(moment(parseInt(res.data.time)).format('YYYY-MM-DD HH:mm:ss'),"llllll")

      // //// console..log(res.data, 'jjjjjj')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 1px !important;
}

.el-form-item__label {
  color: #000000 !important;
}

.wrap .group .msg_l0 {
  color: #807e7e !important;
}
</style>
<style></style>
