<template>
  <div class="SensitiveMonitor" style="height: 600px">
    <!-- <navi-gation /> -->
    <div class="content">
      <el-row :gutter="4" class="mt15" style="display: flex">
        <el-col :span="3">
          <!-- 起始日期 -->
          <el-date-picker :clearable="false"
            size="small"
            style="width: 100%"
            v-model="formInline.startTime"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="0.5">
          <span style="line-height: 30px; color: #dcdfe6">~</span>
        </el-col>
        <el-col :span="3">
          <el-date-picker :clearable="false"
            size="small"
            style="width: 100%"
            v-model="formInline.endTime"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <!-- 类型 -->
          <el-select
            v-model="formInline.reason"
            style="width: 100%"
            placeholder="类型"
            size="small"
            class="placeOption"
            @change="formInlinechang"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.type"
              :label="item.label"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-col>
        <!-- 行为类型 -->
        <el-col :span="3">
          <el-select
            v-if="!isdevNameOptions"
            style="width: 100%"
            v-model="formInline.deviceName"
            placeholder="行为类型"
            class="placeOption"
            size="small"
          >
            <el-option
              v-for="item in devNameOptions"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-else
            style="width: 100%"
            v-model="formInline.deviceName"
            placeholder="行为类型"
            class="placeOption"
            size="small"
          >
            <el-option
              v-for="item in devNameOptions"
              :key="item.type"
              :label="item.label"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <!-- 行为级别 -->
          <el-select
            style="width: 100%"
            v-model="formInline.level"
            placeholder="行为级别"
            size="small"
            class="location"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.type"
              :label="item.label"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
        </el-col>
      </el-row>
      <div>
        <div class="monitor_center">
          <div class="outer">
            <div class="suninfo_cont" key="monitor.vio_count">违规行为数</div>
            <hr />
            <div id="vio_count" v-if="this.user.osId == 20">{{ data2number }}</div>
            <div id="vio_type_count" v-if="this.user.osId == 10">{{ data6number }}</div>
          </div>
          <div class="outer">
            <div class="suninfo_cont" key="monitor.danger_count">威胁行为数</div>
            <hr />
            <div id="vio_count" v-if="this.user.osId == 20">{{ data3number }}</div>
            <div id="vio_type_count" v-if="this.user.osId == 10">{{ data7number }}</div>
          </div>
          <div class="outer">
            <div class="suninfo_cont" key="monitor.sensitive_count">敏感行为数</div>
            <hr />
            <div id="sensitive_count">{{ data4number }}</div>
          </div>
          <div class="outer">
            <div class="suninfo_cont" key="monitor.event_count">安全事件数</div>
            <hr />
            <div id="event_count">{{ data5number }}</div>
          </div>
        </div>
        <div class="monitor_center">
          <div class="outer">
            <div class="suninfo_cont" key="monitor.vio_type_count">违规类型数</div>
            <hr />
            <div id="vio_type_count" v-if="this.user.osId == 20">{{ data2_number }}</div>
            <div id="vio_type_count" v-if="this.user.osId == 10">{{ data6_number }}</div>
          </div>
          <div class="outer">
            <div class="suninfo_cont" key="monitor.danger_type_count">威胁类型数</div>
            <hr />
            <div id="vio_type_count" v-if="this.user.osId == 20">{{ data3_number }}</div>
            <div id="vio_type_count" v-if="this.user.osId == 10">{{ data7_number }}</div>
          </div>
          <div class="outer">
            <div class="suninfo_cont" key="monitor.sensitive_type_count">敏感类型数</div>
            <hr />
            <div id="sensitive_type_count">{{ data4_number }}</div>
          </div>
          <div class="outer">
            <div class="suninfo_cont" key="monitor.event_name_count">安全类型数</div>
            <hr />
            <div id="event_name_count">{{ data5_number }}</div>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        size="mini"
        :data="tableData"
        stripe
        fit
        tooltip-effect="dark"
        class="tableClass"
        style="width: 99%; margin: 10px; height: 340px; overflow: auto"
        @sort-change="sort"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }"
        :default-sort="{ prop: 'createTime', order: 'ascending' }"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="类型"
          prop="typeName"
        >
          <template slot-scope="scope">
            {{ scope.row.typeName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="行为类型"
          prop="type"
        >
          <!-- <template slot-scope="scope">
            {{ scope.row.type }}
          </template> -->
          <template slot-scope="scope">
            <template v-if="scope.row.type == 0"> 设备越狱 </template>
            <template v-else-if="scope.row.type == 8"> SIM卡变更 </template>
            <template v-else-if="scope.row.type == 13"> SD卡变更 </template>
            <template v-else-if="scope.row.type == 9"> 违规外联 </template>
            <template v-else-if="scope.row.type == 5"> 地理围栏 </template>
            <template v-else-if="scope.row.type == 1"> 系统版本 </template>
            <template v-else-if="scope.row.type == 7"> 流量异常 </template>
            <template v-else-if="scope.row.type == 14"> 设备电量 </template>
            <template v-else-if="scope.row.type == 15"> 存储空间 </template>
            <template v-else-if="scope.row.type == 12"> 必装应用 </template>
            <template v-else-if="scope.row.type == 2"> 应用黑白名单 </template>
            <template v-else-if="scope.row.type == 33"> WIFI黑白名单 </template>
            <template v-else-if="scope.row.type == 36"> 系统功能 </template>
            <template v-else-if="scope.row.type == 'S_CHECK_INJECT_STATUS'">
              注入攻击
            </template>
            <template v-else-if="scope.row.type == 'S_CHECK_FRAME_ATTACK'">
              框架攻击
            </template>
            <template v-else-if="scope.row.type == 'S_CHECK_DEBUG_STATUS'">
              调试行为
            </template>
            <template v-else-if="scope.row.type == 'S_CHECK_LOCATION_FRAUD'">
              位置欺诈
            </template>
            <template v-else-if="scope.row.type == 'S_CHECK_DOMAIN'"> 域名欺诈 </template>
            <template v-else-if="scope.row.type == 'S_CHECK_PLUGIN'"> 程序外挂 </template>
            <template v-else-if="scope.row.type == 'S_CHECK_SIGNATURE'">
              应用破解
            </template>
            <template v-else-if="scope.row.type == 'S_CHECK_ABNORMAL_CA'">
              HTTPS劫持
            </template>
            <template v-else-if="scope.row.type == 'S_CHECK_WIFI_PROXY'">
              HTTP代理
            </template>
            <template v-else-if="scope.row.type == 'S_CHECK_MULTI_APK'">
              应用多开
            </template>
            <template v-else-if="scope.row.type == 'S_CHECK_FREQUENCY_ACCOUNT'">
              高频更换账号</template
            >
            <template v-else-if="scope.row.type == 'S_CHECK_FREQUENCY_IP'">
              高频更换IP</template
            >
            <template v-else-if="scope.row.type == 'S_CHECK_FREQUENCY_LOCATION'">
              高频更换位置</template
            >
            <template v-else-if="scope.row.type == 'S_CHECK_FREQUENCY_RESTART'">
              高频启动应用</template
            >
            <template v-else-if="scope.row.type == 'S_FRAME_ATTACH'"> 框架软件</template>
            <template v-else-if="scope.row.type == 'S_CHECK_DANGER_APPS'">
              风险应用</template
            >
            <template v-else-if="scope.row.type == 'S_CHECK_SYS_USER_CA'">
              根证书异常</template
            >
            <template v-else-if="scope.row.type == 'S_ADB_ENABLED'"> USB调试</template>
            <template v-else-if="scope.row.type == 'S_CHECK_ROOT_STATUS'">
              ROOT/越狱</template
            >
            <template v-else-if="scope.row.type == 'S_ALLOW_MOCK_LOCATION'">
              允许模拟位置</template
            >
            <template v-else-if="scope.row.type == 'S_CHECK_VM_STATUS'"> 模拟器</template>
            <template v-else-if="scope.row.type == 'S_CHECK_CUSTOM_ROM'">
              定制ROM</template
            >
            <template v-else-if="scope.row.type == 'S_CHECK_DEVICE'"> 设备伪造</template>
            <template v-else-if="scope.row.type == 'S_CHECK_SCREEN_PASSWORD'">
              锁屏密码未开启</template
            >
            <template v-else-if="scope.row.type == 'S_CHECK_SYSTEM_TIME'">
              系统时间异常</template
            >
            <template v-else-if="scope.row.type == 'S_CHECK_MEMORY_CHANGE'">
              内存篡改</template
            >
            <template v-else-if="scope.row.type == 'S_MACOS_STATUS'"> macOS</template>
            <template v-else-if="scope.row.type == 'S_WINDOWS_STATUS'"> Windows</template>
            <template v-else-if="scope.row.type == 'S_VPN_STATUS'"> VPN</template>
            <template v-else-if="scope.row.type == 'S_CLOUD_PHONE_STATUS'">
              云手机</template
            >
            <template v-else-if="scope.row.infoType == 'sensitive'">
              应用敏感行为</template
            >
            <template v-else-if="scope.row.infoType == 'excessed'">
              应用越权行为</template
            >
            <template v-else-if="scope.row.eventName">
              {{ scope.row.eventName }}</template
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="行为级别"
          prop="level"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color: #d9001b; cursor: auto"
              v-if="scope.row.level == '3'"
              >高</el-button
            >
            <el-button
              type="text"
              style="color: #f59a23; cursor: auto"
              v-if="scope.row.level == '2'"
              >中</el-button
            >
            <el-button
              type="text"
              style="color: #00cdcd; cursor: auto"
              v-if="scope.row.level == '1'"
              >低</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="应用名称"
          prop="name"
        >
          <!-- <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template> -->
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="应用版本"
          prop="version"
        >
          <!-- <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template> -->
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="位置"
          prop="city"
        >
          <!-- <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template> -->
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="外网IP"
          prop="outIp"
        >
          <!-- <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template> -->
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="上报时间" prop="createtime">
          <!-- <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template> -->
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <pagination
        style="margin-right: 10px"
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination> -->
    </div>
  </div>
</template>
<script>
// import naviGation from '@/components/hearder/index' //面包屑
import options from "@/utils/country-level3-data"; //三级城市数据
import pagination from "@/components/pagination/page";
import Eldialog from "@/components/elDialog/index.vue";
import name from "@/components/elDialog/index.vue";
export default {
  components: { pagination, Eldialog },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      // disabledDate(time) {
      //     return time.getTime() > Date.now();
      //   },
      isdevNameOptions: true,
      devNameOptions1: [],
      pickerOptions1: {},
      pickerOptions0: {},
      tradeSelections1: [],
      // 联动
      key: 1, //table  key
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头      //   分页
      currentPage: 1, //当前页
      pageSize: 100000, //每页显示条数
      total: 0,
      orderColume: "createTime", //排序字段
      orderRule: "DESC", //排序规则ASC、DESC
      outerVisible: false,
      tableData: [], //列表data
      detailData: [], //详情data
      typeOptions: [
        { type: "1", label: "类型" },
        { type: "2", label: "违规行为" },
        { type: "3", label: "威胁行为" },
        { type: "4", label: "敏感行为" },
        { type: "5", label: "安全事件" },
      ], //行为类型

      levelOptions: [
        { type: "", label: "行为级别" },
        { type: "3", label: "高" },
        { type: "2", label: "中" },
        { type: "1", label: "低" },
      ], //行为级别
      devNameOptions: [{ type: "1", label: "行为类型" }], //设备名称
      formInline: {
        // 选择日期
        startTime: "",
        endTime: "",
        level: "",
        //下拉
        reason: "",
        // deviceName: "",
        location: [],
        // deviceName: "行为类型",
        // 设备名称
        devName: "",
        input: "",
      },
      user: {
        osId: "",
      },
      name: "",
      placeholder: "设备名称",
      seleShift: "devicename", // 选中下拉框转译
      // options: [],
      props: { multiple: true },
      selectedOptions: [], //存放默认值
      cityOptions: options, // 城市数据
      formCustom: {
        Detail: "", //详细地址信息
        DetailMap: "", //城市地址详细地址拼接
        address: "", //省份城市
      },
      // 配置项数据
      tbodyData: [],
      number: 0,
      number1: 0,
      data1: [{ value: "", label: "行为类型" }],
      data2: [
        { type: "", label: "行为类型" },
        { type: "0", label: "设备越狱" },
        { type: "8", label: "SIM卡变更" },
        { type: "13", label: "SD卡变更" },
        { type: "9", label: "违规外联" },
        { type: "5", label: "地理围栏" },
        { type: "1", label: "系统版本" },
        { type: "7", label: "流量异常" },
        { type: "14", label: "设备电量" },
        { type: "15", label: "存储空间" },
        { type: "12", label: "必装应用" },
        { type: "2", label: "应用黑白名单" },
        { type: "33", label: "WIFI黑白名单" },
        { type: "36", label: "系统功能" },
      ],
      data5: [
        { type: "", label: "行为类型" },
        { type: "0", label: "设备越狱" },
        { type: "5", label: "地理围栏" },
        { type: "1", label: "系统版本" },
        { type: "14", label: "设备电量" },
        { type: "15", label: "存储空间" },
        { type: "36", label: "系统功能" },
      ],
      // 违规行为  iOS  行为类型
      data5: [
        { type: "", label: "行为类型" },
        { type: "0", label: "设备越狱" },
        { type: "5", label: "地理围栏" },
        { type: "1", label: "系统版本" },
        { type: "14", label: "设备电量" },
        { type: "15", label: "存储空间" },
        { type: "36", label: "系统功能" },
      ],
      // 威胁行为  安卓  行为类型
      data3: [
        { type: "", label: "行为类型" },
        { type: "S_CHECK_INJECT_STATUS", label: "注入攻击" },
        { type: "S_CHECK_FRAME_ATTACK", label: "框架攻击" },
        { type: "S_CHECK_DEBUG_STATUS", label: "调试行为" },
        { type: "S_CHECK_LOCATION_FRAUD", label: "位置欺诈" },
        { type: "S_CHECK_DOMAIN", label: "域名欺诈" },
        { type: "S_CHECK_PLUGIN", label: "程序外挂" },
        { type: "S_CHECK_SIGNATURE", label: "应用破解" },
        { type: "S_CHECK_ABNORMAL_CA", label: "HTTPS劫持" },
        { type: "S_CHECK_WIFI_PROXY", label: "HTTP代理" },
        { type: "S_CHECK_MULTI_APK", label: "应用多开" },
        { type: "S_CHECK_FREQUENCY_ACCOUNT", label: "高频更换账号" },
        { type: "S_CHECK_FREQUENCY_IP", label: "高频更换IP" },
        { type: "S_CHECK_FREQUENCY_LOCATION", label: "高频更换位置" },
        { type: "S_CHECK_FREQUENCY_RESTART", label: "高频启动应用" },
        { type: "S_FRAME_ATTACH", label: "框架软件" },
        { type: "S_CHECK_DANGER_APPS", label: "风险应用" },
        { type: "S_CHECK_SYS_USER_CA", label: "根证书异常" },
        { type: "S_ADB_ENABLED", label: "USB调试" },
        { type: "S_CHECK_ROOT_STATUS", label: "ROOT/越狱" },
        { type: "S_ALLOW_MOCK_LOCATION", label: "允许模拟位置" },
        { type: "S_CHECK_VM_STATUS", label: "模拟器" },
        { type: "S_CHECK_CUSTOM_ROM", label: "定制ROM" },
        { type: "S_CHECK_DEVICE", label: "设备伪造" },
        { type: "S_CHECK_SCREEN_PASSWORD", label: "锁屏密码未开启" },
        { type: "S_CHECK_SYSTEM_TIME", label: "系统时间异常" },
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' },
        { type: "S_CHECK_MEMORY_CHANGE", label: "内存篡改" },
        { type: "S_WINDOWS_STATUS", label: "Windows" },
        { type: "S_VPN_STATUS", label: "VPN" },
        { type: "S_CLOUD_PHONE_STATUS", label: "云手机" },
        { type: "S_CHECK_SCREENSHOT_STATUS", label: "截屏阻断解除" },
      ],
      data6: [
        { type: "", label: "行为类型" },
        { type: "S_CHECK_INJECT_STATUS", label: "注入攻击" },
        { type: "S_CHECK_DEBUG_STATUS", label: "调试行为" },
        { type: "S_CHECK_DOMAIN", label: "域名欺诈" },
        { type: "S_CHECK_SIGNATURE", label: "应用破解" },
        { type: "S_CHECK_ABNORMAL_CA", label: "HTTPS劫持" },
        { type: "S_CHECK_WIFI_PROXY", label: "HTTP代理" },
        { type: "S_FRAME_ATTACH", label: "框架软件" },
        { type: "S_CHECK_ROOT_STATUS", label: "ROOT/越狱" },
        { type: "S_CHECK_VM_STATUS", label: "模拟器" },
        { type: "S_CHECK_SYSTEM_TIME", label: "系统时间异常" },
        { type: "S_MACOS_STATUS", label: "macOS" },
        { type: "S_VPN_STATUS", label: "VPN" },
      ],

      data4: [
        { type: "", label: "行为类型" },
        { type: "sensitive", label: "应用敏感行为" },
        { type: "excessed", label: "应用越权行为" },
      ],
      data7: [{ label: "", name: "行为类型" }],
      number: "1",
      data2number: 0,
      data2_number: 0,
      data3number: 0,
      data3_number: 0,
      data4number: 0,
      data4_number: 0,
      data5number: 0,
      data5_number: 0,
      data6number: 0,
      data6_number: 0,
      data7number: 0,
      data7_number: 0,
    };
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter((i) => val.indexOf(i.eng) >= 0);
      this.key - this.key + 1;
    },
  },

  created() {},

  //取token
  beforeMount() {
    const token = sessionStorage.getItem("userName");
  },

  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem("user"));
    this.user = user;
    console.log(this.user.osId, "------------");
    this.formInline.endTime = this.$moment().format("YYYY-MM-DD");
    this.formInline.startTime = this.$moment().subtract("days", 7).format("YYYY-MM-DD");
    var parme = {
      startTime: this.formInline.startTime,
      endTime: this.formInline.endTime,
      udid: JSON.parse(window.sessionStorage.getItem("user")).deviceUDID,
      orderColume: this.orderColume,
      orderRule: this.orderRule,
      // reason: this.formInline.deviceName, //行为类型
      // level: this.formInline.level, //行为级别
      reason: "",
      level: "",
      currentPage: this.currentPage,
      pageSize: 10000,
    };
    console.log(parme);
    this.getDeviceName(parme);
    this.appEvent(parme);
    this.tactful(parme);
    this.appDanger(parme);
    this.violations(parme);
  },

  methods: {
    quchong(arr) {
      var arr = arr,
        result = [],
        i,
        j,
        len = arr.length;
      for (i = 0; i < len; i++) {
        for (j = i + 1; j < len; j++) {
          if (arr[i].type === arr[j].type) {
            j = ++i;
          }
        }
        result.push(arr[i]);
      }
      return result.length;
    },
    // 违规行为
    async Violation(parme) {
      const res = await this.$axios.post(
        "/httpServe/violations/selectByUDIDselectByUDID",
        parme,
        true
      );
      console.log(res);
      res.data.forEach((item) => {
        item.typeName = "违规行为";
      });
      var data = [];
      data.push(...this.data1);
      data.push(...this.data2);
      data.push(...this.data5);
      data.push(...this.data3);
      data.push(...this.data6);
      data.push(...this.data4);
      data.push(...this.data7);
      data.push(...this.devNameOptions1);
      if (res.data.length) {
        this.data2number = res.data.length;
        if (this.user.osId == 20) {
          this.$nextTick(() => {
            console.log(res.data.length, "接口请求回来渲染");
            this.data2number = res.data.length;
          });
          this.data2_number = this.quchong(res.data);
        } else {
          this.data6number = res.data.length;
          this.data6_number = this.quchong(res.data);
        }
      }
        (this.data2number = 0),
          (this.data2_number = 0),
          (this.data3number = 0),
          (this.data3_number = 0),
          (this.data7number = 0),
          (this.data7_number = 0),
          (this.data4number = 0),
          (this.data4_number = 0),
          (this.data5number = 0),
          (this.data5_number = 0),
          (this.data6number = 0),
          (this.data6_number = 0);
        this.data7number = 0;
        this.data7_number = 0;
      console.log(res.data);
      this.tableData = res.data;
      this.tableData.sort((a, b) => {
        return this.$moment(b.time).valueOf() - this.$moment(a.time).valueOf();
      });
      this.total = res.data.length;
    },
    // 威胁行为
    async threaten(parme) {
      const res = await this.$axios.post(
        "/rest/v5/monitorex/appDanger/selectByUDID",
        parme,
        true
      );
      res.data.forEach((item) => {
        item.typeName = "威胁行为";
      });
      var data = [];
      data.push(...this.data1);
      data.push(...this.data2);
      data.push(...this.data5);
      data.push(...this.data3);
      data.push(...this.data6);
      data.push(...this.data4);
      data.push(...this.data7);
      data.push(...this.devNameOptions1);
      if (res.data.length) {
        this.data3number = res.data.length;
        if (this.user.osId == 20) {
          this.$nextTick(() => {
          this.data3number = res.data.length;
        });
          this.data3_number = this.quchong(res.data);
        } else {
          this.data7number = res.data.length;
          this.data7_number = this.quchong(res.data);
        }
      }
      (this.data2number = 0),
          (this.data2_number = 0),
          (this.data3number = 0),
          (this.data3_number = 0),
          (this.data7number = 0),
          (this.data7_number = 0),
          (this.data4number = 0),
          (this.data4_number = 0),
          (this.data5number = 0),
          (this.data5_number = 0),
          (this.data6number = 0),
          (this.data6_number = 0);
        this.data7number = 0;
        this.data7_number = 0;
      
      this.tableData = res.data;
      this.tableData.sort((a, b) => {
        return this.$moment(b.time).valueOf() - this.$moment(a.time).valueOf();
      });
      this.total = res.data.length;
    },
    // 敏感行为
    async tactful(parme) {
      const res = await this.$axios.post(
        "/rest/v5/monitorex/sensitive/selectByUDID",
        parme,
        true
      );
      res.data.forEach((item) => {
        item.typeName = "敏感行为";
      });
      var data = [];
      data.push(...this.data1);
      data.push(...this.data2);
      data.push(...this.data5);
      data.push(...this.data3);
      data.push(...this.data6);
      data.push(...this.data4);
      data.push(...this.data7);
      data.push(...this.devNameOptions1);
      if (res.data.length) {
        this.data4number = res.data.length;
        // this.data4number = res3.data.length;
        this.data4_number = this.quchong(res.data);
      } else {
        (this.data2number = 0),
          (this.data2_number = 0),
          (this.data6number = 0), //违规行为  iOS-10
          (this.data6_number = 0), //违规行为  iOS-10
          (this.data3number = 0),
          (this.data3_number = 0),
          (this.data7number = 0), //威胁行为  安卓-10
          (this.data7_number = 0), //威胁行为  安卓-10
          (this.data4number = 0),
          (this.data4_number = 0),
          (this.data5number = 0),
          (this.data5_number = 0),
          (this.data6number = 0),
          (this.data6_number = 0),
          (this.data7number = 0),
          (this.data7_number = 0);
      }
      this.tableData = res.data;
      this.tableData.sort((a, b) => {
        return this.$moment(b.time).valueOf() - this.$moment(a.time).valueOf();
      });
      this.total = res.data.length;
    },
    // 安全事件
    async security(parme) {
      const res = await this.$axios.post(
        "/rest/v5/monitorex/appEvent/selectByUDID",
        parme,
        true
      );
      res.data.forEach((item) => {
        item.typeName = "安全事件";
      });
      var data = [];
      data.push(...this.data1);
      data.push(...this.data2);
      data.push(...this.data5);
      data.push(...this.data3);
      data.push(...this.data6);
      data.push(...this.data4);
      data.push(...this.data7);
      data.push(...this.devNameOptions1);
      if (res.data.length) {
        this.data5number = res.data.length;
        // this.data5number = res4.data.length;
        this.data5_number = this.quchong(res.data);
      } else {
        (this.data2number = 0),
          (this.data2_number = 0),
          (this.data6number = 0), //违规行为  iOS-10
          (this.data6_number = 0), //违规行为  iOS-10
          (this.data3number = 0),
          (this.data3_number = 0),
          (this.data7number = 0), //威胁行为  安卓-10
          (this.data7_number = 0), //威胁行为  安卓-10
          (this.data4number = 0),
          (this.data4_number = 0),
          (this.data5number = 0),
          (this.data5_number = 0),
          (this.data6number = 0),
          (this.data6_number = 0),
          (this.data7number = 0),
          (this.data7_number = 0);
      }
      this.tableData = res.data;
      this.tableData.sort((a, b) => {
        return this.$moment(b.time).valueOf() - this.$moment(a.time).valueOf();
      });
      this.total = res.data.length;
    },
    //1   列表
    async violations(parme) {
      const res1 = await this.$axios.post(
        "/httpServe/violations/selectByUDIDselectByUDID",
        parme,
        true
      );
      res1.data.forEach((item) => {
        item.typeName = "违规行为";
      });
      console.log(res1.data, 1111);
      if (this.user.osId == 20) {
        this.data2number = res1.data.length;
        this.data2_number = this.quchong(res1.data);
      } else {
        this.data6number = res1.data.length;
        this.data6_number = this.quchong(res1.data);
      }

      const res2 = await this.$axios.post(
        "/rest/v5/monitorex/appDanger/selectByUDID",
        parme,
        true
      );
      res2.data.forEach((item) => {
        item.typeName = "威胁行为";
      });
      console.log(res2, 2222);
      if (this.user.osId == 20) {
        this.data3number = res2.data.length;
        this.data3_number = this.quchong(res2.data);
      } else {
        this.data7number = res1.data.length;
        this.data7_number = this.quchong(res2.data);
      }
      const res3 = await this.$axios.post(
        "/rest/v5/monitorex/sensitive/selectByUDID",
        parme,
        true
      );
      res3.data.forEach((item) => {
        item.typeName = "敏感行为";
      });
      console.log(res3, 3333);
      this.data4number = res3.data.length;
      this.data4_number = this.quchong(res3.data);
      // var parma = {}
      const res4 = await this.$axios.post(
        "/rest/v5/monitorex/appEvent/selectByUDID",
        parme,
        true
      );
      res4.data.forEach((item) => {
        item.typeName = "安全事件";
      });
      console.log(res4, 4444);
      this.data5number = res4.data.length;
      this.data5_number = this.quchong(res4.data);
      var data = [];
      data.push(...this.data1);
      data.push(...this.data2);
      data.push(...this.data5);
      data.push(...this.data3);
      data.push(...this.data6);
      data.push(...this.data4);
      data.push(...this.data7);
      data.push(...this.devNameOptions1);
      var res = [];
      res.push(...res1.data);
      res.push(...res2.data);
      res.push(...res3.data);
      res.push(...res4.data);
      res.push();

      for (let index = 0; index < res.length; index++) {
        for (let j = 0; j < data.length; j++) {
          if (res[index].type == data[j].type) {
            res[index].type = data[j].type;
          }
        }
      }
      this.tableData = res;
      this.tableData.sort((a, b) => {
        return this.$moment(b.time).valueOf() - this.$moment(a.time).valueOf();
      });
      this.total = res.length;
    },

    //2
    async appDanger(parme) {
      // var parma = {}
    },
    //3
    async sensitive(parme) {
      // var parma = {}
    },
    //4 違規行爲
    async appEvent(parme) {},
    // 获取事件名称
    async getDeviceName() {
      var parme = { type: "event" };
      const res = await this.$axios.post(
        "/httpServe/monitorExEventRule/selectNameList",
        parme,
        true
      );
      this.devNameOptions1 = res.data;
      this.devNameOptions1 = [...this.data7, ...this.devNameOptions1];
      this.devNameOptions1.forEach((i) => (i.label = i.name));
      // var obj = { type: "", label: "行为类型" };
      // this.devNameOptions1.unshift(obj);
      // console.log(this.devNameOptions,"事件名称")
      //  console.log(this.reasonOptions, "事件名称");
    },
    formInlinechang(val) {
      // alert(val)
      console.log(val);
      console.log(this.formInline);
      console.log(this.formInline.reason);
      this.isdevNameOptions = true;
      this.number = val;
      if (val == 1) {
        this.devNameOptions = this.data1;
      } else if (val == 2) {
        if (this.user.osId == 20) {
          this.devNameOptions = this.data2;
        } else if (this.user.osId == 10) {
          console.log(this.user.osId, "00000000");
          this.devNameOptions = this.data5;
        }
      } else if (val == 3) {
        if (this.user.osId == 20) {
          this.devNameOptions = this.data3;
        } else if (this.user.osId == 10) {
          this.devNameOptions = this.data6;
        }
      } else if (val == 4) {
        this.devNameOptions = this.data4;
      } else if (val == 5) {
        this.isdevNameOptions = false;
        // this.devNameOptions=data5
        // reasonOptions
        console.log(this.devNameOptions);
        this.devNameOptions = this.devNameOptions1;
      }
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 排序@sort-change="sort"
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.onSubmit(); //再次调用查询接口
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
    },
    changeDate(val) {
      console.log(this.formInline.endTime);
      console.log(this.formInline.startTime);
    },
    //查询
    async onSubmit() {
      var parme2 = {
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        udid: JSON.parse(window.sessionStorage.getItem("user")).deviceUDID,
        reason:
          this.formInline.deviceName == "行为类型" ? "" : this.formInline.deviceName, //行为类型
        level: this.formInline.level, //行为级别
        currentPage: this.currentPage,
        pageSize: 10000,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
      };
      if (this.number == 1) {
        this.violations(parme2);
      } else if (this.number == 2) {
        this.Violation(parme2);
      } else if (this.number == 3) {
        this.threaten(parme2);
      } else if (this.number == 4) {
        this.tactful(parme2);
      } else if (this.number == 5) {
        if (this.formInline.deviceName == "行为类型") {
          this.formInline.deviceName = "";
        }
        this.security(parme2);
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: 10000,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        udid: JSON.parse(window.sessionStorage.getItem("user")).deviceUDID,
        reason: this.formInline.deviceName, //行为类型
        level: this.formInline.level, //行为级别
        orderColume: this.orderColume,
        orderRule: this.orderRule,
      };
      console.log(parma, "列表查询onsubmit");
      // const res = await this.$axios.post("/appDanger/getDataInfo", parma, true);
      // console.log(res, "风险信息查询数据");
      // this.tableData = res.data.content;
      // this.total = res.data.total;
      // 对于为空的字段，显示N/A
      for (var i = 0; i < this.tableData.length; i++) {
        var obj2 = this.tableData[i];
        for (var j = 0; j < this.devNameOptions.length; j++) {
          // console.log("内层循环" + this.typeOptions[j].type + "次");
          if (this.tableData[i].type == this.devNameOptions[j].type) {
            this.tableData[i].type = this.devNameOptions[j].label;
          }
        }
      }
    },

    // 分页
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // this.queryObj.currentPage = page;
      // this.queryObj.pageSize = limit;
      // 调用查询方法
      this.violations();
    },
    // 详情
    details(info) {
      this.outerVisible = true;
      this.detailData = info;
      this.detailData["decideStr"] = eval(this.detailData.decide);
    },
    //配置项监听事件
    tableTitleTime() {
      // tableTitleTime(event) {
      // console.log(event, "监听表格配置项");
      // console.log(this.checkList, "kongshuzu");
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table::before {
  z-index: inherit;
}
.monitor_center {
  width: 100%;
  display: flex;
}
.monitor_center .outer {
  width: 130px;
  height: 80px;
  border: 1px solid #cccccc;
  margin-top: 12px;
  margin-left: 178px;
}
.monitor_center .outer:first-child {
  margin-left: 10px;
}
.monitor_center .outer div {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}
.monitor_center .outer hr {
  margin-top: 12px;
  width: 118px;
  margin-left: 5px;
  background: #cccccc;
}
.SensitiveMonitor {
  width: 100%;
  height: 100%;
}
.mt15 {
  padding: 10px 0;
}
.content {
  padding-left: 10px;
  // margin-top: 10px;
  padding-right: 10px;
  // .control {
  //   .el-form-item {
  //     .el-form-item__content {
  //       //   width: 130px !important;
  //       //   日期控件
  //       .block {
  //         .el-date-editor {
  //   width: 130px !important;
  //         }
  //       } //   下拉控件
  //       .el-select {
  //         width: 130px !important;
  //         .el-input {
  //           width: 130px !important;
  //         }
  //       }
  //       //位置控件
  //       .el-cascader {
  //         width: 130px !important;
  //       }
  //     }
  //   }
  // }
}
// .detailContent {
//   width: 100%;
//   .detailTitle {
//     margin-top: 8px;
//     font-size: 14px;
//     font-weight: 700;
//     height: 24px;
//     line-height: 24px;
//   }
//   .detailbox {
//     width: 100%;
//     display: flex;
//     font-size: 12px;
//     .boxLeft {
//       width: 50%;
//       padding-left: 68px;
//       line-height: 24px;
//       .group {
//         margin-top: 8px;
//         .detailLabel {
//           width: 44%;
//           text-align: left;
//         }
//         .detaildesc {
//           width: 56%;
//         }
//       }
//     }
//     .boxRight {
//       width: 50%;
//       line-height: 24px;
//       .group {
//         margin-top: 8px;
//         .detailLabel {
//           width: 23%;
//           text-align: left;
//         }
//         .detaildesc {
//           width: 67%;
//           display: inline-block;
//           overflow-y: visible;
//         }
//       }
//     }
//   }
//   .boxBot {
//     width: 100%;
//     padding-left: 68px;
//     font-size: 12px;
//     .group {
//       margin-top: 8px;
//       .detailLabel {
//         width: 22%;
//         text-align: left;
//         padding-right: 5px;
//       }
//       .detaildesc {
//         width: 78%;
//         display: inline-block;
//       }
//     }
//   }
// }
// .el-form {
//   width: 100%;
//   margin-left: 0px;
//   margin-top: 1px;
// }
.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 130px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px;
}
.el-form-item__content {
  width: 100%;
  /* display: flex; */
  margin: 0 !important;
}
.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
.placeOption {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-input .el-input__inner::placeholder {
    color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep .el-input .el-input__inner::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-input .el-input__inner::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-input .el-input__inner:-ms-input-placeholder {
    color: #606266 !important;
  }
}
</style>
