<template>
  <div style="display: flex;">
    <div style="margin-top: 20px">
      <el-form ref="form" :model="form" label-width="190px">
        <!-- <el-form-item label="锁定设备"> {{ form.deviceName }}</el-form-item> -->
        <el-form-item label="锁定设备">
          <span>（此操作将把设备锁定）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="lockDevice">锁定设备</el-button>
          </p>
        </el-form-item>
        <el-form-item label="密码锁定" v-if="user.osId == 20">
          <span>（此操作将把设备锁定，同时重置PIN码，仅对Android6及以下设备有效）</span>
          <p style="margin-left: 2%; display: flex;height:28px;line-height: 28px;">
            <el-button type="primary" size="mini" @click="password">密码锁定</el-button>
            <el-input size="mini" v-model="input2" style="width: 30%; margin-left: 10px" placeholder="请输入锁定密码"></el-input>
          </p>
        </el-form-item>
        <el-form-item label="清除密码" v-if="user.osId == 20">
          <span>（此操作将把设备上的锁定密码清空，Android6及以下设备有效）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="clearpassword">清除密码</el-button>
          </p>
        </el-form-item>
        <el-form-item label="清除数据">
          <span>（此操作将清除设备上受保护应用的业务数据）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="Cleardata">清除数据</el-button>
          </p>
        </el-form-item>
        <el-form-item label="强制注销">
          <span>（此操作将强制设备退出登录）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="Forcelogoff">强制注销</el-button>
          </p>
        </el-form-item>
        <el-form-item label="卸载码" v-if="user.osId == 20">
          <span>（此操作将生成卸载码，仅支持合作厂商）</span>
          <p style="margin-left: 2%; display: flex;height:28px;line-height: 28px;">
            <el-button :type="type" size="mini" @click="uninstall" :disabled="disabled">生成卸载码</el-button>
            <el-input disabled size="mini" style="width: 30%; margin-left: 10px" v-model="form.uninstallCode" v-if="uninstallCode"></el-input>
          </p>
        </el-form-item>
        <el-form-item label="设备丢失" v-if="user.osId == 10">
          <span>（此操作将把设备设置为丢失模式，仅支持监督模式）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="deviceLost">设备丢失</el-button>
          </p>
        </el-form-item>
        <!-- <el-form-item label="恢复出厂" v-if="user.osId == 10">
          <span>（此操作将把设备恢复到出厂设置状态）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="recovery">恢复出厂</el-button>
          </p>
        </el-form-item> -->
      </el-form>
    </div>
    <div style="margin-top: 20px">
      <el-form ref="form" :model="form" label-width="300px">
        <el-form-item label="恢复出厂">
          <span>（此操作将把设备恢复到出厂设置状态）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="recovery">恢复出厂</el-button>
          </p>
        </el-form-item>
        <el-form-item label="提取日志">
          <span>（此操作将提取应用运行日志）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="extract">提取日志</el-button>
          </p>
        </el-form-item>
        <!-- 安卓 -->
        <el-form-item label="安装应用" v-if="user.osId == 20">
          <span>（此操作将静默安装指定的应用，仅支持合作厂商）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="Install">安装应用</el-button>
          </p>
        </el-form-item>
        <!-- ios -->
        <el-form-item label="安装应用" v-if="user.osId == 10">
          <span>（此操作将向设备推送安装指定应用的命令）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="Install">安装应用</el-button>
          </p>
        </el-form-item>
        <!-- 安卓 -->
        <el-form-item label="卸载应用" v-if="user.osId == 20">
          <span>（此操作将静默卸载指定的应用，仅支持合作厂商）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="dump">卸载应用</el-button>
          </p>
        </el-form-item>
        <!-- ios -->
        <el-form-item label="卸载应用" v-if="user.osId == 10">
          <span>（此操作将静默卸载指定的应用）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="dumps">卸载应用</el-button>
          </p>
        </el-form-item>
        <!-- ios -->
        <el-form-item label="解除丢失" v-if="user.osId == 10">
          <span>（此操作将把设备解除丢失模式，仅支持监督模式）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="disableLost">解除丢失</el-button>
          </p>
        </el-form-item>
        <!-- 安卓 -->
        <el-form-item label="设备关机" v-if="user.osId == 20">
          <span>（此操作将使设备关机，仅支持合作厂商）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="shutdown">设备关机</el-button>
          </p>
        </el-form-item>
        <el-form-item label="设备重启" v-if="user.osId == 20">
          <span>（此操作将重启设备，仅支持合作厂商）</span>
          <p style="margin-left: 2%">
            <el-button type="primary" size="mini" @click="restart">设备重启</el-button>
          </p>
        </el-form-item>
      </el-form>
    </div>
    <!-- 安装应用 -->
    <Eldialog @handleClose="handleClose" :title="'安装应用'" :btnTitle="'安装'" :dialogVisible="dialogVisible" @heightCustom="heightCustom" @determine="joinType" :flagbtn="true" :cancelBtn="true" :determineBtn="true">
      <div style="height: 450px">
        <el-input v-model="input" size="small" style="width: 200px; margin-bottom: 10px" placeholder="请输入应用名称" @keyup.enter.native="searchDev">
          <i style="cursor: pointer" class="el-icon-search el-input__icon" slot="suffix" @click="searchDev">
          </i>
        </el-input>
        <el-table size="mini" ref="multipleTable" :data="gridData" class="sort_table" tooltip-effect="dark" style="width: 99%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange1" :default-sort="{ prop: 'e.lastupdatetime', order: 'descending' }" @sort-change="sort">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="appName" :show-overflow-tooltip="true" label="应用名称" width="150">
          </el-table-column>
          <el-table-column prop="packageName" :show-overflow-tooltip="true" label="应用ID" width="150">
          </el-table-column>
          <el-table-column prop="versionName" :show-overflow-tooltip="true" label="版本" width="100">
          </el-table-column>
          <el-table-column prop="lastupdatetime" :show-overflow-tooltip="true" label="修改时间">
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          共<b>{{ gridData.length }}</b>条记录
        </p>
        <!-- <p>
          <el-button
            @click="joinType"
            style="margin-left: 540px"
            type="primary"
            size="small"
            >安装</el-button
          >
        </p> -->
      </div>
    </Eldialog>
    <!-- 20 安卓卸载应用 -->
    <Eldialog @handleClose="handleClose" :title="'卸载应用'" :btnTitle="'卸载'" :dialogVisible="install" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="xiezai">
      <div style="height: 450px">
        <el-input v-model="input1" size="small" style="width: 200px; margin-bottom: 10px" placeholder="请输入应用名称" @keyup.enter.native="searchDevs">
          <i style="cursor: pointer" class="el-icon-search el-input__icon" slot="suffix" @click="searchDevs">
          </i>
        </el-input>
        <el-table size="mini" ref="multipleTable" :data="gridData" class="sort_table" tooltip-effect="dark" style="width: 99%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange1" :default-sort="{ prop: 'e.lastupdatetime', order: 'descending' }" @sort-change="sort">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="appName" :show-overflow-tooltip="true" label="应用名称" width="120">
          </el-table-column>
          <el-table-column prop="packageName" :show-overflow-tooltip="true" label="应用ID" width="150">
          </el-table-column>
          <el-table-column prop="versionName" :show-overflow-tooltip="true" label="版本" width="100">
          </el-table-column>
          <el-table-column prop="lastUpdateTimeStr" :show-overflow-tooltip="true" label="修改时间">
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          共<b>{{ gridData.length }}</b>条记录
        </p>
        <!-- <p>
          <el-button
            @click="xiezai"
            style="margin-left: 530px"
            type="primary"
            size="small"
            >卸载</el-button
          >
        </p> -->
      </div>
    </Eldialog>
    <!-- 10 ios卸载应用 -->
    <Eldialog @handleClose="handleClose" :title="'卸载应用'" :btnTitle="'卸载'" :dialogVisible="installs" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="xiezai">
      <div style="height: 450px">
        <el-input
          v-model="input1"
          size="small"
          style="width: 200px; margin-bottom: 10px"
          placeholder="请输入应用名称"
          @keyup.enter.native="searchDevss"
        >
          <i
            style="cursor: pointer"
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="searchDevss"
          >
          </i>
        </el-input>
        <el-table size="mini" ref="multipleTable" :data="gridData" class="sort_table" tooltip-effect="dark" style="width: 99%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange1" :default-sort="{ prop: 'e.createTime', order: 'descending' }" @sort-change="sort">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" :show-overflow-tooltip="true" label="应用名称" width="120">
          </el-table-column>
          <el-table-column prop="identifier" :show-overflow-tooltip="true" label="应用ID" width="150">
          </el-table-column>
          <el-table-column prop="shortVersion" :show-overflow-tooltip="true" label="版本" width="100">
          </el-table-column>
          <el-table-column prop="createTime" :show-overflow-tooltip="true" label="修改时间">
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          共<b>{{ gridData.length }}</b>条记录
        </p>
        <!-- <p>
          <el-button
            @click="xiezai"
            style="margin-left: 530px"
            type="primary"
            size="small"
            >卸载</el-button
          >
        </p> -->
      </div>
    </Eldialog>
  </div>
</template>

<script>
import Eldialog from '@/components/elDialog'
// 弹框
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { Eldialog, dialogInfo },
  // props: {
  //   dictData: {
  //     type: Object,
  //     default: {},
  //   },
  // },
  data() {
    return {
      type: 'primary',
      dataTolCount: '', //分页
      input: '', //查询
      input1: '', //查询
      gridData: [],
      form: {
        // name: "11",
        user: {},
        uninstallCode: ''
      },
      user: {
        osId: ''
      },
      disabled: false,
      dialogVisible: false, //弹框组件默认--关闭
      install: false, //安卓弹框组件默认--关闭
      installs: false, //ios弹框组件默认--关闭
      flag: 1, // 显示当前 / 编辑页面
      isnlist: [],
      datal: [],
      input2: '',
      uninstallCode: false,
      Instpage: {
        orderColume: 'e.lastupdatetime', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
      editlist: []
    }
  },
  created() {},
  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    setTimeout(() => {
      console.log(this.user.osId)
    }, 3000)
    this.getlist(user)
  },

  methods: {
    // 排序
    sort(column) {
      this.queryObj.orderColume = column.prop
      console.log(column.order, column.prop)
      if (column.prop == 'd.deviceName') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.deviceName'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.deviceName'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'u.loginName') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'u.loginName'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'u.loginName'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'u.userName') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'u.userName'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'u.userName'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'g.groupName') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'g.groupName'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'g.groupName'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.osId') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.osId'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.osId'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.belong') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.belong'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.belong'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.status') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.status'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.status'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.isViolation') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.isViolation'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.isViolation'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.signStatus') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.signStatus'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.signStatus'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'devcon.conStatus') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'devcon.conStatus'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'devcon.conStatus'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'd.healthDegree') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'd.healthDegree'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'd.healthDegree'
          this.page.orderRule = 'DESC'
        }
      } else if (column.prop == 'devcon.lastLoginTime') {
        if (column.order == 'ascending') {
          this.page.orderColume = 'devcon.lastLoginTime'
          this.page.orderRule = 'ASC'
        } else {
          this.page.orderColume = 'devcon.lastLoginTime'
          this.page.orderRule = 'DESC'
        }
      }
      this.tableList()
    },
    // 关闭
    handleClose() {
      this.visibaelFlag = false //提示消息  关闭
      this.dialogVisible = false //详情弹框  关闭
      this.install = false //安卓详情弹框  关闭
      this.installs = false //ios详情弹框  关闭
      this.input = ''
      this.input1 = ''
    },
    heightCustom() {
      this.height.height = this.num
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false
    },
    async getlist(user) {
      var param = {
        deviceUDID: user.deviceUDID
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/device/command/get',
        param,
        true
      )
      // const res = await this.$axios.post("/httpServe/device/uninstallCode/get", param, true);
      console.log(res)
      // this.form = res.data.uninstallCode;
      // console.log(this.form);
    },
    // 锁定设备 lockDevice
    async lockDevice() {
      var param = {
        type: 'DeviceLock', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm('此操作将使设备锁屏，确定锁定设备？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "锁定设备命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '锁定设备命令下发成功',
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 锁定密码 password
    async password() {
      var param = {
        type: 'DeviceLock', //标记状态
        deviceUDID: this.user.deviceUDID,
        pwd: this.input2
      }
      console.log(param)
      this.$confirm('确定密码锁定？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("/api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "密码锁定命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '密码锁定命令下发成功',
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 清除密码 clearpassword
    async clearpassword() {
      var param = {
        type: 'ClearPasscode', //标记状态
        deviceUDID: this.user.deviceUDID,
        pwd: ''
      }
      console.log(param)
      this.$confirm('确定清除密码？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("/api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "清除密码命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '清除密码命令下发成功',
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 清除数据 lockDevice
    async Cleardata() {
      var param = {
        type: 'removeappdata', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(
        '此操作将清除设备上受保护应用的业务数据，确定清除数据？',
        '提示信息',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'persdsd',
          type: 'warning'
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "清除数据命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '清除数据命令下发成功',
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 强制注销 lockDevice
    async Forcelogoff() {
      var param = {
        type: 'logOut', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm('此操作将强制设备退出登录，确定强制注销？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "强制注销命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '强制注销命令下发成功',
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 卸载码 lockDevice
    async uninstall() {
      var param = {
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/device/command/create',
        // "/api/device/uninstallCode/create.do",
        param,
        true
      )
      this.form.uninstallCode = res.data.uninstallCode
      console.log(res)
      this.visibaelFlag = true
      this.uninstallCode = true
      this.disabled = true
      this.type = 'info'
    },
    // 恢复出厂 recovery
    async recovery() {
      var param = {
        type: 'EraseDevice', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm(
        '此操作将把设备恢复至出厂设置状态，确定恢复出厂？',
        '提示信息',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'persdsd',
          type: 'warning'
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "恢复出厂命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '恢复出厂命令下发成功',
            type: 'success',
            offset: 100
          })
        })

        .catch(() => {
          return false
        })
    },
    // 提取日志 extract
    async extract() {
      var param = {
        type: 'extractLog', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm('确定提取日志？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "提取日志命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '提取日志命令下发成功',
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 安装应用 Install
    async Install() {
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      this.dialogVisible = true
      var param = {
        // osType: user.osId == 20 ? "Android" : "iOS",
        deviceUDID: user.deviceUDID,
        // type: "InstallApplication", //安装
        // status: user.status,
        // deviceId: user.id,
        osTypeId: user.osId,
        // udid: user.deid,
        appName: this.input, //input搜索
        currentPage: 1,
        pageSize: 100000,
        orderColume: this.Instpage.orderColume,
        orderRule: this.Instpage.orderRule
      }
      console.log(param)

      const res = await this.$axios.post(
        '/httpServe/entApp/getDataInfo',
        param,
        true
      )
      console.log(res)
      this.gridData = res.data.content
      // conosle.log(this.gridData);
      this.isnlist = res.data.content
      this.dataTolCount = res.data.total
      this.$forceUpdate()
      // this.$emit("sun", "提取日志命令下发成功");
      // this.visibaelFlag = true;
    },
    // 查询
    async searchDev() {
      if (this.input != '') {
        this.gridData = this.gridData.filter((item) => {
          return item.appName.includes(this.input)
        })
      } else {
        // this.Install();
        this.gridData = this.isnlist
      }
    },
    // 安装
    async joinType() {
      var entAppId = []
      this.datal.forEach((i) => {
        entAppId.push(String(i.id))
      })
      if (this.datal.length == 0) {
        // this.$emit("sun", "请至少选中一项");
        this.$message({
          message: '请至少选中一项',
          type: 'error',
          offset: 100
        })
      } else if (this.datal.length >= 1) {
        this.$confirm('是否下发安装应用命令？', '提示信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'persdsd',
          type: 'warning'
        })
          .then(async () => {
            var param = {
              type: 'InstallApplication', //标记状态
              deviceUDID: this.user.deviceUDID,
              entAppId: entAppId //应用id
            }
            console.log(param)
            const res = await this.$axios.post(
              '/httpServe/device/command/exec',
              param,
              true
            )
            console.log(res)
            // this.$emit("sun", "下发安装应用命令成功");
            // this.visibaelFlag = true;
            this.$message({
              message: '下发安装应用命令成功',
              type: 'success',
              offset: 100
            })
          })
          .catch(() => {
            return false
          })
      }
    },

    // 卸载应用 dump
    // ios  10
    async dumps() {
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      this.installs = true
      var param = {
        // deviceId: user.id,
        deviceUDIDs: [user.deviceUDID],
        osType: user.osId,
        // status: user.status,
        // type: "RemoveApplication", //卸载
        // isSystem: 1,
        // osTypeId: user.osId,
        // udid: user.deid,
        // where: {
        //   name: "",
        // },
        orderColume: 'createTime',
        orderRule: 'DESC',
        currentPage: 1
        // "appName":this.input1
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/entApp/selectApp',
        param,
        true
      )
      console.log(res)
      this.gridData = res.data.content
      console.log(this.gridData)
      this.isnlist = res.data.total
      this.$forceUpdate()
      // this.$emit("sun", "提取日志命令下发成功");
      // this.visibaelFlag = true;
    },
    searchDevss() {
      console.log(11111111,"--------------");
      if (this.input1 != "") {
        this.gridData = this.gridData.filter((item) => {
          return item.name.includes(this.input1);
        });
      } else {
        // this.dump();
        this.dumps();
        console.log(3232,"9999999");
        this.gridData = this.isnlist;
      }
    },
    // 安卓   20
    async dump() {
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      this.install = true
      var param = {
        // deviceId: user.id,
        deviceUDIDs: [user.deviceUDID],
        osType: user.osId,
        // status: user.status,
        // type: "RemoveApplication", //卸载
        // isSystem: 1,
        // osTypeId: user.osId,
        // udid: user.deid,
        // where: {
        //   name: "",
        // },
        orderColume: 'lastupdatetime',
        orderRule: 'DESC',
        currentPage: 1
        // "appName":this.input1
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/entApp/selectApp',
        param,
        true
      )
      console.log(res)
      this.gridData = res.data.content
      console.log(this.gridData)
      this.isnlist = res.data.total
      this.$forceUpdate()
      // this.$emit("sun", "提取日志命令下发成功");
      // this.visibaelFlag = true;
    },
    // 卸载
    async xiezai() {
      var removeId = []
      this.datal.forEach((i) => {
        removeId.push(String(i.id))
      })
      if (this.datal.length == 0) {
        // this.$emit("sun", "请至少选中一项");
        this.$message({
          message: '请至少选中一项',
          type: 'error',
          offset: 100
        })
      } else if (this.datal.length >= 1) {
        this.$confirm('是否下发卸载应用命令？', '提示信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'persdsd',
          type: 'warning'
        })
          .then(async () => {
            var param = {
              type: 'RemoveApplication', //标记状态
              deviceUDID: this.user.deviceUDID,
              removeId: removeId
            }
            console.log(param)
            const res = await this.$axios.post(
              '/httpServe/device/command/exec',
              param,
              true
            )
            console.log(res)
            // this.$emit("sun", "下发卸载应用命令成功");
            // this.visibaelFlag = true;
            this.$message({
              message: '下发卸载应用命令成功',
              type: 'success',
              offset: 100
            })
          })
          .catch(() => {
            return false
          })
      }
    },
    async searchDevs() {
      console.log(11111111,"--------------");
      if (this.input1 != "") {
        this.gridData = this.gridData.filter((item) => {
          return item.appName.includes(this.input1)
        })
      } else {
        this.dump();
        // this.dumps();
        console.log(3232,"9999999");
        this.gridData = this.isnlist;
      }
    },
    handleSelectionChange1(val) {
      this.datal = val
    },
    // 设备丢失 iOS
    async deviceLost(){
      var param = {
        type: "EnableLostMode", //标记状态
        deviceUDID: this.user.deviceUDID,
      };
      console.log(param);
      this.$confirm("此操作将设备设置为丢失模式，确定要设备丢失？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$axios.post("/httpServe/device/command/exec", param, true);
          console.log(res);
          this.$message({
            message: "设备丢失命令下发成功",
            type: "success",
            offset: 100,
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 解除丢失 iOS
    async disableLost(){
      var param = {
        type: "DisableLostMode", //标记状态
        deviceUDID: this.user.deviceUDID,
      };
      console.log(param);
      this.$confirm("此操作将设备解除丢失模式，确定要解除丢失？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$axios.post("/httpServe/device/command/exec", param, true);
          console.log(res);
          this.$message({
            message: "解除丢失命令下发成功",
            type: "success",
            offset: 100,
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 设备关机 shutdown
    async shutdown() {
      var param = {
        type: 'shutDown', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm('确定设备关机？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "设备关机命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '设备关机命令下发成功',
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    },
    // 设备重启 restart
    async restart() {
      var param = {
        type: 'restartDevice', //标记状态
        deviceUDID: this.user.deviceUDID
      }
      console.log(param)
      this.$confirm('确定设备重启？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res)
          // this.$emit("sun", "设备重启命令下发成功");
          // this.visibaelFlag = true;
          this.$message({
            message: '设备重启命令下发成功',
            type: 'success',
            offset: 100
          })
        })
        .catch(() => {
          return false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 1px !important;
}
.el-form-item__label {
  color: #000000 !important;
}
.wrap .group .msg_l0 {
  color: #807e7e !important;
}
</style>