<template>
  <div>
    <div class="daochu">
      <div class="jiben">
        <div class="news">基本信息：</div>
        <div style="margin-left: 30px;width: 450px;">
          <el-checkbox-group v-model="checkList" @change="checkListChangedis">
            <el-checkbox label="deviceName" disabled>设备名称</el-checkbox>
            <el-checkbox label="deviceTime">在/离线时长</el-checkbox>
            <el-checkbox label="lastLoginTime">最近登录时间</el-checkbox>  
          </el-checkbox-group>
          <!-- <el-checkbox-group v-model="checkList">
            <el-checkbox label="deviceTime">在/离线时长</el-checkbox>
          </el-checkbox-group> -->
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="healthDegree">健康度</el-checkbox>
            <el-checkbox label="brand">设备厂商</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="isViolation">合规状态</el-checkbox>
            <el-checkbox label="model">设备型号</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="isJailBreak">设备越狱</el-checkbox>
            <el-checkbox label="serial">序列号</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="status">设备状态</el-checkbox>
            <el-checkbox label="bindStatus">解绑状态</el-checkbox>
            <el-checkbox label="osId">操作系统</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="conStatus">在线状态</el-checkbox>
            <el-checkbox label="osVersion">系统版本</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="tunnelStatus">隧道状态</el-checkbox>
            <el-checkbox label="surfType">上网方式</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="battery">电池电量</el-checkbox>
            <el-checkbox label="number">手机号码</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="availMemorySize">内存使用</el-checkbox>
            <el-checkbox label="sdk">SDK版本</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="availDataSize">存储使用</el-checkbox>
            <el-checkbox label="imei">IMEI</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="version">客户端版本</el-checkbox>
            <el-checkbox label="deviceUDID">UDID</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="isActive">设备管理器</el-checkbox>
            <el-checkbox label="signStatus">标记状态</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="deviceDay">在/离线天数</el-checkbox>
            <el-checkbox label="belong">设备归属</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="jibens">
        <div class="news">硬件信息：</div>
        <div style="margin-left: 30px;width: 450px;">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="phoneType">手机制式</el-checkbox>
            <el-checkbox label="board">主板</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="netWorkType">网络类型</el-checkbox>
            <el-checkbox label="releaseVersionId">版本</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="device">设备参数</el-checkbox>
            <el-checkbox label="hasIccCard">是否有SIM卡</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="product">手机厂商</el-checkbox>
            <el-checkbox label="simCountryIso">SIM卡国别</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="hardware">硬件名称</el-checkbox>
            <el-checkbox label="simState">SIM卡状态</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="deviceSoftwareVersion">软件版本号</el-checkbox>
            <el-checkbox label="simSerialNumber">SIM卡序列号</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="subscriberId">手机用户ID</el-checkbox>
            <el-checkbox label="simOperatorName">SIM卡供应商</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="hardwarBrand">硬件制造商</el-checkbox>
            <el-checkbox label="timeStr">BUILD时间</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="systemBrand">系统定制商</el-checkbox>
            <el-checkbox label="tags">BUILD描述标签</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="hardwarSerial">硬件串行好</el-checkbox>
            <el-checkbox label="cpuAbi">CPU指令集</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="voiceMailNumber">语音信息号码</el-checkbox>
            <el-checkbox label="cpuAbi2">CPU指令集2</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="networkOperatorName">网络运营商名称</el-checkbox>
            <el-checkbox label="deivceId">手机设备ID（IMEI）</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="networkCountryIso">网络供应商国别</el-checkbox>
            <el-checkbox label="releaseVersion">用户可见的版本字符串</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="jibenss">
        <div class="news">用户信息：</div>
        <div style="margin-left: 30px;width: 450px;">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="loginName">用户名</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="userName">姓名</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="userSex">性别</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="groupFullName">部门</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="phone">电话</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="email">邮箱</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <el-button
      type="primary"
      size="small"
      style="margin: 20px 180px; width: 80px"
      @click="daochu"
      >导出</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    searchValArr: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      userId: "1",
      checkList: [
        "deviceName",
        "isViolation",
        "status",
        "bindStatus",
        "conStatus",
        "deviceDay",
        "deviceTime",
        "brand",
        "model",
        "osId",
        "osVersion",
        "surfType",
        "imei",
        "belong",
        "lastLoginTime",
        "networkOperatorName",
        "loginName",
        "userName",
        "groupFullName",
        "phone",
        "email",
        "tunnelStatus",
      ],
      form: {
        deviceName: "",
      },
      searchname:"",
      placeholder:"",
      devosType:""
    };
  },

  mounted() {
    let searchname = window.sessionStorage.getItem("searchname");
    this.searchname=searchname;
    //  console.log(window.sessionStorage.getItem("searchname"))

    let placeholder = window.sessionStorage.getItem("placeholder");
    console.log(placeholder,"let");
    this.placeholder=placeholder
    console.log(placeholder,"let2");
    // console.log(window.sessionStorage.getItem("placeholder"))
  },

  methods: {
    checkListChangedis(val){
      console.log(val)

    },
    async daochu() {
    // async daochu(seaname,plaholde) {
      // console.log("下载模板");
      let token2 =sessionStorage.getItem("Authorization") ?sessionStorage.getItem("Authorization"):
        "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A";
      let userString = decodeURIComponent(escape(window.atob(token2.split(".")[1])));
      // let userString = decodeURIComponent(
      //   escape(window.atob(this.token.split(".")[1]))
      // );
      this.userId = JSON.parse(userString).userId;
      console.log(this.userId, "ididiididi");
      if(this.$route.query.jumpPageName){
        console.log(this.$route.query.searchKey,"this.$route.query.searchKey")
        this.placeholder='osVersion'
      }
      console.log(this.searchValArr,"this.searchValArr")
      var params = {
        currentPage: this.searchValArr.pageNumber,
        pageSize: this.searchValArr.rowNumber,
        deviceStatus: this.searchValArr.deviceStatus,
        isViolation: this.searchValArr.isViolation,
        signStatus: this.searchValArr.signStatus,
        osType: this.searchValArr.osType,
        belong: this.searchValArr.belong,
        conStatus: this.searchValArr.conStatus,
        conStateRate: this.searchValArr.conStateRate,
        orderColume: "devcon.lastLoginTime",
        orderRule: "DESC",
	      osType:this.searchValArr.osType,
        searchKey: this.placeholder, //设备名称 下拉框 姓名 用户名 部门 //搜索下拉框
        searchContent: this.searchValArr.input, //搜索内容
        exportField:this.checkList
      };
      console.log(params);
      const res = await this.$axios.postExport(
        "/httpManageExport/device/exportFile",
        params,
        false,
        this.userId
      );
      // debugger
      // const res = await this.$axios.post("/httpServe/device/exportFile", params, true);
      console.log(res);
      // const res = await this.$axios.get("/api/device/exportFile.do", true);
      // await this.$axios.downloadFile(res.data);
      const link = document.createElement("a");
      console.info(new Date());

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportDevice_" + Date.parse(new Date()) + ".xls";
      // console.log(fileName);
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.daochu {
  margin-top: 10px;
  display: flex;
}
.jiben {
  margin-left: 7%;
  width: 460px;
}
.jibens {
  margin-left: 8%;
  width: 556px;
}
.jibenss {
  margin-left: 8%;
  width: 556px;
}
.news {
  font-size: 14px;
  margin-top: 20px;
}
.el-checkbox {
  width: 130px;
  padding: 2%;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}
</style>
