import { render, staticRenderFns } from "./yingy.vue?vue&type=template&id=16994f91&scoped=true&"
import script from "./yingy.vue?vue&type=script&lang=js&"
export * from "./yingy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16994f91",
  null
  
)

export default component.exports