<template>
  <div>
    <navi-gation />
    <div v-if="flag == 1">
      <!-- 选择框   搜索 -->
      <el-row :gutter="3" class="mt15" style="display: flex">
        <!-- 操作系统 -->
        <el-col :span="2">
          <el-select style="width: 100%" size="small" :placeholder="$t('DeviceManage.Device.Auditing.Pleaseselect')" v-model="queryObj.osType">
            <el-option v-for="item in options1" :key="item.value" :label="$t(`DeviceManage.Device.OperatSystem.${item.label}`)"  :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 设备归属 -->
        <el-col :span="2">
          <el-select style="width: 100%" size="small" :placeholder="$t('DeviceManage.Device.Auditing.Pleaseselect')" v-model="queryObj.belong">
            <el-option v-for="item in options2" :key="item.value" :label="$t(`DeviceManage.Device.equipownership.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 设备状态 -->
        <el-col :span="2">
          <el-select
            style="width: 100%"
            size="small"
            :placeholder="$t('DeviceManage.Device.Auditing.Pleaseselect')"
            v-model="queryObj.deviceStatus"
          >
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="$t(`DeviceManage.Device.Devicestatus.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <!-- 合规状态 -->
        <el-col :span="2">
          <el-select style="width: 100%" size="small" :placeholder="$t('DeviceManage.Device.Auditing.Pleaseselect')" v-model="queryObj.isViolation">
            <el-option v-for="item in options4" :key="item.value" :label="$t(`DeviceManage.Device.Complianstatus.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 标记状态 -->
        <el-col :span="2">
          <el-select style="width: 100%" size="small" :placeholder="$t('DeviceManage.Device.Auditing.Pleaseselect')" v-model="queryObj.signStatus">
            <el-option v-for="item in options5" :key="item.value" :label="$t(`DeviceManage.Device.Markerstate.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 在线状态 -->
        <el-col :span="2">
          <el-select style="width: 100%" size="small" :placeholder="$t('DeviceManage.Device.Auditing.Pleaseselect')" v-model="queryObj.conStatus" @change="op">
            <el-option v-for="item in options6" :key="item.value" :label="$t(`DeviceManage.Device.Onlinestate.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 在线频度 -->
        <el-col :span="2">
          <el-select style="width: 100%" size="small" :placeholder="$t('DeviceManage.Device.Auditing.Pleaseselect')" v-model="queryObj.conStateRate">
            <el-option v-for="item in options7" :key="item.value" :label="$t(`DeviceManage.Device.Onlinefrequen.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 设备名称 -->
        <select-input
          :options="options8"
          @selectSearchChange="selectSearchChange"
          @searchList="searchList"
        ></select-input>

      </el-row>
      <!-- 按钮 -->

      <el-row style="padding: 0px 10px 10px 10px">
        <el-col :span="24">
          <el-button-group>
            <el-button :disabled="binSeleted" :type="typeInfo6"  size="small" @click="batchUnbind">{{$t('DeviceManage.Device.Equipmentunty')}}</el-button> 
            <el-button
              style="margin-left: 1px"
              @click="Forcelogoff"
              :disabled="hasSeleted"
              :type="typeInfo4"
              size="small"
              >{{$t('DeviceManage.Device.Forcedcancell')}}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <!-- 点击选中只显示对应内容 -->
      <div
        style="
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 48px;
          top: 2px;
          cursor: pointer;
        "
      >
        <!-- 弹出框 -->
        <el-popover trigger="click" popper-class="LinkageTable">
          <el-checkbox-group @change="tableTitleTime" v-model="checkList">
            <el-checkbox
              v-for="(item, index) in tradeSelections"
              :key="index"
              :label="$t(`DeviceManage.Device.Tablelinkage.${item.eng}`)"
              :disabled="item.flag"
              @click.native="sss(item.eng, index, item.flag)"
              >{{ $t(`DeviceManage.Device.Tablelinkage.${item.name}`) }}</el-checkbox
            >
          </el-checkbox-group>
          <img
            slot="reference"
            src="@/images/showConfig.png"
            alt=""
            style="
              top: 72px;
              width: 20px;
              height: 20px;
              left: 13px;
              position: absolute;
              cursor: pointer;
            "
          />
        </el-popover>
      </div>
      <!-- 列表 -->
      <el-row class="formBox" :gutter="16" style="padding: 0 20px">
        <div
          @click="maskLayer"
          class="callenia"
          style="
            width: 55px;
            height: 0px;
            background: rgba(255,  255,  255, 0.1);
            position: absolute;
            top: 10px;
            left: 21px;
            z-index: 9999;
            font-size: 12px;
            z-index: 1;
          "
        >
        </div>
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          :row-key="getRowKeys"
          stripe
          ref="multipleTable"
          :data="dictData"
          size="mini"
          @selection-change="handleSelectionChange"
          @select="delobj"
          @sort-change="sort"
          :default-sort="{ prop: 'devcon.lastLoginTime', order: 'descending' }"
          style="width: 100%"
          class="tableClass"
        >
          <template>
            <el-table-column v-if="$filter.nameFilter(formThead, tradeSelections)">
              <el-table-column
                :show-overflow-tooltip="true"
                width="120"
                v-for="(item, index) in formThead"
                :key="index"
                :label="item.name"
              >
                <template slot-scope="scope">
                  {{ scope.row[item.eng] }}
                </template>
              </el-table-column>
            </el-table-column>
          </template>
          <el-table-column
            :reserve-selection="true"
            align="center"
            type="selection"
            width="65"
            :selectable="selectEnable"
          >
          </el-table-column>
          <el-table-column
            prop="d.deviceName"
            :label="$t('public.DeviceName')"
            width="160"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <div class="el-linkStyle" @click="Equipment(row)">
                <el-link type="warning">
                  <span style="font-size: 12px">{{ row.deviceName }}</span>
                </el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="udid"
            label="UDID"
            width="160"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 19].isChecked"
          >
            <template v-slot="{ row }">
              <div class="el-linkStyle" @click="Equipment(row)">
                <el-link type="warning">
                  <span style="font-size: 12px">
                    {{ row.deviceUDID }}
                    <!-- {{ '65b6fe9d222ea8bfa98862e9e_98862e9e' }} -->
                  </span>
                </el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="u.loginName"
            :label="$t('public.Username')"
            width="90"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              {{ row.userInfo.loginName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="u.userName"
            :label="$t('public.Name')"
            width="90"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 17].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.userInfo.userName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="g.groupName"
            :label="$t('public.Section')"
            width="110"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 16].isChecked"
          >
            <template v-slot="{ row }">
              <span v-if="row.groups.groupFullName">
                {{ row.groups.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="deviceGroupName"
            :label="'设备组'"
            width="110"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 15].isChecked"
          >
            <template v-slot="{ row }">
              <span v-if="row.deviceGroupName">
                {{ row.deviceGroupName }}
              </span>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="d.osId"
            :label="$t('public.OperatingSystem')"
            width="95"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 14].isChecked"
          >
            <template v-slot="{ row }">
              <template v-if="row.osId == 10"> iOS </template>
              <template v-else-if="row.osId == 20"> Android </template>
              <!-- v6.2.0新增Windows -->
              <template v-else-if="row.osId == 30"> Windows </template>
              <!-- v6.3.0新增Linux -->
              <template v-else-if="row.osId == 40"> Linux </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="d.os_version"
            :label="$t('public.SystemVersion')"
            width="95"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 13].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.osVersion }}
            </template>
          </el-table-column>
          <el-table-column
            prop="d.belong"
            :label="$t('DeviceManage.Device.Equipmentownership')"
            width="95"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 12].isChecked"
          >
            <template v-slot="{ row }">
              <template v-if="row.belong == 1">
                <span style="color: #575656">{{ $t("DeviceManage.Device.Firm") }}</span>
              </template>
              <template v-if="row.belong == 2">
                <span style="color: #575656">{{
                  $t("DeviceManage.Device.Personage")
                }}</span>
              </template>
              <template v-if="row.belong == 0 || row.belong == ''">
                <span style="color: #575656">{{
                  $t("DeviceManage.Device.Unknown")
                }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('DeviceManage.Device.EquipmentStatus')"
            width="95"
            prop="d.status"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 11].isChecked"
          >
            <template v-slot="{ row }">
              <!-- 等待注册 -->
              <template v-if="row.status == 10">
                <span style="color: #575656">{{
                  $t("DeviceManage.Device.Pendingregistration")
                }}</span>
              </template>
              <!-- 未激活 -->
              <template v-if="row.status == 5">
                <span style="color: #2ba245">{{
                  $t("DeviceManage.Device.BasicNews.Notactive")
                }}</span>
              </template>
              <!-- 已激活 -->
              <template v-if="row.status == 15">
                <span style="color: #575656">{{
                  $t("DeviceManage.Device.BasicNews.Active")
                }}</span>
              </template>
              <!-- <template v-if="row.status == 20">
                <span style="color: #1e8ae5">取消激活</span>
              </template> -->
              <!-- 注销 -->
              <template v-if="row.status == 25">
                <span style="color: #1e8ae5">{{
                  $t("DeviceManage.Device.SignOUT")
                }}</span>
              </template>
              <!-- 停用 -->
              <template v-if="row.status == 35">
                <span style="color: #ff0000">{{ $t("public.Outofservice") }}</span>
              </template>
            </template>
          </el-table-column>
          <!-- 流程状态 -->
          <el-table-column
            :label="$t('DeviceManage.Device.askStatus')"
            width="95"
            prop="askStatus"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 10].isChecked"
          >
            <template v-slot="{ row }">
              <!-- 未申请  -->
              <template v-if="row.askStatus == 0 || row.askStatus == 6">
                <span>{{ $t("DeviceManage.Device.NotAppliedFor") }}</span>
              </template>
              <!-- 待审批 -->
              <!-- <template v-if="row.askStatus == 0">
                <span >{{$t('DeviceManage.Device.PendingApproval')}}</span>
              </template> -->
              <!-- 审批中 -->
              <template v-if="row.askStatus == 2 || row.askStatus == 1">
                <span>{{ $t("DeviceManage.Device.inApproval") }}</span>
              </template>
              <!-- 已通过 -->
              <template v-if="row.askStatus == 3">
                <span>{{ $t("DeviceManage.Device.Passed") }}</span>
              </template>
              <!-- 已拒绝 -->
              <!-- <template v-if="row.askStatus == 4">
                <span >{{$t('DeviceManage.Device.rejected')}}</span>
              </template> -->
              <!-- 已废除 -->
              <!-- <template v-if="row.askStatus == 5">
                <span >{{$t('DeviceManage.Device.repealed')}}</span>
              </template> -->
              <!-- 已撤销 -->
              <!-- <template v-if="row.askStatus == 6">
                <span >{{$t('DeviceManage.Device.revoked')}}</span>
              </template> -->
              <!-- 未通过 -->
              <template
                v-if="row.askStatus == 7 || row.askStatus == 4 || row.askStatus == 5"
              >
                <span>{{ $t("DeviceManage.Device.Fail") }}</span>
              </template>
              <!-- 自动审批 -->
              <template v-if="row.askStatus == 8">
                <span>{{ $t("DeviceManage.Device.AutomaticApproval") }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('DeviceManage.Device.Bindingstate')"
            width="95"
            sortable="custom"
            prop="d.bind_status"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 9].isChecked"
          >
            <template v-slot="{ row }">
              <template v-if="row.bindStatus == 1">
                <span style="color: #2ba245">{{ $t("DeviceManage.Device.Bind") }}</span>
              </template>
              <template v-if="row.bindStatus == 0">
                <span style="color: #575656">{{ $t("DeviceManage.Device.Untie") }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('DeviceManage.Device.Compliancestatus')"
            width="95"
            sortable="custom"
            prop="d.isViolation"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 8].isChecked"
          >
            <template v-slot="{ row }">
              <template v-if="row.isViolation == 1">
                <span style="color: #2ba245">{{ $t("public.Normal") }}</span>
              </template>
              <template v-else-if="row.isViolation == 0">
                <span style="color: #ff0000">{{
                  $t("DeviceManage.Device.Getoutofline")
                }}</span>
              </template>
              <template v-else>
                <span style="color: #575656">{{
                  $t("DeviceManage.Device.Unknown")
                }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="d.signStatus"
            sortable="custom"
            :label="$t('DeviceManage.Device.Signstate')"
            width="95"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 5].isChecked"
          >
            <template v-slot="{ row }">
              <template v-if="row.signStatus == 1">
                <span style="color: #575656">{{
                  $t("DeviceManage.Device.Nnlabeled")
                }}</span>
              </template>
              <template v-if="row.signStatus == 2">
                <span style="color: #2ba245">{{
                  $t("DeviceManage.Device.Recovered")
                }}</span>
              </template>
              <template v-if="row.signStatus == 3">
                <span style="color: #ff0000">{{ $t("DeviceManage.Device.Lost") }}</span>
              </template>
              <template v-if="row.signStatus == 4">
                <span style="color: #1e8ae5">{{
                  $t("DeviceManage.Device.Scrapped")
                }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="devcon.conStatus"
            :label="$t('DeviceManage.Device.Onlinesta')"
            width="95"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 7].isChecked"
          >
            <template v-slot="{ row }">
              <template v-if="row.deviceConIMStatus.conStatus == 0">
                <span
                  style="color: #575656; cursor: pointer"
                  @mousemove="changeMove2(row)"
                  :title="timesMove"
                  >{{ $t("DeviceManage.Device.Offline") }}</span
                >
              </template>
              <template v-else-if="row.deviceConIMStatus.conStatus == 1">
                <span
                  style="color: #2ba245; cursor: pointer"
                  @mousemove="changeMove1(row)"
                  :title="timesMove"
                  >{{ $t("DeviceManage.Device.Online") }}</span
                >
              </template>
              <template v-else>
                <span
                  style="color: #575656; cursor: pointer"
                  @mousemove="changeMove(row)"
                  :title="timesMove"
                  >{{ $t("DeviceManage.Device.Unknown") }}</span
                >
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="d.tunnel_status"
            :label="$t('DeviceManage.Device.Tunnelstatus')"
            width="95"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 6].isChecked"
          >
            <template v-slot="{ row }">
              <template v-if="row.tunnelStatus == 0">
                <span style="color: #ff0000; cursor: pointer">{{
                  $t("public.Forbidden")
                }}</span>
              </template>
              <template v-else-if="row.tunnelStatus == 1">
                <span style="color: #2ba245; cursor: pointer">{{
                  $t("public.Enable")
                }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="d.healthDegree"
            :label="$t('DeviceManage.Device.Healthdegree')"
            width="80"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 4].isChecked"
          >
            <template v-slot="{ row }">
              <span v-if="row.healthDegree">{{ row.healthDegree }}</span>
              <span v-else>N/A</span>
            </template>
          </el-table-column>
          <el-table-column prop="d.createTime" :label="$t('DeviceManage.Device.jiuoTime')" width="80" sortable="custom"
            :show-overflow-tooltip="true" v-if="tradeSelections[tradeSelections.length - 3].isChecked">
            <template v-slot="{ row }">{{ row.createTime }}</template>
          </el-table-column>
          <el-table-column
            prop="devcon.lastLoginTime"
            :label="$t('DeviceManage.Device.lastLoginTime')"
            width="160"
            sortable="custom"
            :show-overflow-tooltip="true"
            v-if="tradeSelections[tradeSelections.length - 2].isChecked"
          >
            <template v-slot="{ row }">
              <span v-if="row.deviceConIMStatus.lastLoginTime == null"></span>
              <span v-else>{{ row.deviceConIMStatus.lastLoginTime }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')" align="left">
            <template v-slot="{ row }">
              <!-- 弹出框 -->
              <div class="shezhi1" v-if="row.osId == 10 || row.osId == 20">
                <el-popover
                  placement="left-start"
                  width="150"
                  trigger="hover"
                  style="text-align: center"
                >
                  <template
                  v-if="row.bindStatus == 1 "
                  >
                    <el-link
                      @click="appUnbind(row.id)"
                      type="warning"
                      :underline="false"
                      class="linkclass"
                    >
                      <span style="color: #575656" class="sitehovers"
                        >{{$t('DeviceManage.Device.Equipmentunty')}}</span
                      >
                    </el-link>
                  </template>
                  <template
                    v-if="
                      row.status == 15 || row.status == 25 || row.status == 35
                    "
                  >
                    <el-link
                      @click="celogoff(row)"
                      type="warning"
                      :underline="false"
                      class="linkclass"
                    >
                      <span style="color: #575656" class="sitehovers"
                        >{{$t('DeviceManage.Device.Forcedcancell')}}</span
                      >
                    </el-link>
                  </template>
                  <template slot="reference">
                    <span class="action_icon">
                      <img
                        @click="distribution(row)"
                        src="@/images/icon_setting.png"
                        :title="$t('DeviceManage.Device.Settings')"
                        alt=""
                        style="
                          width: 15px;
                          height: 15px;
                          margin-left: 5px;
                          cursor: pointer;
                        "
                      /> </span
                  ></template>
                </el-popover>
              </div>
              <!-- 弹出框 -->
              <!-- v6.2.0 Windows弹框区分 / v6.3.0 Linux弹框区分 -->
              <div class="shezhi1" v-if="row.osId == 30 || row.osId == 40">
                <el-popover
                  placement="left-start"
                  width="150"
                  trigger="hover"
                  style="text-align: center"
                >
                  <template
                  v-if="row.bindStatus == 1 "
                  >
                    <el-link
                      @click="appUnbind(row.id)"
                      type="warning"
                      :underline="false"
                      class="linkclass"
                    >
                      <span style="color: #575656" class="sitehovers"
                        >{{$t('DeviceManage.Device.Equipmentunty')}}</span
                      >
                    </el-link>
                  </template>
                  <template slot="reference">
                <span class="action_icon">
                  <img
                        @click="distribution(row)"
                        src="@/images/icon_setting.png"
                        :title="$t('DeviceManage.Device.Settings')"
                    alt=""
                        style="
                          width: 15px;
                          height: 15px;
                          margin-left: 5px;
                          cursor: pointer;
                        "
                      /> </span
                  ></template>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :page="queryObj.pageNumber"
          :limit="queryObj.rowNumber"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        />
        <ul
          class="tableCheckBox"
          style=""
          v-if="isCheckShow"
          @mouseleave="leave"
        >
          <li @click="curSelection()">当前页全选</li>
          <!-- <li @click="toggleAllSelection()">所有页全选</li> -->
          <li @click="toggleSelection()">取消全选</li>
        </ul>
      </el-row>
    </div>
    <!-- 新增 newly -->
    <newly
      v-if="flag == 2"
      :androidData="androidData"
      @sunline="sunline"
    ></newly>
    <!-- 编辑 newlyedit -->
    <newlyedit
      v-if="flag == 6"
      :androidData="androidData"
      @sunline="sunline"
    ></newlyedit>
    <!-- 导入页面 -->
    <Import
      v-if="flag == 3"
      :androidData="androidData"
      @sunline="sunline"
    ></Import>
    <!-- 导出 Import -->
    <derive v-if="flag == 4" :searchValArr="searchKeyArr"></derive>
    <!-- 详情 Equipment -->
    <Equipment v-if="flag == 5" @su="su" :androidData="androidData"></Equipment>
    <!-- 安装应用 -->
    <Eldialog
      @handleClose="handleClose"
      :title="'安装应用'"
      :btnTitle="'安装'"
      :dialogVisible="dialogVisible"
      @heightCustom="heightCustom"
      @determine="joinType"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
    >
      <!-- <Install @aaa="handleClose" :dictData="dataFrom" /> -->
      <div style="height: 450px">
        <el-input
          v-model="input"
          size="small"
          style="width: 200px; margin-bottom: 10px"
          placeholder="请输入应用名称"
          @keyup.enter.native="searchDev"
        >
          <i
            style="cursor: pointer"
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="searchDev"
          >
          </i>
        </el-input>
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          size="mini"
          ref="multipleTable"
          :data="gridData"
          class="tableClass"
          tooltip-effect="dark"
          style="width: 99%"
          @selection-change="editid"
          :default-sort="{ prop: 'e.lastupdatetime', order: 'descending' }"
          @sort-change="sort"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="appName"
            :show-overflow-tooltip="true"
            label="应用名称"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="packageName"
            :show-overflow-tooltip="true"
            label="应用ID"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="versionName"
            :show-overflow-tooltip="true"
            label="版本"
            width="110"
          >
          </el-table-column>
          <el-table-column
            prop="lastupdatetime"
            lastUpdateTimeStr
            :show-overflow-tooltip="true"
            label="修改时间"
          >
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          共<b>{{ gridData.length }}</b
          >条记录
        </p>
        <!-- <p style="margin-top: 150px">
            <el-button
              @click="joinType"
              style="margin-left: 540px"
              type="primary"
              size="small"
              >安装</el-button
            >
          </p> -->
      </div>
    </Eldialog>
    <!-- 卸载应用  20安卓-->
    <Eldialog
      @handleClose="handleClose"
      :title="'卸载应用'"
      :btnTitle="'卸载'"
      :dialogVisible="install"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="xiezai"
    >
      <div style="height: 450px">
        <el-input
          v-model="input"
          size="small"
          style="width: 200px; margin-bottom: 10px"
          placeholder="请输入应用名称"
          @keyup.enter.native="searchDevs"
        >
          <i
            style="cursor: pointer"
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="searchDevs"
          >
          </i>
        </el-input>
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          size="mini"
          ref="multipleTable"
          :data="gridDas"
          class="sort_table"
          tooltip-effect="dark"
          style="width: 99%"
          @selection-change="editid"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="appName"
            :show-overflow-tooltip="true"
            label="应用名称"
            width="110"
          >
          </el-table-column>
          <el-table-column
            prop="packageName"
            :show-overflow-tooltip="true"
            label="应用ID"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="versionName"
            :show-overflow-tooltip="true"
            label="版本"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="lastUpdateTimeStr"
            :show-overflow-tooltip="true"
            label="修改时间"
          >
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          共<b>{{ gridDas.length }}</b
          >条记录
        </p>
        <!-- <p>
            <el-button
              @click="xiezai"
              style="margin-left: 540px"
              type="primary"
              size="small"
              >卸载</el-button
            >
          </p> -->
      </div>
    </Eldialog>
    <!-- 卸载应用  10 ios-->
    <Eldialog
      @handleClose="handleClose"
      :title="'卸载应用'"
      :btnTitle="'卸载'"
      :dialogVisible="installs"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="xiezai"
    >
      <div style="height: 450px">
        <el-input
          v-model="input"
          size="small"
          style="width: 200px; margin-bottom: 10px"
          placeholder="请输入应用名称"
          @keyup.enter.native="searchDevss"
        >
          <i
            style="cursor: pointer"
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="searchDevss"
          >
          </i>
        </el-input>
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          size="mini"
          ref="multipleTable"
          :data="gridDatas"
          class="sort_table"
          tooltip-effect="dark"
          style="width: 99%"
          @selection-change="editid"
        >
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column
            prop="name"
            :show-overflow-tooltip="true"
            label="应用名称"
            width="110"
          >
          </el-table-column>
          <el-table-column
            prop="identifier"
            :show-overflow-tooltip="true"
            label="应用ID"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="shortVersion"
            :show-overflow-tooltip="true"
            label="版本"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            :show-overflow-tooltip="true"
            label="修改时间"
          >
          </el-table-column>
        </el-table>
        <p style="margin-top: 10px">
          共<b>{{ gridDatas.length }}</b
          >条记录
        </p>
        <!-- <p>
            <el-button
              @click="xiezai"
              style="margin-left: 540px"
              type="primary"
              size="small"
              >卸载</el-button
            >
          </p> -->
      </div>
    </Eldialog>
    <!-- 安全隧道-->
    <Eldialog
      :num="'40px'"
      :width="'300px'"
      @handleClose="handleClose"
      :title="'安全隧道'"
      :btnTitle="'确定'"
      :dialogVisible="tunne"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="security"
      style="margin-top: 26vh"
    >
      <el-radio-group v-model="queryObj.tunnelStatus" class="adio">
        <el-radio v-model="queryObj.tunnelStatus" label="1">启用</el-radio>
        <el-radio v-model="queryObj.tunnelStatus" label="0" class="displauno"
          >禁用</el-radio
        >
      </el-radio-group>
    </Eldialog>
    <!-- 设置安全隧道-->
    <Eldialog
      :num="'40px'"
      :width="'300px'"
      @handleClose="handleClose"
      :title="'安全隧道'"
      :btnTitle="'确定'"
      :dialogVisible="Safetytun"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="tunnelsecu"
      style="margin-top: 26vh"
    >
      <el-radio-group v-model="queryObj.tunnelStatus" class="adio">
        <el-radio v-model="queryObj.tunnelStatus" label="1">启用</el-radio>
        <el-radio v-model="queryObj.tunnelStatus" label="0" class="displauno"
          >禁用</el-radio
        >
      </el-radio-group>
    </Eldialog>

    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>
  
  <script>
import selectInput from "@/components/selectInput";
import Eldialog from "@/components/elDialog";
import naviGation from "@/components/hearder/index";
import pagination from "@/components/pagination/page.vue";
// 弹框
import dialogInfo from "@/components/promptMessage/index";
// 详情
import Equipment from "./components/Equipment.vue";
// 新增 newly
import newly from "./components/newly.vue";
// 编辑 newly
import newlyedit from "./components/newlyedit.vue";
// 导入 derive
import Import from "./components/Import.vue";
// 导出 derive
import derive from "./components/derive.vue";
// 安装应用
// import Install from "./components/Install.vue";
// // 安装应用
// import Installedit from "./components/Installedit.vue";
export default {
  components: {
    selectInput,
    Eldialog,
    naviGation,
    pagination,
    newly,
    dialogInfo,
    Equipment,
    // Install,
    // Installedit,
    Import,
    derive,
    newlyedit,
  },
  data() {
    return {
      groupFullNameShowByIndex: "", //用户部门截取的下标
      timesMove: "",
      btnJiagu1: false,
      number2: "",
      tradeSelections: [
        { eng: "deviceName", name: "设备名称", isChecked: true, flag: true },
        { eng: "udid", name: "UDID", isChecked: false, flag: false },
        { eng: "loginName", name: "用户名", isChecked: true, flag: true },
        { eng: "userName", name: "姓名", isChecked: false, flag: false },
        { eng: "groupFullName", name: "部门", isChecked: true, flag: false },
        { eng: "deviceGroupName", name: "设备组", isChecked: false, flag: false },
        { eng: "osId", name: "操作系统", isChecked: true, flag: false },
        { eng: "osVersion", name: "系统版本", isChecked: true, flag: false },
        { eng: "belong", name: "设备归属", isChecked: false, flag: false },
        { eng: "status", name: "设备状态", isChecked: true, flag: false },
        { eng: "askStatus", name: "流程状态", isChecked: true, flag: false },
        { eng: "bindStatus", name: "绑定状态", isChecked: true, flag: false },
        { eng: "isViolation", name: "合规状态", isChecked: true, flag: false },
        { eng: "conStatus", name: "在线状态", isChecked: true, flag: false },
        { eng: "tunnelStatus", name: "隧道状态", isChecked: true, flag: false },
        { eng: "signStatus", name: "标记状态", isChecked: true, flag: false },
        { eng: "healthDegree", name: "健康度", isChecked: false, flag: false },
        { eng: 'createTime', name: '激活时间', isChecked: false, flag: false },
        {
          eng: "lastLoginTime",
          name: "最近登录时间",
          isChecked: true,
          flag: false,
        },
        { eng: "decide", name: "操作", isChecked: true, flag: true },
      ], // isChecked：true选中(默认)false不选中    flag：true是禁用(不可编辑)false不禁用(可编辑)
      tradeSelections1: [],
      checkList: [
        "DeviceManage.Device.Tablelinkage.deviceName",
        "DeviceManage.Device.Tablelinkage.loginName",
        "DeviceManage.Device.Tablelinkage.groupFullName",
        "DeviceManage.Device.Tablelinkage.osId",
        "DeviceManage.Device.Tablelinkage.osVersion",
        "DeviceManage.Device.Tablelinkage.status",
        "DeviceManage.Device.Tablelinkage.askStatus",
        "DeviceManage.Device.Tablelinkage.bindStatus",
        "DeviceManage.Device.Tablelinkage.isViolation",
        "DeviceManage.Device.Tablelinkage.conStatus",
        "DeviceManage.Device.Tablelinkage.tunnelStatus",
        "DeviceManage.Device.Tablelinkage.signStatus",
        "DeviceManage.Device.Tablelinkage.lastLoginTime",
        "DeviceManage.Device.Tablelinkage.decide",
      ], //弹框 默认勾选 值，列表默认展示
      key: 1, //table  key
      formThead: [], //渲染表头

      // 下拉框
      options1: [
        {
          value: "",
          label: "操作系统",
        },
        {
          value: "10",
          label: "iOS",
        },
        {
          value: "20",
          label: "Android",
        },
        {
          value: "30",
          label: "Windows",
        },
        {
          value: "40",
          label: "Linux",
        },
      ],
      options2: [
        {
          value: "",
          label: "设备归属",
        },
        {
          value: "1",
          label: "企业",
        },
        {
          value: "2",
          label: "个人",
        },
      ],
      options3: [
        {
          value: "",
          label: "设备状态",
        },
        // {
        //   value: '15',
        //   label: '加入'
        // },
        // {
        //   value: '5',
        //   label: '新建'
        // },
        {
          value: "5",
          label: "未激活",
        },
        {
          value: "15",
          label: "已激活",
        },
        {
          // value: "20",
          value: "35",
          label: "停用",
        },
        {
          value: "25",
          label: "注销",
        },
      ],
      options4: [
        {
          value: "",
          label: "合规状态",
        },
        {
          value: "1",
          label: "正常",
        },
        {
          value: "0",
          label: "违规",
        },
      ],
      options5: [
        {
          value: "",
          label: "标记状态",
        },
        {
          value: "1",
          label: "未标记",
        },
        {
          value: "2",
          label: "已找回",
        },
        {
          value: "3",
          label: "已丢失",
        },
        {
          value: "4",
          label: "已报废",
        },
      ],
      options6: [
        {
          value: "",
          label: "在线状态",
        },
        {
          value: "1",
          label: "在线",
        },
        {
          value: "0",
          label: "离线",
        },
      ],
      options7: [
        {
          value: "",
          label: "在线频度",
        },
        {
          value: "on-1",
          label: "1天内在线",
        },
        {
          value: "on-7",
          label: "7天内在线",
        },
        {
          value: "on-30",
          label: "30天内在线",
        },
        {
          value: "on-90",
          label: "90天内在线",
        },
        {
          value: "off-1",
          label: "1天内离线",
        },
        {
          value: "off-7",
          label: "7天内离线",
        },
        {
          value: "off-30",
          label: "30天内离线",
        },
        {
          value: "off-90",
          label: "90天内离线",
        },
      ],
      options10: [
        {
          value: "",
          label: "在线频度",
        },
        {
          value: "on",
          label: "在线频度",
        },
        {
          value: "off",
          label: "在线频度",
        },
        {
          value: "on-1",
          label: "1天内在线",
        },
        {
          value: "on-7",
          label: "7天内在线",
        },
        {
          value: "on-30",
          label: "30天内在线",
        },
        {
          value: "on-90",
          label: "90天内在线",
        },
        {
          value: "off-1",
          label: "1天内离线",
        },
        {
          value: "off-7",
          label: "7天内离线",
        },
        {
          value: "off-30",
          label: "30天内离线",
        },
        {
          value: "off-90",
          label: "90天内离线",
        },
      ],
      options8: [
        {
          value: "devicename",
          label: "设备名称",
        },
        {
          value: "osVersion",
          label: "系统版本",
        },
        {
          value: "udid",
          label: "UDID",
        },
        {
          value: "loginName",
          label: "用户名",
        },
        {
          value: "userName",
          label: "姓名",
        },
        {
          value: "groupFullName",
          label: "部门",
        },
      ],
      queryObj: {
        currentPage: 1,
        pageSize: 10,
        rowNumber: 10,
        orderColume: "osId", //排序字段
        orderRule: "d.osId asc", //排序规则ASC、DESC
        osType: "",
        belong: "",
        deviceStatus: "",
        isViolation: "",
        signStatus: "",
        conStatus: "",
        conStateRate: "",
        searchKey: "设备名称",

        sort: "",
        // 查询
        input: "",
        pageNumber: 1,
        rowNumber: 10,
        // 安全隧道
        tunnelStatus: "0",
      },
      // 设置安全隧道
      nelStat: "",
      // 查询
      input: "",
      seleShift: "deviceName", // 选中下拉框转译
      placeholder: "设备名称",
      flag: 1, // 显示当前 / 编辑页面
      content: {
        devicename: "", //设备名称
        loginName: "", //用户名
        userName: "", //姓名
        groupFullName: "", //部门
        osid: "", //操作系统
        belong: "", //设备归属
        status: "", //设备状态
        isviolation: "", //合规状态
        conStatus: "", //在线状态
        signstatus: "", //标记状态
        lastupdatetime: "", //最近登录时间
      },
      androidData: {}, //子组件--Android编辑
      searchKeyArr: {}, //查询条件---导出
      delarr: [], //删除
      unbind: [], //删除
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", //二次提示
      dictData: [], // table表格数据
      gridData: [], // table表格数据
      gridDatas: [], // table表格数据
      gridDas: [], // table表格数据
      // 导入
      filename: "",
      form: {
        filename: "",
      },
      dataTolCount: "", //分页
      loading: false,
      dialogVisible: false, //弹框组件默认--关闭
      install: false, //弹框组件默认--关闭
      installs: false, //弹框组件默认--关闭
      tunne: false, //安全隧道 弹框组件默认--关闭
      Safetytun: false, //安全隧道 弹框组件默认--关闭
      dataFrom: {}, //安装应用组件

      pathImg: "",
      activeName: "first",
      installFlag: false,
      tableAn: [],
      tableAn1: [],
      checksign_type: true,
      path: "",
      downFlag: false,
      checked: true,
      iosData: {}, //子组件--ios编辑
      typeInfo: "info",
      typeInfo1: "info",
      typeInfo2: "info",
      typeInfo3: "info",
      typeInfo4: "info",
      typeInfo5: "info",
      typeInfo6: 'info',
      btnJiagu: true,
      rulesHTML: "dasdasd",
      num: 220,
      jiaGuVisible: false,
      flagbtn: false, //footer
      // 提示框的提示语
      titleInfo: "",
      // applyFlag: false, //选择应用
      // statusVisible: false, //状态弹框
      title: "应用详情", //应用详情title
      title1: "应用编辑",

      multipleSelection: [],
      total: 0,
      // 遮罩全选
      btnJiance: true,
      isCheckShow: false,
      hasSeleted: true,
      binSeleted: true,
      yongting: true,
      yongqi: true,
      zhuang: true,
      zaixie: true,
      // theShelves: true,
      // shelves: true,
      deletesBtn: true,
      dataArray: {},
      sidd: "",
      jiaGlist: [],
      statusDown: false,
      editlist: [],
      // number:1
      number: 0,
      number1: 0,
      isnlist: [],
      status: "",
      page: {
        orderColume: "devcon.lastLoginTime", //排序字段
        orderRule: "DESC", //排序规则ASC、DESC
      },
      Instpage: {
        orderColume: "e.lastupdatetime", //排序字段
        orderRule: "DESC", //排序规则ASC、DESC
      },
      imgUrl: vueConfig.jqUrl, //下载模板
    };
  },
  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    window.sessionStorage.setItem("searchname", "");
    console.log(this.$route.query.line, "this.$route.query.line");

    if (this.$route.query.jumpPageName == "Situation") {
      window.sessionStorage.setItem("placeholder", "osVersion");
      window.sessionStorage.setItem(
        "searchname",
        this.$route.query.deviceVersion
      );
      this.queryObj.searchKey = "系统版本"; //设备名称 下拉框 姓名 用户名 部门
      this.queryObj.input = this.$route.query.deviceVersion; //搜索的内容
      this.queryObj.osType = this.$route.query.deviceType;
      this.tableList(); //应用商店table数据表格
    } else if (this.$route.query.jumpPageName == "SourceHome") {
      if (this.$route.query.conStatus && this.$route.query.line) {
        console.log(this.$route.query.line, "this.$route.query.line");
        if (this.$route.query.conStatus == "0") {
          this.queryObj.conStateRate = "off-" + this.$route.query.line;
        } else if (this.$route.query.conStatus == "1") {
          this.queryObj.conStateRate = "on-" + this.$route.query.line;
        }
        this.queryObj.conStatus = this.$route.query.conStatus;
      }
      if (this.$route.query.conStatus && this.$route.query.line == undefined) {
        this.queryObj.conStatus = this.$route.query.conStatus;
      }
      // if (this.$route.query.isViolation) {
      //   this.queryObj.isViolation = this.$route.query.isViolation
      // }
      this.tableList(); //应用商店table数据表格
    } else {
      window.sessionStorage.setItem("placeholder", "deviceName");
      this.tableList(); //设备管理table数据表格
    }
  },
  methods: {
    selectSearchChange(row) {
      console.log(row);
    },
    getRowKeys(row) {
      return row.id;
    },
    changeMove() {},
    // 在线
    changeMove1(val) {
      let timeA = new Date().getTime();
      let timeB = new Date(val.deviceConIMStatus.lastLoginTime).getTime();
      console.log(timeB);
      let timeC = timeA - timeB;
      this.format1(timeC);
    },
    // 离线
    changeMove2(val) {
      let timeA = new Date().getTime();
      let timeB = new Date(val.deviceConIMStatus.lastLoginTime).getTime();
      console.log(timeB);
      let timeC = timeA - timeB;
      this.format2(timeC);
    },
    // 判断为0的时候隐藏   在线
    format1(time) {
      let day = Math.floor(time / (1000 * 60 * 60 * 24));
      let hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((time / (1000 * 60)) % 60);
      let seconds = Math.floor((time / 1000) % 60);
      this.timesMove =
        "在线时长：" +
        day +
        "天" +
        hours +
        "小时" +
        minutes +
        "分钟" +
        seconds +
        "秒";
      if (day <= 0) {
        this.timesMove =
          "在线时长：" + hours + "小时" + minutes + "分钟" + seconds + "秒";
        console.log(this.timesMove, "天");
      }
      if (hours <= 0) {
        this.timesMove = "在线时长：" + minutes + "分钟" + seconds + "秒";
        console.log(this.timesMove, "小时");
        return;
      }
    },
    // 判断离线
    format2(time) {
      let day = Math.floor(time / (1000 * 60 * 60 * 24));
      let hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((time / (1000 * 60)) % 60);
      let seconds = Math.floor((time / 1000) % 60);
      this.timesMove =
        "离线时长：" +
        day +
        "天" +
        hours +
        "小时" +
        minutes +
        "分钟" +
        seconds +
        "秒";
      if (day <= 0) {
        this.timesMove =
          "离线时长：" + hours + "小时" + minutes + "分钟" + seconds + "秒";
        console.log(this.timesMove, "天");
      }
      if (hours <= 0) {
        this.timesMove = "离线时长：" + minutes + "分钟" + seconds + "秒";
        console.log(this.timesMove, "小时");
        return;
      }
    },
    queryObjoninput(val) {
      window.sessionStorage.setItem("searchname", val);
    },
    su(title) {
      this.visibaelFlag = true;
      this.titleInfo = title;
    },
    sunline(data) {
      if (data == 1) {
        this.tableList();
        // this.visibaelFlag = true;
        // this.titleInfo = "保存成功";
        this.$message({
          message: "保存成功",
          type: "success",
          offset: 100,
        });
      }
      console.log(data);
      this.flag = data;
      // var flag =1
      // this.$emit("sunline",flag)
    },
    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "devicename" ||
          index == "udid" ||
          index == "loginName" ||
          index == "userName" ||
          index == "groupFullName" ||
          index == "deviceGroupName" ||
          index == "osId" ||
          index == "osVersion" ||
          index == "belong" ||
          index == "status" ||
          index == "askStatus" ||
          index == "bindStatus" ||
          index == "isViolation" ||
          index == "conStatus" ||
          index == "signStatus" ||
          index == "tunnelStatus" ||
          index == "healthDegree" ||
          index == 'createTime' ||
          index == "lastLoginTime"
        ) {
          this.tradeSelections[key].isChecked = !this.tradeSelections[key].isChecked;
        }
        // alert(1)
        this.number = 0;
      } else {
        this.number = 1;
      }
    },
    op(val) {
      if (val == "") {
        this.$nextTick(() => {
          this.options7 = this.options10;
        });
      }
      if (val == 0) {
        this.queryObj.conStateRate = "";
        this.options7 = this.options10.filter((i) => {
          return i.value.includes("off");
        });
      } else if (val == 1) {
        this.queryObj.conStateRate = "";
        this.options7 = this.options10.filter((i) => {
          return i.value.includes("on");
        });
      }
      // else{
      //   this.options7 =
      // }
    },
    editid(val) {
      // console.log(val)
      this.editlist = val;
      // console.log(this.editlist)
    },
    tableTitleTime(i, k, event) {
      // console.log(event, "监听表格配置项");
      // console.log(this.checkList, "kongshuzu");
      // console.log(i, k);
    },

    // 查询
    async searchList(val) {
      console.log(val);
      var param = {
        ownerId:sessionStorage.getItem('userId'),
        currentPage: 1,
        pageSize: this.queryObj.rowNumber,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        searchKey: val ? val.deviceName : "",
        searchContent: val ? val.InputText : "",
        deviceStatus: this.queryObj.deviceStatus,
        isViolation: this.queryObj.isViolation,
        signStatus: this.queryObj.signStatus,
        osType: this.queryObj.osType,
        belong: this.queryObj.belong,
        conStatus: this.queryObj.conStatus,
        conStateRate: this.queryObj.conStateRate,
        // pageNumber: this.queryObj.pageNumber,
        // rowNumber: this.queryObj.rowNumber,
        // where: {
        //   searchContent: this.queryObj.input,
        //   deviceStatus: this.queryObj.deviceStatus,
        //   isViolation: this.queryObj.isViolation,
        //   signStatus: this.queryObj.signStatus,
        //   osType: this.queryObj.osType,
        //   belong: this.queryObj.belong,
        //   conStatus: this.queryObj.conStatus,
        //   conStateRate: this.queryObj.conStateRate,
        //   deviceName: this.queryObj.deviceName,
        // },
        // sort: "devcon.lastLoginTime desc",
      };
      console.log(param);
      // http95
      // const res = await this.$axios.post("/api/device/paginate.do", param, true);
      const res = await this.$axios.post(
        "/httpServe/device/getDataInfo",
        param,
        true
      );
      console.log(res, "设备管理table搜索");
      // this.dictData = res.data;
      // this.total = res.totalCount;
      this.dictData = res.data.content;
      this.total = res.data.total;
    },
    //下拉选中查询类型--转译input中placeholder显示
    searchTypeChange(item) {
      console.log(item, "下拉选中查询类型");
      this.queryObj.searchKey = item;
      this.placeholder = item;
      this.options8.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
          console.log(this.seleShift);
          window.sessionStorage.setItem("placeholder", k.value);
        }
      });
    },
    // 列表
    async tableList() {
      //判断首页有没有传值过来
      if (this.$route.query.deviceStatus) {
        this.$set(this.queryObj, "deviceStatus", "15");
      }

      var param = {
        ownerId:sessionStorage.getItem('userId'),
        currentPage: this.queryObj.pageNumber,
        pageSize: this.queryObj.rowNumber,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        searchKey: window.sessionStorage.getItem("placeholder"), //设备名称 下拉框 姓名 用户名 部门
        searchContent: this.queryObj.input, //搜索的内容
        deviceStatus: this.queryObj.deviceStatus,
        isViolation: this.queryObj.isViolation,
        signStatus: this.queryObj.signStatus,
        osType: this.queryObj.osType,
        belong: this.queryObj.belong,
        conStatus: this.queryObj.conStatus,
        conStateRate: this.queryObj.conStateRate,
        // currentPage: this.queryObj.currentPage,
        // // pageSize: this.queryObj.pageSize,
        // rowNumber: this.queryObj.rowNumber,
        // // orderColume: this.queryObj.orderColume,
        // // orderRule: this.queryObj.orderRule,
        // where: {
        //   searchContent: "",
        //   deviceStatus: "",
        //   isViolation: this.queryObj.isViolation,
        //   signStatus: "",
        //   osType: "",
        //   belong: "",
        //   conStatus: this.queryObj.conStatus,
        //   conStateRate: "",
        //   deviceName: "",
        // },
        // sort: "devcon.lastLoginTime desc",
      };
      console.log(param);
      // http95
      // const res = await this.$axios.post("/api/device/paginate.do", param, true);
      const res = await this.$axios.post(
        "/httpServe/device/getDataInfo",
        param,
        true
      );
      console.log(res, "liebiaoshuju");
      // .then(() => {
      this.$nextTick(() => {
        this.dictData = res.data.content;
        console.log(this.dictData, "9ru3849r489fy4599ry79");
      });
      this.total = res.data.total;
      // });
      console.log(res, "设备管理table");
    },
    // 排序
    sort(column) {
      this.queryObj.orderColume = column.prop;
      console.log(column.order, column.prop);
      if (column.prop == "d.deviceName") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.deviceName";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.deviceName";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "udid") {
        if (column.order == "ascending") {
          this.page.orderColume = "udid";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "udid";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "u.loginName") {
        if (column.order == "ascending") {
          this.page.orderColume = "u.loginName";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "u.loginName";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "u.userName") {
        if (column.order == "ascending") {
          this.page.orderColume = "u.userName";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "u.userName";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "g.groupName") {
        if (column.order == "ascending") {
          this.page.orderColume = "g.groupName";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "g.groupName";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.osId") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.osId";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.osId";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.belong") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.belong";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.belong";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.status") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.status";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.status";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.bind_status") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.bind_status";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.bind_status";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.isViolation") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.isViolation";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.isViolation";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.signStatus") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.signStatus";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.signStatus";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "devcon.conStatus") {
        if (column.order == "ascending") {
          this.page.orderColume = "devcon.conStatus";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "devcon.conStatus";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.healthDegree") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.healthDegree";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.healthDegree";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "devcon.lastLoginTime") {
        if (column.order == "ascending") {
          this.page.orderColume = "devcon.lastLoginTime";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "devcon.lastLoginTime";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.tunnel_status") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.tunnel_status";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.tunnel_status";
          this.page.orderRule = "DESC";
        }
      } else if (column.prop == "d.os_version") {
        if (column.order == "ascending") {
          this.page.orderColume = "d.os_version";
          this.page.orderRule = "ASC";
        } else {
          this.page.orderColume = "d.os_version";
          this.page.orderRule = "DESC";
        }
      }
      this.tableList();
    },
    // 删除 handleSelectionChange
    // 全选删除
    delobj(selection, row) {
      selection.forEach((item) => {
        console.log(item);
        this.delarr.push(item.id);
        console.log(this.delarr);
      });
    },
    // delobj(obj) {
    //   console.log(obj);
    //   this.delarr = obj;
    // },
    delobj(obj) {
      console.log(obj);
      this.delarr = obj;
    },
    // 多选删除
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      console.log(ids);
      // 请求接口
      var parma = { ids: ids };
      console.log(parma);
      this.$confirm(
        "此操作将删除设备上的安全桌面或使设备退出登录，确定删除选中的设备？",
        "提示信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/device/delete",
            parma,
            true
          );
          // const res = await this.$axios.post("/api/device/delete.do", parma, true);
          // this.visibaelFlag = true;
          // this.titleInfo = "删除成功";
          this.$message({
            message: "删除成功",
            type: "success",
            offset: 100,
          });
          this.$refs.multipleTable.clearSelection();
          this.tableList();
        })
        .catch(() => {
          return false;
        });
    },
    // 单个删除
    del(id) {
      this.$confirm(
        "此操作将删除设备上的安全桌面或使设备退出登录，确定删除选中设备？",
        "提示信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          // 请求接口
          var parma = { ids: [String(id)] };
          const res = await this.$axios.post(
            "/httpServe/device/delete",
            parma,
            true
          );
          // const res = await this.$axios.post("/api/device/delete.do", parma, true);
          console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "删除成功";
          this.$message({
            message: "删除成功",
            type: "success",
            offset: 100,
          });
          this.tableList();
        })
        .catch(() => {
          return false;
        });
    },
    eedits(row) {
      window.sessionStorage.setItem("devicedeit", JSON.stringify(row));
      this.flag = 6;
    },
    // 详情
    Equipment(row) {
      // console.log(row)
      window.sessionStorage.setItem("user", JSON.stringify(row));
      this.flag = 5;
    },
    // 批量解绑
    async batchUnbind() {
      var arr = []
      this.multipleSelection.forEach((i) => {
        arr.push(String(i.id))
      })
      console.log(arr,'设备解绑id')
      this.$confirm('确定解绑选中的设备？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          var param = {
            ids: arr,
            // devStatus: '35' //设备状态
            // checkAllType: "0",
          }
          console.log(param)
          const res = await this.$axios.post(
            '/httpServe/device/unbind',
            param,
            true
          )
          this.$message({
            message: '设备解绑成功',
            type: 'success',
            offset: 100
          })
          console.log(res, '解绑')
          this.searchList()
          this.$refs.multipleTable.clearSelection()
        })
        .catch(() => {
          return false
        })
    },
    // 停用
    async stop() {
      var arr = [];
      this.multipleSelection.forEach((i) => {
        arr.push(String(i.id));
      });
      this.$confirm("确定停用选中的设备？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            ids: arr,
            devStatus: "35", //设备状态
            // checkAllType: "0",
          };
          console.log(param);
          const res = await this.$axios.post(
            "/httpServe/device/updateStatus",
            param,
            true
          );
          this.$message({
            message: "设备停用成功",
            type: "success",
            offset: 100,
          });
          console.log(res, "停用");
          this.searchList();
          this.$refs.multipleTable.clearSelection();
        })
        .catch(() => {
          return false;
        });
    },
    // 启用
    async Enable() {
      var arr = [];
      this.multipleSelection.forEach((i) => {
        arr.push(String(i.id));
      });
      this.$confirm("确定启用选中的设备？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            ids: arr,
            devStatus: "45", //设备状态
            // checkAllType: "0",
          };
          console.log(param);
          const res = await this.$axios.post(
            "/httpServe/device/updateStatus",
            param,
            true
          );
          this.$message({
            message: "设备启用成功",
            type: "success",
            offset: 100,
          });
          console.log(res, "启用");
          this.searchList();
          this.$refs.multipleTable.clearSelection();
        })
        .catch(() => {
          return false;
        });
    },
    // 导入
    handleCommand(command) {
      if (command == "a") {
        // this.downModel();
      } else {
        this.flag = 3; //导入SDK权限策略库
      }
    },
    // 下载模板
    // async downModel() {
    //   console.log("下载模板");
    //   var params = {
    //     // type: "protected",
    //     path: "public/模板文档/设备模板.xls",
    //   };
    //   console.log(params);
    //   const res = await this.$axios.post("/httpServe/sysFile/fileDown", params, true);
    //   console.log(res);
    //   let url = Base64.decode(res.data);
    //   console.log(url, "------url解码");
    //   url = this.imgUrl + url;
    //   console.log(url, "-----url");
    //   location.href = url;
    //   // await this.$axios.downloadFile(res.data);
    // },
    // 导出
    derive() {
      console.log(this.queryObj, "this.queryObj");
      this.searchKeyArr = { ...this.queryObj };
      console.log(this.searchKeyArr, "this.searchKeyArr");
      this.flag = 4;
    },
    // 安装应用
    async Install(val) {
      console.log(this.multipleSelection);
      window.sessionStorage.setItem(
        "userlist",
        JSON.stringify(this.multipleSelection)
      );
      console.log(val);
      var deviceUDID = [];
      var osType = "";
      var osTypeId = 20;
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
        osType = i.osId == 20 ? "Android" : "iOS";
        osTypeId = i.osId;
      });
      var deid = deviceUDID.join(",");
      this.dialogVisible = true;
      this.dataFrom = { ...val };
      let params = {
        deviceUDID: deid,
        osTypeId: osTypeId,
        appName: this.input, //input搜索
        currentPage: 1,
        pageSize: 100000,
        orderColume: this.Instpage.orderColume,
        orderRule: this.Instpage.orderRule,
        appStatus: "2",
        isGetH5App: 0,
      };
      console.log(params);
      const res = await this.$axios.post(
        "/httpServe/entApp/getDataInfo",
        params,
        true
      );
      // const res = await this.$axios.post("/api/entApp/paginate.do", params, true);
      console.log(res);
      this.gridData = res.data.content;
      console.log(this.gridData);
      this.isnlist = res.data.content;
      this.dataTolCount = res.data.total;
      this.$forceUpdate();
      window.sessionStorage.setItem("list", JSON.stringify(res));
    },
    // 安装
    async joinType() {
      var deviceUDID = [];
      var osType = "";
      var osTypeId = "";
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
        osType = i.osId == 20 ? "Android" : "iOS";
        osTypeId = i.osId;
      });
      var entAppId = [];
      this.editlist.forEach((i) => {
        entAppId.push(i.id);
      });
      // var deid = deviceUDID.join(",");
      var deid = deviceUDID.join(",");

      if (this.editlist.length == 0) {
        // this.$confirm("请至少选中一项", "提示信息", {
        //   confirmButtonText: "确定",
        //   customClass: "persdsd",
        // });
        this.$message({
          message: "请至少选中一项",
          type: "error",
          offset: 100,
        });
        // this.visibaelFlag = true;
        // this.titleInfo = "请至少选中一项";
      } else if (this.editid.length == 1) {
        this.$confirm("是否下发安装应用命令？", "提示信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        })
          .then(async () => {
            var param = {
              type: "InstallApplication", //标记状态
              deviceUDID: deid,
              entAppId: entAppId,
              // entAppId: [String(entAppId)],
            };
            console.log(param);
            const res = await this.$axios.post(
              "/httpServe/device/command/exec",
              param,
              true
            );
            // const res = await this.$axios.post("api/device/command/exec.do", param, true);
            console.log(res);
            this.$emit("sun", "下发安装应用命令成功");
            // this.visibaelFlag = true;
            // this.titleInfo = "下发安装应用命令成功";
            this.$message({
              message: "下发安装应用命令成功",
              type: "success",
              offset: 100,
            });
          })
          .catch(() => {
            return false;
          });
      }
    },

    // 查询
    async searchDev() {
      if (this.input != "") {
        this.gridData = this.gridData.filter((item) => {
          return item.appName.includes(this.input);
        });
      } else {
        this.gridData = this.isnlist;
        // this.Install(、);
      }
    },
    searchDevs() {
      if (this.input != "") {
        this.gridDas = this.gridDas.filter((item) => {
          return item.appName.includes(this.input);
        });
      } else {
        this.gridDas = this.isnlist;
        this.Uninstall();
      }
    },
    searchDevss() {
      console.log(111, "---");
      if (this.input != "") {
        this.gridDatas = this.gridDatas.filter((item) => {
          return item.name.includes(this.input);
        });
      } else {
        this.gridDatas = this.isnlist;
        this.Uninstall();
      }
    },
    // 卸载应用
    async Uninstall(val) {
      //  console.log(this.multipleSelection);
      //   window.sessionStorage.setItem("userlist", JSON.stringify(this.multipleSelection));
      console.log(val);
      var deviceUDID = [];
      var osType = "";
      var osTypeId = "";
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
        osType = i.osId == 20 ? "Android" : "iOS";
        osTypeId = i.osId;
      });
      var deid = deviceUDID.join(",");

      this.dataFrom = { ...val };
      if (osTypeId == 10) {
        this.installs = true;
        // ios
        let params = {
          currentPage: 1,
          orderColume: "createTime",
          orderRule: "DESC",
          deviceUDIDs: [deid],
          osType: osTypeId,
          appName: "",
        };
        console.log(params);
        const res = await this.$axios.post(
          "/httpServe/entApp/selectApp",
          params,
          true
        );
        // const res = await this.$axios.post("/api/entApp/selectApp", params, true);
        console.log(res);
        this.gridDatas = res.data.content;
        // this.gridData = res.data.data;
        console.log(this.gridDatas);
        this.isnlist = res.data.content;
        this.dataTolCount = res.total;
        // this.dataTolCount = res.totalCount;
        this.$forceUpdate();
        window.sessionStorage.setItem("list", JSON.stringify(res));
      } else if (osTypeId == 20) {
        this.install = true;
        // 安卓
        let params = {
          currentPage: 1,
          orderColume: "lastupdatetime",
          orderRule: "ASC",
          deviceUDIDs: [deid],
          osType: osTypeId,
          appName: "",
        };
        console.log(params);
        const res = await this.$axios.post(
          "/httpServe/entApp/selectApp",
          params,
          true
        );
        // const res = await this.$axios.post("/api/entApp/selectApp", params, true);
        console.log(res);
        this.gridDas = res.data.content;
        // this.gridData = res.data.data;
        console.log(this.gridDas);
        this.isnlist = res.data.content;
        this.dataTolCount = res.total;
        // this.dataTolCount = res.totalCount;
        this.$forceUpdate();
        window.sessionStorage.setItem("list", JSON.stringify(res));
      }
    },
    // 卸载
    async xiezai() {
      var deviceUDID = [];
      var osType = "";
      var osTypeId = "";
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
        osType = i.osId == 20 ? "Android" : "iOS";
        osTypeId = i.osId;
      });
      var removeId = [];
      this.editlist.forEach((i) => {
        removeId.push(String(i.id));
      });
      console.log(removeId, "卸载");
      // var deid = deviceUDID.join(",");
      var deid = deviceUDID.join(",");

      if (this.editlist.length == 0) {
        // this.$confirm("请至少选中一项", "提示信息", {
        //   confirmButtonText: "确定",
        //   customClass: "persdsd",
        // });
        this.$message({
          message: "请至少选中一项",
          type: "error",
          offset: 100,
        });
        // this.visibaelFlag = true;
        // this.titleInfo = "请至少选中一项";
      } else if (this.editlist.length >= 1) {
        this.$confirm("是否下发卸载应用命令？", "提示信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        })
          .then(async () => {
            var param = {
              type: "RemoveApplication", //标记状态
              deviceUDID: deid,
              removeId: removeId,
              // isSystem: "1",
            };
            console.log(param);
            const res = await this.$axios.post(
              "/httpServe/device/command/exec",
              param,
              true
            );
            console.log(res);
            // this.titleInfo = "下发卸载应用命令成功";
            // this.visibaelFlag = true;
            this.$message({
              message: "下发卸载应用命令成功",
              type: "success",
              offset: 100,
            });
            this.$emit("sun", "下发卸载应用命令成功");
          })
          .catch(() => {
            return false;
          });
      }
    },

    // 标记丢失
    async lose() {
      var arr = [];
      var idsarr = [];
      var index = 0;
      this.multipleSelection.forEach((i) => {
        arr.push(String(i.id));
        idsarr.push(i.signStatus);
        if (i.signStatus == 3) {
          // console.log(1)
          index = 1;
        }
      });
      if (index == 1) {
        this.$message({
          message: "没有符合此项操作的设备",
          type: "error",
          offset: 100,
        });
        return false;
      }
      this.$confirm("确定标记丢失？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            signStatus: "3", //标记状态
            // id: JSON.stringify(arr),
            ids: arr,
          };
          console.log(param, "丢失");
          const res = await this.$axios.post(
            "/httpServe/device/updateSignStatus",
            param,
            true
          );
          console.log(res, "丢失");
          // const res = await this.$axios.post("/api/device/updateSignStatus", param, true);
          console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "标记丢失成功";
          this.$message({
            message: "标记丢失成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
      //   this.$confirm("没有符合此项操作的设备", "提示信息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   customClass: "persdsd",
      //   type: "warning",
      // });
    },
    // 设置标记丢失
    async loses(row) {
      var arr = [];
      var idsarr = [];
      var index = 0;
      this.multipleSelection.forEach((i) => {
        arr.push(String(i.id));
        idsarr.push(i.signStatus);
        if (i.signStatus == 3) {
          // console.log(1)
          index = 1;
        }
      });
      if (index == 1) {
        this.$message({
          message: "没有符合此项操作的设备",
          type: "error",
          offset: 100,
        });
        return false;
      }
      this.$confirm("确定标记丢失？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            signStatus: "3", //标记状态
            // id: JSON.stringify(arr),
            ids: [String(row.id)],
          };
          console.log(param, "设置丢失");
          const res = await this.$axios.post(
            "/httpServe/device/updateSignStatus",
            param,
            true
          );
          console.log(res, "设置丢失");
          // const res = await this.$axios.post("/api/device/updateSignStatus", param, true);
          console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "标记丢失成功";
          this.$message({
            message: "标记丢失成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
      //   this.$confirm("没有符合此项操作的设备", "提示信息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   customClass: "persdsd",
      //   type: "warning",
      // });
    },
    // 标记合规
    async Compliance(id) {
      var arr = [];
      this.multipleSelection.forEach((i) => {
        arr.push(i.id);
      });
      this.$confirm("确定设置该设备为合规？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            deviceId: id,
          };
          // console.log(param);
          const res = await this.$axios.post(
            "/httpServe/device/updateViolation",
            param,
            true
          );
          // const res = await this.$axios.post("/api/device/updateViolation", param, true);
          // console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "设置合规状态成功";
          this.$message({
            message: "设置合规状态成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
      //   this.$confirm("没有符合此项操作的设备", "提示信息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   customClass: "persdsd",
      //   type: "warning",
      // });
    },
    // 标记找回
    async retrieve(row) {
      var arr = [];
      var idsarr = [];
      var index = 0;
      this.multipleSelection.forEach((i) => {
        arr.push(String(i.id));
        idsarr.push(i.signStatus);
        if (i.signStatus == 4 || i.signStatus == 1 || i.signStatus == 2) {
          // console.log(1)
          index = 1;
        }
      });
      if (index == 1) {
        this.$message({
          message: "没有符合此项操作的设备",
          type: "error",
          offset: 100,
        });
        return false;
      }
      this.$confirm("确定标记找回？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            signStatus: "2", //标记状态
            ids: arr,
          };
          console.log(param, "找回");
          const res = await this.$axios.post(
            "/httpServe/device/updateSignStatus",
            param,
            true
          );
          console.log(res, "找回");
          // const res = await this.$axios.post("api/device/updateSignStatus", param, true);
          // this.visibaelFlag = true;
          // this.titleInfo = "标记找回成功";
          this.$message({
            message: "标记找回成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
      // this.$confirm("没有符合此项操作的设备", "提示信息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   customClass: "persdsd",
      //   type: "warning",
      // });
    },
    // 设置标记找回
    async Retrieves(row) {
      var arr = [];
      var index = 0;
      this.multipleSelection.forEach((i) => {
        arr.push(i.signStatus);
        if (i.signStatus == 4 || i.signStatus == 1 || i.signStatus == 2) {
          // console.log(1)
          index = 1;
        }
      });
      if (index == 1) {
        this.$message({
          message: "没有符合此项操作的设备",
          type: "error",
          offset: 100,
        });
        return false;
      }
      this.$confirm("确定标记找回？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            signStatus: "2", //标记状态
            ids: [String(row.id)],
          };
          console.log(param, "设置找回");
          const res = await this.$axios.post(
            "/httpServe/device/updateSignStatus",
            param,
            true
          );
          console.log(res, "设置找回");
          // const res = await this.$axios.post("api/device/updateSignStatus", param, true);
          // this.visibaelFlag = true;
          // this.titleInfo = "标记找回成功";
          this.$message({
            message: "标记找回成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
      // this.$confirm("没有符合此项操作的设备", "提示信息", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   customClass: "persdsd",
      //   type: "warning",
      // });
    },
    // 标记报废
    async Mark() {
      var arr = [];
      var idsarr = [];
      var index = 0;
      this.multipleSelection.forEach((i) => {
        arr.push(String(i.id));
        idsarr.push(i.signStatus);
        if (i.signStatus == 4) {
          // console.log(1)
          index = 1;
        }
      });
      if (index == 1) {
        this.$message({
          message: "没有符合此项操作的设备",
          type: "error",
          offset: 100,
        });
        return false;
      }
      this.$confirm("确定标记报废？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            signStatus: "4", //标记状态
            ids: arr,
          };
          console.log(param, "报废");
          const res = await this.$axios.post(
            "/httpServe/device/updateSignStatus",
            param,
            true
          );
          console.log(res, "报废");
          // const res = await this.$axios.post("api/device/updateSignStatus", param, true);
          // this.visibaelFlag = true;
          // this.titleInfo = "标记报废成功";
          this.$message({
            message: "标记报废成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },
    // 设置标记报废
    async Marks(id) {
      var arr = [];
      this.multipleSelection.forEach((i) => {
        arr.push(i.id);
      });
      this.$confirm("确定标记报废？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            signStatus: "4", //标记状态
            ids: [String(id)],
          };
          console.log(param, "设置报废");
          const res = await this.$axios.post(
            "/httpServe/device/updateSignStatus",
            param,
            true
          );
          console.log(res, "设置报废");
          // const res = await this.$axios.post("api/device/updateSignStatus", param, true);
          // this.visibaelFlag = true;
          // this.titleInfo = "标记报废成功";
          this.$message({
            message: "标记报废成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },
    // 锁定设备
    async Lockingdevice() {
      var deviceUDID = [];
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
      });
      var deid = deviceUDID.join(",");
      this.$confirm("此操作将使设备锁屏，确定锁定设备？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            type: "DeviceLock", //标记状态
            deviceUDID: deid,
          };
          console.log(param);
          const res = await this.$axios.post(
            "/httpServe/device/command/exec",
            param,
            true
          );
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "锁定设备命令下发成功";
          this.$message({
            message: "锁定设备命令下发成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },
    // 设置锁定设备
    async Lockingdevices(row) {
      console.log(row, "suo");
      var deviceUDID = [];
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
      });
      var deid = deviceUDID.join(",");
      this.$confirm("此操作将使设备锁屏，确定锁定设备？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            type: "DeviceLock", //标记状态
            deviceUDID: row.deviceUDID,
          };
          console.log(param);
          const res = await this.$axios.post(
            "/httpServe/device/command/exec",
            param,
            true
          );
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "锁定设备命令下发成功";
          this.$message({
            message: "锁定设备命令下发成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },

    // 设置设备解绑 didi
    async appUnbind(row) {
      this.$confirm(this.$t('DeviceManage.Device.Multipledelete.Batchuntying'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            ids: [row],
          };
          const res = await this.$axios.post(
            "/httpServe/device/unbind",
            param,
            true
          );
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.Unbindsuccessfully'),
            type: 'success',
            offset: 100
          })
          this.searchList()
          this.$refs.multipleTable.clearSelection()
        })
        .catch(() => {
          return false;
        });
    },
    // 设置清除数据
    async Cleardatas(row) {
      console.log(row, "suo");
      var deviceUDID = [];
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
      });
      var deid = deviceUDID.join(",");
      this.$confirm(
        "此操作将清除设备上受保护应用的业务数据，确定清除数据？",
        "提示信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          var param = {
            type: "removeappdata", //标记状态
            deviceUDID: row.deviceUDID,
          };
          console.log(param);
          const res = await this.$axios.post(
            "/httpServe/device/command/exec",
            param,
            true
          );
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "清除数据命令下发成功";
          this.$message({
            message: "清除数据命令下发成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },
    // 清除数据
    async Cleardata() {
      var deviceUDID = [];
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
      });
      var deid = deviceUDID.join(",");
      this.$confirm(
        "此操作将清除设备上受保护应用的业务数据，确定清除数据？",
        "提示信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          var param = {
            type: "removeappdata", //标记状态
            deviceUDID: deid,
          };
          const res = await this.$axios.post(
            "/httpServe/device/command/exec",
            param,
            true
          );
          // const res = await this.$axios.post("api/device/command/exec.do", param, true);
          // this.visibaelFlag = true;
          // this.titleInfo = "清除数据命令下发成功";
          this.$message({
            message: "清除数据命令下发成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },
    // 强制注销
    async Forcelogoff() {
      var deviceUDID = []
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID)
      })
      var deid = deviceUDID.join(',')
      this.$confirm(this.$t('DeviceManage.Device.Multipledelete.LogoffOk'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          var param = {
            type: 'logOut', //标记状态
            deviceUDID: deid
          }
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.Loggedsuccessfully'),
            type: 'success',
            offset: 100
          })
          this.$refs.multipleTable.clearSelection()
          this.searchList()
        })
        .catch(() => {
          return false
        })
    },
    // 设置强制注销
    async celogoff(row) {
      var deviceUDID = []
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID)
      })
      var deid = deviceUDID.join(',')
      this.$confirm(this.$t('DeviceManage.Device.Multipledelete.LogoffOk'),
        this.$t('public.PromptMessage'), {
          confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          var param = {
            type: 'logOut', //标记状态
            deviceUDID: row.deviceUDID
          }
          const res = await this.$axios.post(
            '/httpServe/device/command/exec',
            param,
            true
          )
          this.$message({
            message: this.$t('DeviceManage.Device.Multipledelete.Loggedsuccessfully'),
            type: 'success',
            offset: 100
          })
          this.$refs.multipleTable.clearSelection()
          this.searchList()
        })
        .catch(() => {
          return false
        })
    },
    // 安全隧道
    tunnel() {
      this.tunne = true;
    },
    // 安全隧道 多选确定
    async security() {
      var arr = [];
      this.multipleSelection.forEach((i) => {
        console.log(i, "安全隧道内容");
        arr.push(i.id);
      });

      if (this.queryObj.tunnelStatus == "1") {
        console.log("安全隧道开启");
        this.$confirm("确定启用安全隧道？", "提示信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        })
          .then(async () => {
            var param = {
              tunnelStatus: this.queryObj.tunnelStatus, //安全隧道状态 0禁用 1开启
              // id: JSON.stringify(arr),
              ids: arr,
            };
            console.log(param, "安全隧道");
            const res = await this.$axios.post(
              "/httpServe/device/updateTunnelStatus",
              param,
              true
            );
            console.log(res, "安全隧道数据");
            this.$message({
              message: "启用安全隧道成功",
              type: "success",
              offset: 100,
            });

            this.tunne = false;
            this.$refs.multipleTable.clearSelection();
            this.queryObj.tunnelStatus = "0";
            this.tableList();
          })
          .catch(() => {
            return false;
          });
      } else {
        console.log("安全隧道禁用");
        this.$confirm("确定禁用安全隧道？", "提示信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        })
          .then(async () => {
            var param = {
              tunnelStatus: this.queryObj.tunnelStatus, //安全隧道状态 0禁用 1开启
              // id: JSON.stringify(arr),
              ids: arr,
            };
            console.log(param, "安全隧道");
            const res = await this.$axios.post(
              "/httpServe/device/updateTunnelStatus",
              param,
              true
            );
            console.log(res, "安全隧道数据");
            this.$message({
              message: "禁用安全隧道成功",
              type: "success",
              offset: 100,
            });
            this.tunne = false;
            this.$refs.multipleTable.clearSelection();
            this.queryObj.tunnelStatus = "0";
            this.tableList();
          })
          .catch(() => {
            return false;
          });
      }
    },
    // 设置安全隧道
    Safetytunnel(row) {
      console.log(row, "设置安全隧道");
      this.Safetytun = true;
      this.nelStat = row.id;
      console.log(this.nelStat, "设置安全隧道------------------");
      // this.multipleSelection.forEach((i) => {
      //   arr.push(String(i.id));
      // });
    },
    // 设置安全隧道 单选确定
    async tunnelsecu() {
      if (this.queryObj.tunnelStatus == "1") {
        console.log("安全隧道开启");
        this.$confirm("确定启用安全隧道？", "提示信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        })
          .then(async () => {
            var param = {
              tunnelStatus: this.queryObj.tunnelStatus, //安全隧道状态 0禁用 1开启
              ids: [this.nelStat],
            };
            console.log(param, "安全隧道");
            const res = await this.$axios.post(
              "/httpServe/device/updateTunnelStatus",
              param,
              true
            );
            console.log(res, "安全隧道数据");
            this.$message({
              message: "启用安全隧道成功",
              type: "success",
              offset: 100,
            });
            this.Safetytun = false;
            this.$refs.multipleTable.clearSelection();
            this.queryObj.tunnelStatus = "0";
            this.tableList();
          })
          .catch(() => {
            return false;
          });
      } else {
        console.log("安全隧道禁用");
        this.$confirm("确定禁用安全隧道？", "提示信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        })
          .then(async () => {
            var param = {
              tunnelStatus: this.queryObj.tunnelStatus, //安全隧道状态 0禁用 1开启
              // id: JSON.stringify(arr),
              ids: [this.nelStat],
            };
            console.log(param, "安全隧道");
            const res = await this.$axios.post(
              "/httpServe/device/updateTunnelStatus",
              param,
              true
            );
            console.log(res, "安全隧道数据");
            this.$message({
              message: "禁用安全隧道成功",
              type: "success",
              offset: 100,
            });
            this.Safetytun = false;
            this.$refs.multipleTable.clearSelection();
            this.queryObj.tunnelStatus = "0";
            this.tableList();
          })
          .catch(() => {
            return false;
          });
      }
    },
    // 恢复出厂
    async recovery() {
      var deviceUDID = [];
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
      });
      var deid = deviceUDID.join(",");
      this.$confirm(
        "此操作将把设备恢复至出厂设置状态，确定恢复出厂？",
        "提示信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          var param = {
            type: "EraseDevice", //标记状态
            deviceUDID: deid,
          };
          const res = await this.$axios.post(
            "/httpServe/device/command/exec",
            param,
            true
          );
          // const res = await this.$axios.post("/api/device/command/exec.do", param, true);
          // this.visibaelFlag = true;
          // this.titleInfo = "恢复出厂命令下发成功";
          this.$message({
            message: "恢复出厂命令下发成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },
    // 设置恢复出厂
    async overy(row) {
      console.log(row, "suo");
      var deviceUDID = [];
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
      });
      var deid = deviceUDID.join(",");
      this.$confirm(
        "此操作将把设备恢复至出厂设置状态，确定恢复出厂？",
        "提示信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          var param = {
            type: "EraseDevice", //标记状态
            deviceUDID: row.deviceUDID,
          };
          console.log(param);
          const res = await this.$axios.post(
            "/httpServe/device/command/exec",
            param,
            true
          );
          console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "恢复出厂命令下发成功";
          this.$message({
            message: "恢复出厂命令下发成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },
    // 提取日志
    async extract() {
      var deviceUDID = [];
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
      });
      var deid = deviceUDID.join(",");
      this.$confirm("确定提取日志？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            type: "extractLog", //标记状态
            deviceUDID: deid,
          };
          const res = await this.$axios.post(
            "/httpServe/device/command/exec",
            param,
            true
          );
          // const res = await this.$axios.post("/api/device/command/exec.do", param, true);
          // this.visibaelFlag = true;
          // this.titleInfo = "提取日志命令下发成功";
          this.$message({
            message: "提取日志命令下发成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },
    // 设置提取日志
    async extractss(row) {
      console.log(row, "suo");
      var deviceUDID = [];
      this.multipleSelection.forEach((i) => {
        deviceUDID.push(i.deviceUDID);
      });
      var deid = deviceUDID.join(",");
      this.$confirm("确定提取日志？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          var param = {
            type: "extractLog", //标记状态
            deviceUDID: row.deviceUDID,
          };
          console.log(param);
          const res = await this.$axios.post(
            "/httpServe/device/command/exec",
            param,
            true
          );
          console.log(res);
          // this.visibaelFlag = true;
          // this.titleInfo = "提取日志命令下发成功";
          this.$message({
            message: "提取日志命令下发成功",
            type: "success",
            offset: 100,
          });
          this.searchList();
        })
        .catch(() => {
          return false;
        });
    },

    selectEnable(row, rowIndex) {
      if (row.purgeStatus != 2 && row.scanScore != -1) {
        return true;
      } else {
        return false;
      }
    },

    handleSelectionChange(val) {
      this.delarr = val;
      this.unbind = val
      // true为禁用  false为启用 
      this.multipleSelection = [...val];
      this.binSeleted = true 
      if(this.delarr.length ==1){
       if(this.delarr[0].bindStatus == 0){
         this.typeInfo6 = 'info'
         this.binSeleted = true
        //  break
       }else if(this.delarr[0].bindStatus == 1){
         this.typeInfo6 = 'primary'
         this.binSeleted = false
       } 
      }
      if(this.delarr.length >1){ 
        var ids=[] 
       this.unbind.forEach((item) => { 
          if(item){
            ids.push(item.bindStatus) 
            for (let i = 0; i < ids.length; i++) {
              if (ids[i] == 1) { 
                this.typeInfo6 = 'primary'
                this.binSeleted = false
                break
              }else{ 
                this.typeInfo6 = 'info'
                this.binSeleted = true
              }
            }

          } 
        })
      }
      //设备绑定  为0 时 设备解绑按钮置灰
    //   this.binSeleted = true 
    //   var ids=[]
    //    this.unbind = val
    //    this.unbind.forEach((item) => { 
    //    if(item){
    //     ids.push(item.bindStatus) 
    //     for (let i = 0; i < ids.length; i++) {
    //       if (ids[i] == 0) {
    //         console.log(i);
    //         this.typeInfo6 = 'info'
    //         this.binSeleted = true
    //         break
    //       }else{
    //         this.typeInfo6 = 'primary'
    //         this.binSeleted = false
    //       }
    //     }

    //    } 
    //  })

      if (val.length == 0) {
        this.hasSeleted = true; 
        this.yongting = true;
        this.yongqi = true;
        this.zhuang = true;
        this.zaixie = true;
        this.btnJiagu = true; 
        this.binSeleted = true
        this.typeInfo = "info";
        this.typeInfo1 = "info";
        this.typeInfo2 = "info";
        this.typeInfo3 = "info";
        this.typeInfo4 = "info";
        this.typeInfo5 = "info"; 
        this.typeInfo6 = 'info'
      } else {
        this.hasSeleted = false;
        this.zhuang = false;
        this.zaixie = false;
        this.typeInfo2 = "primary";
        this.typeInfo4 = "primary";
        let dataArray = [...val];
        this.dataArray = dataArray.pop();
        if (this.dataArray.status == 5) {
          this.yongqi = true;
          this.yongting = true;
          this.typeInfo = "info";
          this.typeInfo1 = "info";
        } else if (this.dataArray.status == 25) {
          this.yongqi = true;
          this.yongting = false;
          this.typeInfo = "primary";
          this.typeInfo1 = "info";
        } else if (this.dataArray.status == 15) {
          this.yongqi = true;
          this.yongting = false;
          this.typeInfo = "primary";
          this.typeInfo1 = "info";
        } else if (this.dataArray.status == 35) {
          this.yongqi = false;
          this.yongting = true;
          this.typeInfo = "info";
          this.typeInfo1 = "primary";
        }
        // if (this.multipleSelection.length > 1) {
        //    var osid = this.multipleSelection[0].status
        //   this.multipleSelection.forEach((item, index) => {
        //       if(item.status !=osid){
        //       this.yongqi = true
        //       this.yongting = true
        //       this.typeInfo = 'info'
        //       this.typeInfo1 = 'info'
        //       }
        //   })
        // }
        if (this.multipleSelection.length > 1) {
          var osid = this.multipleSelection[0].osId;
          this.multipleSelection.forEach((item, index) => {
            if (item.osId != osid) {
              this.zhuang = true;
              this.zaixie = true;
              this.typeInfo2 = "info";
            }
          });
        }
      }
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.queryObj.pageNumber = page;
      this.queryObj.rowNumber = limit;
      // this.queryObj.currentPage = page;
      // this.queryObj.pageSize = limit;
      // 调用查询方法
      this.searchList();
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //所有页全选
    // toggleAllSelection() {
    //   this.disabled = false;
    //   this.$refs.multipleTable.toggleAllSelection();
    // },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabled = false;
      }
      this.isCheckShow = true;
    },
    // 关闭
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
      this.dialogVisible = false; //详情弹框  关闭
      this.jiaGuVisible = false; //加固详情
      this.downFlag = false;
      this.installFlag = false;
      this.install = false; //详情弹框  关闭
      this.installs = false;
      this.tunne = false;
      this.Safetytun = false;
      this.input = "";
      this.queryObj.tunnelStatus = "0";
      this.$refs.multipleTable.clearSelection();
    },
    heightCustom() {
      this.height.height = this.num;
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false;
    },
    distribution(row) {
      for (let index = 0; index < this.dictData.length; index++) {
        this.$set(this.dictData[index], "shezhi", false);

        if (row.id == this.dictData[index].id) {
          if (this.number2 == row.id) {
            this.number2 = "";
            return;
          } else {
            this.number2 = row.id;
            this.$set(this.dictData[index], "shezhi", true);
            this.$forceUpdate();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.sitehovers {
  text-align: center !important;
  line-height: 20px !important;
  font-size: 14px !important;
}
.sitehovers:hover {
  color: orange !important;
  text-decoration: underline !important;
}
::v-deep .box-corn {
  overflow-x: hidden !important;
}
.shezhi1 {
  // position: relative;
}
.pshezhi {
  width: 100px;
  position: absolute;
  top: 10px;
  left: -88px;
  background: #ffffff;
  z-index: 9999;
  border: 1px solid #1e89e5;
  border-radius: 5px;
  text-align: center;
}
:v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: none;
}
.el-linkStyle {
  display: flex;
  cursor: pointer;
  color: #1e89e5;
  img {
    width: 20px;
    line-height: 20px;
    // margin-top: 20px;
  }
}
.mt15 {
  padding: 10px 10px;
}
.formBox {
  //全选弹框
  position: relative;
  .tableCheckBox {
    border: 1px solid #1e89e5;
    border-radius: 3px;
    width: 80px;
    background-color: #fff;
    position: absolute;
    top: 32px;
    left: 20px;
    z-index: 999;
    // background:rgba(255, 255, 255,.1)
    li {
      margin-top: -1px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #575656;
      cursor: pointer;
      font-weight: 400;
      font-family: Microsoft YaHei;
      font-size: 12px;
      list-style: none !important;
      div {
        display: inline-block;
      }
    }
    li:hover {
      color: #d38a08;
      text-decoration: underline;
    }
  }
}
.processManage .callenia {
  height: 0;
}
.clo24 {
  margin: 10px 0;
}
.col12 {
  text-align: center;
  margin: 60px 0;
  .el-button {
    width: 180px;
  }
}
::v-deep .el-checkbox__label {
  color: #000;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
::v-deep .el-table__empty-text {
  line-height: 0px;
  width: 60px;
  color: #909399;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0px;
}
.linkclass {
  color: #575656;
  margin-left: 30px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
}
.adio {
  margin-top: 20px;
}
.displauno {
  margin-left: 20px;
}
</style>