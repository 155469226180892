<template>
  <div class="phone" style="height: 700px;">
    <el-row :gutter="4" class="mt15" style="display: flex; margin-left: 0px; margin-top: 10px">
      <!-- 下拉控件-->
      <el-col :span="3">
        <el-select style="width: 100%" class="el-select1" v-model="formInline.value" placeholder="请选择" size="small" @change="changeName">
          <el-option v-for="item in callType" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-date-picker :clearable="false" style="width: 100%" size="small" v-model="formInline.optionDate1" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" @change="changeDate">
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker :clearable="false" style="width: 100%" size="small" v-model="formInline.optionDate2" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions1" @change="changeDate">
        </el-date-picker>
      </el-col>
      <!-- 输入状态 -->
      <el-col :span="3" v-if="formInline.value == 5">
        <el-select style="width: 100%" class="el-select1" v-model="formInline.status" placeholder="请选择" size="small">
          <el-option v-for="item in chatStatus" :key="item.statusValue" :label="item.statusKey" :value="item.statusValue">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" v-if="formInline.value == 5">
        <!-- 输入应用名称 -->
        <el-select style="width: 100%" class="el-select1" v-model="formInline.value1" placeholder="请选择" size="small" @change="searchTypeChanges">
          <el-option v-for="item in searchType1" :key="item.value1" :label="item.label1" :value="item.value1">
          </el-option>
        </el-select>
      </el-col>
      <!-- 聊天信息下拉控件-->
      <el-col :span="3" v-if="formInline.value == 3">
        <el-select style="width: 100%" class="el-select1" v-model="formInline.value2" placeholder="请选择" size="small" @change="searchTypeChange">
          <el-option v-for="item in searchType" :key="item.value2" :label="item.label2" :value="item.value2">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-input size="small" v-model="formInline.searchContent" :placeholder="`请输入${placeholder}`" @keyup.enter.native="onSubmit('ser')"></el-input>
      </el-col>
      <el-col :span="3" style="margin-left: 8px">
        <el-button size="small" type="primary" @click="onSubmit('ser')"> 查询 </el-button>

        <el-button style="margin-left: 2px" size="small" type="primary" @click="outReport">
          导出
        </el-button>
      </el-col>
    </el-row>
    <!-- 电话 -->
    <el-row v-if="formInline.value == 1" style="position: relative; margin-left: 2px; margin-right: 10px">
      <el-table size="mini" :data="tableData" stripe fit tooltip-effect="dark" class="sort_table" style="width: 99%" @sort-change="sort" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }" :default-sort="{ prop: 'createtime', order: 'descending' }">
        <el-table-column :show-overflow-tooltip="true" width="180" label="联系人" prop="contact">
          <template v-slot="{ row }">
            <span v-if="row.contact == '' || row.contact == undefined"> 未知 </span>
            <span v-else>
              {{ row.contact }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="通话号码" prop="mobile"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="地区" prop="area">
          <template v-slot="{ row }">
            <span v-if="row.area == '' || row.area == undefined"> 未知 </span>
            <span v-else>
              {{ row.area }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="通话时长" prop="duration">
          <template v-slot="{ row }">
            <!-- <span v-if="row.duration == '' || row.duration == undefined"> 未知 </span> -->
            <!-- <span v-else> -->
            <span>
              {{  $common.formatDuring(row.duration) }}
              <!-- {{ row.duration}} -->
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="通话类型" prop="type">
          <template v-slot="{ row }">
            <!-- <span v-if="row.type==''">
              未知{{row.type}}
            </span> -->
            <span v-if="row.type == '0'"> 主叫 </span>
            <span v-else-if="row.type == '1'"> 被叫 </span>
            <span v-else-if="row.type == '2'"> 未知 </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="通话时间" prop="createtime"></el-table-column>
      </el-table>
      <div style="margin-left: 5px; margin-right: 15px">
        <pagination :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
      </div>
    </el-row>
    <!-- 短信 -->
    <el-row v-if="formInline.value == 2" :gutter="16" style="position: relative; margin-left: 2px; margin-right: 10px">
      <el-table size="mini" :data="tableData" stripe fit tooltip-effect="dark" class="sort_table" style="width: 99%" @sort-change="sort" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }" :default-sort="{ prop: 'createtime', order: 'descending' }">
        <el-table-column :show-overflow-tooltip="true" width="180" label="联系人" prop="contact">
          <template v-slot="{ row }">
            <span v-if="row.contact == '' || row.contact == undefined"> 未知 </span>
            <span v-else>
              {{ row.contact }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="短信号码" prop="mobile"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="短信内容" prop="content">
          <template v-slot="{ row }">
            <span v-if="row.content == '' || row.content == undefined"> 未知 </span>
            <span v-else>
              {{ row.content }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="短信类型" prop="type">
          <template v-slot="{ row }">
            <!-- <span v-if="row.type==''">
              未知{{row.type}}
            </span> -->
            <span v-if="row.type == '0'"> 发送 </span>
            <span v-else-if="row.type == '1'"> 接收 </span>
            <span v-else-if="row.type == '2'"> 未知 </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="短信时间" prop="createtime"></el-table-column>
      </el-table>
      <div style="margin-left: 5px; margin-right: 15px">
        <pagination :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
      </div>
    </el-row>
    <!-- 聊天信息 -->
    <el-row v-if="formInline.value == 3" :gutter="16" style="position: relative; margin-left: 2px; margin-right: 10px">
      <el-table size="mini" :data="tableData" stripe fit tooltip-effect="dark" class="sort_table" style="width: 99%" @sort-change="sort" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }" :default-sort="{ prop: 'createtime', order: 'descending' }">
        <el-table-column :show-overflow-tooltip="true" width="120" label="应用名称" prop="appName">
          <template v-slot="{ row }">
            <span v-if="row.appname == '' || row.appname == undefined"> 未知 </span>
            <span v-else>
              {{ row.appname }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="本人" prop="username"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="对话人" prop="talker">
          <template v-slot="{ row }">
            <span v-if="row.talker == '' || row.talker == undefined"> 未知 </span>
            <span v-else>
              {{ row.talker }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="内容" prop="content">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="110" label="类别" prop="type">
          <template v-slot="{ row }">
            <span v-if="row.type == '1'"> 文本 </span>
            <span v-if="row.type == '2'"> 名片 </span>
            <span v-if="row.type == '3'"> 照片 </span>
            <span v-if="row.type == '4'"> 文件 </span>
            <span v-if="row.type == '5'"> 语音 </span>
            <span v-if="row.type == '6'"> 引用 </span>
            <span v-if="row.type == '7'"> 转账 </span>
            <span v-if="row.type == '8'"> 红包 </span>
            <span v-if="row.type == '9'"> 表情包 </span>

            <span v-if="row.type == '10'"> 发送位置 </span>
            <span v-if="row.type == '11'"> 音视频聊天 </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="敏感词" prop="words">
          <template v-slot="{ row }">
            <span v-if="row.words == ''"> / </span>
            <span v-else>
              {{ row.words }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="词库名称" prop="sensitiveName">
          <template v-slot="{ row }">
            <span v-if="row.sensitivename == ''"> / </span>
            <span v-else>
              {{ row.sensitivename }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="110" label="状态" prop="status">
          <template v-slot="{ row }">
            <span v-if="row.status == '0'"> 允许 </span>
            <span v-if="row.status == '1'"> 禁止 </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="110" label="类型" prop="isSend">
          <template v-slot="{ row }">
            <span v-if="row.issend == '0'"> 接受 </span>
            <span v-if="row.issend == '1'"> 发送 </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="时间" prop="createtime"></el-table-column>
      </el-table>
      <div style="margin-left: 5px; margin-right: 15px">
        <pagination :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
      </div>
    </el-row>
    <!-- 网络访问 -->
    <el-row v-if="formInline.value == 4" :gutter="16" style="position: relative; margin-left: 2px; margin-right: 10px">
      <el-table size="mini" :data="tableData" stripe fit tooltip-effect="dark" class="sort_table" style="width: 99%" @sort-change="sort" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }" :default-sort="{ prop: 'createtime', order: 'descending' }">
        <el-table-column :show-overflow-tooltip="true" width="180" label="网址" prop="url">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="标题" prop="title"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="180" label="访问状态" prop="type">
          <template v-slot="{row}">
            <span v-if="row.type=='0'">
              允许
            </span>
            <span v-if="row.type=='1'">
              禁止
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="时间" prop="createtime"></el-table-column>
      </el-table>
      <div style="margin-left: 5px; margin-right: 15px">
        <pagination :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
      </div>
    </el-row>
    <!-- 输入信息 -->
    <el-row :gutter="16" style="position: relative; margin-left: 2px; margin-right: 10px" v-if="formInline.value == 5">
      <el-table size="mini" :data="tableData" stripe fit tooltip-effect="dark" class="sort_table" style="width: 100%" @sort-change="sort" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }" :default-sort="{ prop: 'create_time', order: 'descending' }">
        <el-table-column :show-overflow-tooltip="true" width="120" label="应用名称" prop="app_name">
          <template v-slot="{ row }">
            <span v-if="row.appname == ''"> 未知 </span>
            <span v-else>
              {{ row.appname }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="设备名称" prop="device_name">
          <template v-slot="{ row }">
            <span v-if="row.devicename == ''"> 未知 </span>
            <span v-else>
              {{ row.devicename }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="用户名" prop="login_name">
          <template v-slot="{ row }">
            <span v-if="row.loginname == ''"> 未知 </span>
            <span v-else>
              {{ row.loginname }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="部门" prop="group_full_name">
          <template v-slot="{ row }">
            <span v-if="row.groupfullname == ''"> 未知 </span>
            <span v-else>
              {{ row.groupfullname }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="内容" prop="content">
          <template v-slot="{ row }">
            <span v-if="row.content == ''"> 未知 </span>
            <span v-else>
              {{ row.content }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="敏感词" prop="words">
          <template v-slot="{ row }">
            <span v-if="row.words == ''"> / </span>
            <span v-else>
              {{ row.words }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="词库名称" prop="sensitive_name">
          <template v-slot="{ row }">
            <span v-if="row.sensitivename == ''"> / </span>
            <span v-else>
              {{ row.sensitivename }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="状态" prop="status">
          <template v-slot="{ row }">
            <span v-if="row.status == '0'"> 允许 </span>
            <span v-if="row.status == '1'"> 禁止 </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="时间" prop="create_time">
          <template v-slot="{ row }">
            <span v-if="row.createtime == ''"> / </span>
            <span v-else>
              {{ row.createtime }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-left: 5px; margin-right: 15px">
        <pagination :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
      </div>
    </el-row>
  </div>
</template>

<script>
import pagination from '@/components/pagination/page.vue' //分页
export default {
  components: {
    pagination
  },
  data() {
    return {
      // duration:"23758490050",
      pickerOptions1: {},
      pickerOptions0: {},
      // 表单控件
      formInline: {
        optionDate1: '', // 选择开始日期
        optionDate2: '', // 选择结束日期
        value: '1', //默认选中通话类型
        value1: 'appName', //默认选中设备名称
        value2: 'appName', //默认选中设备名称
        searchContent: '', // 查询关键字
        sortStr: 'createTime desc', //排序
        input: '',
        udid: '',
        mobile: '',
        status: '' //聊天状态
      },
      tableData: [], //表格数据
      // 分页
      currentPage: '',
      phonepage: {
        orderColume: 'createTime', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
      page: {
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        // totalPages: 40, //总页数
        currentPage: 1,
        orderColume: 'create_time', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
      //通话类型
      callType: [
        {
          label: '电话',
          value: '1'
        },
        {
          label: '短信',
          value: '2'
        },
        {
          label: '聊天信息',
          value: '3'
        },
        {
          label: '网络访问',
          value: '4'
        },
        {
          label: '输入信息',
          value: '5'
        }
      ],

      //查询类别
      searchType: [
        {
          value2: 'appName',
          label2: '应用名称'
        },
        {
          value2: 'username',
          label2: '本人'
        },
        {
          value2: 'talker',
          label2: '对话人'
        },
        {
          value2: 'words',
          label2: '敏感词'
        },
        {
          value2: 'sensitiveName',
          label2: '词库名称'
        }
      ],
      placeholder: '通话号码',
      //聊天状态
      chatStatus: [
        {
          statusKey: '状态',
          statusValue: ''
        },
        {
          statusKey: '允许',
          statusValue: '0'
        },
        {
          statusKey: '禁止',
          statusValue: '1'
        }
      ],
      //查询类别
      searchType1: [
        {
          value1: 'appName',
          label1: '应用名称'
        },
        {
          value1: 'deviceName',
          label1: '设备名称'
        },
        {
          value1: 'loginName',
          label1: '用户名'
        },
        {
          value1: 'words',
          label1: '敏感词'
        },
        {
          value1: 'sensitiveName',
          label1: '词库名称'
        }
      ],

      placeholder1: '应用名称'
    }
  },
  created() {},
  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.getlist(user)
    // this.outReport(user);
    // conosle.log(user)
    // this.getTableList(); //获取表格数据
  },
  methods: {
    SecondToDate(t) {
      return _SecondToDate(t)
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:0px'
    },
    //日期控件
    changeDate() {
      let start = new Date(this.formInline.optionDate1).getTime()
      let end = new Date(this.formInline.optionDate2).getTime()
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != '') {
            return time.getTime() > Date.now() || time.getTime() > end
          }
          return time.getTime() > Date.now()
        }
      }
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          )
        }
      }
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page
      this.page.pageSize = limit
      // 调用查询方法
      this.onSubmit()
    },
    //排序
    //排序
    sort(column) {
      console.log(column.order, column.prop)
      if (column.order === 'ascending') {
        this.formInline.sortStr = column.prop + ' ASC'
      } else if (column.order === 'descending') {
        this.formInline.sortStr = column.prop + ' DESC'
      }
      this.onSubmit()
    },
    // 增加网络访问
    pushw() {
      // if (this.callType.length == 4) {
      //   return;
      // }
      var obj = {
        label: '网络访问',
        value: '4'
      }
      this.callType.push(obj)
      // this.$forceUpdate();
    },
    // 下拉康
    changeName(item) {
      // 1电话请求的接口 2短信 3聊天信息 4网络访问 5输入信息
      if (item == 1) {
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        this.placeholder = '通话号码'
        this.formInline.searchContent = ''
        this.getlist(user)
        // if (this.callType.length == 4) {
        //   this.callType.pop();
        // }
      } else if (item == 2) {
        this.placeholder = '短信号码'
        this.formInline.searchContent = ''
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        this.note(user)
        // this.pushw();
      } else if (item == 3) {
        this.placeholder = '应用名称'
        this.formInline.searchContent = ''
        this.formInline.value2 = 'appName'
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        this.chat(user)
        // this.pushw();
      } else if (item == 4) {
        this.placeholder = '网址'
        this.formInline.searchContent = ''
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        this.network(user)
      } else if (item == 5) {
        this.placeholder = '应用名称'
        this.formInline.searchContent = ''
        this.formInline.status = ''
        this.formInline.value1 = 'appName'
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        this.inputTab(user)
      }
      this.$forceUpdate()

      console.log(this.formInline.value)
      this.formInline.deviceName = item
      // this.devNameOptions.forEach((k) => {
      //   if (item == k.label) {
      //     this.seleShift = k.value;
      //     console.log(this.seleShift);
      //   }
      // });
    },
    // 排序
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.order === 'ascending') {
        this.page.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.page.orderRule = 'DESC'
      }
      this.page.orderColume = column.prop
      this.onSubmit() //再次调用查询接口
    },
    //获取表格数据 电话
    async getlist(user) {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || '-'
        var nowDate = new Date()
        nowDate.setMonth(nowDate.getMonth() + n)
        var year = nowDate.getFullYear()
        var month = nowDate.getMonth() + 1
        var day = nowDate.getDate()
        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month
        return year + symbol + month + symbol + day
      }
      var getDate = function () {
        var date = new Date()
        var year = date.getFullYear()
        var mon = date.getMonth() + 1
        var day = date.getDate()
        return (
          year +
          '-' +
          (mon < 10 ? '0' + mon : mon) +
          '-' +
          (day < 10 ? '0' + day : day)
        )
      }
      this.formInline.optionDate1 = getAnyMonth('-', -1)
      this.formInline.optionDate2 = getDate()
      this.changeDate()
      var params = {
        // deviceId: user.id,
        deviceUDID: user.deviceUDID,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.phonepage.orderColume,
        orderRule: this.phonepage.orderRule,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        // type: this.formInline.value,
        searchKey: this.formInline.value1,
        searchValue: this.formInline.searchContent
      }
      console.log(params, '电话列表参数---')
      const res = await this.$axios.post(
        // "/api/deviceHistory/telephone/paginate.do",
        'httpServe/deviceHistory/telephone/getDataInfo',
        params,
        true
      )
      console.log(res)
      console.log(res.data, '电话列表数据---')
      this.tableData = res.data.content
      this.currentPage = res.data.pages
      this.page.total = res.data.total
      this.$forceUpdate()
    },
    // 短信
    async note(user) {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || '-'
        var nowDate = new Date()
        nowDate.setMonth(nowDate.getMonth() + n)
        var year = nowDate.getFullYear()
        var month = nowDate.getMonth() + 1
        var day = nowDate.getDate()
        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month
        return year + symbol + month + symbol + day
      }
      var getDate = function () {
        var date = new Date()
        var year = date.getFullYear()
        var mon = date.getMonth() + 1
        var day = date.getDate()
        return (
          year +
          '-' +
          (mon < 10 ? '0' + mon : mon) +
          '-' +
          (day < 10 ? '0' + day : day)
        )
      }
      this.formInline.optionDate1 = getAnyMonth('-', -1)
      this.formInline.optionDate2 = getDate()
      this.changeDate()
      var params = {
        // deviceId: user.id,
        deviceUDID: user.deviceUDID,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.phonepage.orderColume,
        orderRule: this.phonepage.orderRule,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        // type: this.formInline.value,
        searchKey: this.formInline.value1,
        searchValue: this.formInline.searchContent
      }
      console.log(params, '短信列表参数---')
      const res = await this.$axios.post(
        // "/api/deviceHistory/message/paginate.do",
        '/httpServe/deviceHistory/message/getDataInfo',
        params,
        true
      )
      console.log(res)
      console.log(res.data, '短信列表数据---')
      this.tableData = res.data.content
      this.currentPage = res.data.pages
      this.page.total = res.data.total
      this.$forceUpdate()
    },
    // 聊天
    async chat(user) {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || '-'
        var nowDate = new Date()
        nowDate.setMonth(nowDate.getMonth() + n)
        var year = nowDate.getFullYear()
        var month = nowDate.getMonth() + 1
        var day = nowDate.getDate()
        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month
        return year + symbol + month + symbol + day
      }
      var getDate = function () {
        var date = new Date()
        var year = date.getFullYear()
        var mon = date.getMonth() + 1
        var day = date.getDate()
        return (
          year +
          '-' +
          (mon < 10 ? '0' + mon : mon) +
          '-' +
          (day < 10 ? '0' + day : day)
        )
      }
      this.formInline.optionDate1 = getAnyMonth('-', -1)
      this.formInline.optionDate2 = getDate()
      this.changeDate()
      var params = {
        // deviceId: user.id,
        deviceUDID: user.deviceUDID,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.phonepage.orderColume,
        orderRule: this.phonepage.orderRule,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        type: this.formInline.info,
        status: this.formInline.status,
        isSend: this.formInline.send,
        searchKey: this.formInline.value1,
        searchValue: this.formInline.searchContent
      }
      console.log(params, '聊天列表参数---')
      const res = await this.$axios.post(
        // "/api/deviceHistory/chats/paginate.do",
        '/httpServe/deviceHistory/chats/getDataInfo',
        params,
        true
      )
      console.log(res)
      console.log(res.data, '聊天列表数据---')
      this.tableData = res.data.content
      this.currentPage = res.data.pages
      this.page.total = res.data.total
      this.$forceUpdate()
    },
    // 网络
    async network(user) {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || '-'
        var nowDate = new Date()
        nowDate.setMonth(nowDate.getMonth() + n)
        var year = nowDate.getFullYear()
        var month = nowDate.getMonth() + 1
        var day = nowDate.getDate()
        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month
        return year + symbol + month + symbol + day
      }
      var getDate = function () {
        var date = new Date()
        var year = date.getFullYear()
        var mon = date.getMonth() + 1
        var day = date.getDate()
        return (
          year +
          '-' +
          (mon < 10 ? '0' + mon : mon) +
          '-' +
          (day < 10 ? '0' + day : day)
        )
      }
      this.formInline.optionDate1 = getAnyMonth('-', -1)
      this.formInline.optionDate2 = getDate()
      this.changeDate()
      var params = {
        // deviceId: user.id,
        deviceUDID: user.deviceUDID,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.phonepage.orderColume,
        orderRule: this.phonepage.orderRule,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        status: this.formInline.status,
        searchKey: this.formInline.value1,
        searchValue: this.formInline.searchContent
      }
      console.log(params, '网络列表参数---')
      const res = await this.$axios.post(
        // "/api/deviceHistory/browser/paginate.do",
        '/httpServe/deviceHistory/browser/getDataInfo',
        params,
        true
      )
      console.log(res)
      console.log(res.data, '网络列表数据---')
      this.tableData = res.data.content
      this.currentPage = res.data.pages
      this.page.total = res.data.total
      this.$forceUpdate()
    },
    // 输入信息
    async inputTab(user) {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || '-'
        var nowDate = new Date()
        nowDate.setMonth(nowDate.getMonth() + n)
        var year = nowDate.getFullYear()
        var month = nowDate.getMonth() + 1
        var day = nowDate.getDate()
        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month
        return year + symbol + month + symbol + day
      }
      var getDate = function () {
        var date = new Date()
        var year = date.getFullYear()
        var mon = date.getMonth() + 1
        var day = date.getDate()
        return (
          year +
          '-' +
          (mon < 10 ? '0' + mon : mon) +
          '-' +
          (day < 10 ? '0' + day : day)
        )
      }
      this.formInline.optionDate1 = getAnyMonth('-', -1)
      this.formInline.optionDate2 = getDate()
      this.changeDate()
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        deviceUDID: user.deviceUDID,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        type: this.formInline.info,
        status: this.formInline.status,
        searchKey: this.formInline.value1,
        searchValue: this.formInline.searchContent
      }
      console.log(params, '输入列表参数---')

      const res = await this.$axios.post(
        '/httpServe/deviceHistory/input/getDataInfo',
        // '/httpServe/deviceHistory/chats/getDataInfo',
        params,
        true
      )
      console.log(res, '输入列表数据---')
      this.tableData = res.data.content
      this.currentPage = res.data.pages
      this.page.total = res.data.total
    },
    //下拉选中查询类型--转译input中placeholder显示
    searchTypeChange(item) {
      this.searchType.forEach((k) => {
        if (item == k.value2) {
          this.placeholder = k.label2
        }
      })
    },
    searchTypeChanges(item) {
      console.log(item)
      this.searchType1.forEach((k) => {
        if (item == k.value1) {
          this.placeholder = k.label1
        }
      })
    },
    //查询
    async onSubmit(flag) {
      if (this.formInline.value == 1) {
        if (flag == 'ser') {
          this.page.currentPage = 1
        }
        // console.log(this.user)
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        if (this.formInline.searchContent.length > 0) {
          this.page.currentPage = 1
        }
        var params = {
          deviceUDID: user.deviceUDID,
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.phonepage.orderColume,
          orderRule: this.phonepage.orderRule,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          // type: this.formInline.value,
          searchKey: 'mobile',
          searchValue: this.formInline.searchContent
        }
        console.log(params, '电话查询参数---')
        const res = await this.$axios.post(
          'httpServe/deviceHistory/telephone/getDataInfo',
          params,
          true
        )
        console.log(res, '电话查询数据---')
        this.tableData = res.data.content
        this.page.total = res.data.total
      } else if (this.formInline.value == 2) {
        if (flag == 'ser') {
          this.page.currentPage = 1
        }
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        var params = {
          deviceUDID: user.deviceUDID,
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.phonepage.orderColume,
          orderRule: this.phonepage.orderRule,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          // type: this.formInline.value,
          searchKey: 'mobile',
          searchValue: this.formInline.searchContent
        }
        console.log(params, '短信查询参数---')
        const res = await this.$axios.post(
          '/httpServe/deviceHistory/message/getDataInfo',
          params,
          true
        )
        console.log(res, '短信查询数据---')
        this.tableData = res.data.content
        this.page.total = res.data.total
      } else if (this.formInline.value == 3) {
        if (flag == 'ser') {
          this.page.currentPage = 1
        }
        if (this.formInline.searchContent.length > 0) {
          this.page.currentPage = 1
        }
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        var params = {
          deviceUDID: user.deviceUDID,
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.phonepage.orderColume,
          orderRule: this.phonepage.orderRule,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          // type: this.formInline.value,
          searchKey: this.formInline.value1,
          searchValue: this.formInline.searchContent
        }
        console.log(params, '聊天查询参数---')
        const res = await this.$axios.post(
          '/httpServe/deviceHistory/chats/getDataInfo',
          params,
          true
        )
        console.log(res, '聊天查询数据---')
        this.tableData = res.data.content
        this.page.total = res.data.total
      } else if (this.formInline.value == 4) {
        if (flag == 'ser') {
          this.page.currentPage = 1
        }
        if (this.formInline.searchContent.length > 0) {
          this.page.currentPage = 1
        }
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        var params = {
          deviceUDID: user.deviceUDID,
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.phonepage.orderColume,
          orderRule: this.phonepage.orderRule,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          status: '',
          searchKey: 'url',
          searchValue: this.formInline.searchContent
        }
        console.log(params, '网络列表参数---')
        const res = await this.$axios.post(
          '/httpServe/deviceHistory/browser/getDataInfo',
          params,
          true
        )
        console.log(res, '网络查询数据---')
        this.tableData = res.data.content
        this.page.total = res.data.total
      } else if (this.formInline.value == 5) {
        if (flag == 'ser') {
          this.page.currentPage = 1
        }
        if (this.formInline.searchContent.length > 0) {
          this.page.currentPage = 1
        }
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        // //一个月前日期
        // var getAnyMonth = function (symbol, n) {
        //   var symbol = symbol || '-'
        //   var nowDate = new Date()
        //   nowDate.setMonth(nowDate.getMonth() + n)
        //   var year = nowDate.getFullYear()
        //   var month = nowDate.getMonth() + 1
        //   var day = nowDate.getDate()
        //   day = day < 10 ? '0' + day : day
        //   month = month < 10 ? '0' + month : month
        //   return year + symbol + month + symbol + day
        // }
        // var getDate = function () {
        //   var date = new Date()
        //   var year = date.getFullYear()
        //   var mon = date.getMonth() + 1
        //   var day = date.getDate()
        //   return (
        //     year +
        //     '-' +
        //     (mon < 10 ? '0' + mon : mon) +
        //     '-' +
        //     (day < 10 ? '0' + day : day)
        //   )
        // }
        // this.formInline.optionDate1 = getAnyMonth('-', -1)
        // this.formInline.optionDate2 = getDate()
        // this.changeDate()
        var params = {
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.page.orderColume,
          orderRule: this.page.orderRule,
          deviceUDID: user.deviceUDID,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          type: this.formInline.info,
          status: this.formInline.status,
          searchKey: this.formInline.value1,
          searchValue: this.formInline.searchContent
        }
        console.log(params, '输入信息列表参数---')

        const res = await this.$axios.post(
          '/httpServe/deviceHistory/input/getDataInfo',
          // '/httpServe/deviceHistory/chats/getDataInfo',
          params,
          true
        )
        console.log(res, '输入信息列表数据---')
        this.tableData = res.data.content
        this.page.total = res.data.total
      }
    },

    //导出
    async outReport(user) {
      if (this.formInline.value == 1) {
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        // console.log('电话导出')
        let token2 = sessionStorage.getItem('Authorization')
          ? sessionStorage.getItem('Authorization')
          : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A'
        let userString = decodeURIComponent(
          escape(window.atob(token2.split('.')[1]))
        )
        // let userString = decodeURIComponent(
        //   escape(window.atob(this.token.split(".")[1]))
        // );
        this.userId = JSON.parse(userString).userId
        var params = {
          deviceUDID: user.deviceUDID,
          currentPage: this.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.phonepage.orderColume,
          orderRule: this.phonepage.orderRule,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          type: '',
          searchKey: this.formInline.value1,
          searchValue: this.formInline.searchContent,
          exportField: [
            'contact',
            'mobile',
            'area',
            'duration',
            'type',
            'createTime'
          ]
        }
        console.log(params, '电话列表导出参数---')
        const res = await this.$axios.postExport(
          // http://192.168.220.93:8989
          '/httpExport/deviceHistory/telephone/exportExcel',
          params,
          false,
          this.userId
        )
        console.log(res, '电话列表导出---')
        // location.href = "/api/deviceHistory/telephone/exportFile.do";
        const link = document.createElement('a')
        console.info(new Date())

        // let fileName = res.headers["content-disposition"].split("=")[1];
        var fileName = 'Telephone_' + Date.parse(new Date()) + '.xls'
        // console.log(fileName);
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', decodeURI(fileName))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else if (this.formInline.value == 2) {
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        // console.log('短信导出')
        let token2 = sessionStorage.getItem('Authorization')
          ? sessionStorage.getItem('Authorization')
          : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A'
        let userString = decodeURIComponent(
          escape(window.atob(token2.split('.')[1]))
        )
        // let userString = decodeURIComponent(
        //   escape(window.atob(this.token.split(".")[1]))
        // );
        this.userId = JSON.parse(userString).userId
        var params = {
          deviceUDID: user.deviceUDID,
          currentPage: this.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.phonepage.orderColume,
          orderRule: this.phonepage.orderRule,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          type: '',
          searchKey: this.formInline.value1,
          searchValue: this.formInline.searchContent,
          exportField: ['contact', 'mobile', 'content', 'type', 'createTime']
        }
        console.log(params, '短信列表导出参数---')
        const res = await this.$axios.postExport(
          '/httpExport/deviceHistory/message/exportExcel',
          // "/httpExport/deviceHistory/telephone/setExportWhere.do",
          params,
          false,
          this.userId
        )
        console.log(res, '短信列表导出---')
        // location.href = "/api/deviceHistory/telephone/exportFile.do";
        const link = document.createElement('a')
        console.info(new Date())

        // let fileName = res.headers["content-disposition"].split("=")[1];
        var fileName = 'Message_' + Date.parse(new Date()) + '.xls'
        // console.log(fileName);
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', decodeURI(fileName))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else if (this.formInline.value == 3) {
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        // console.log('信息导出')
        let token2 = sessionStorage.getItem('Authorization')
          ? sessionStorage.getItem('Authorization')
          : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A'
        let userString = decodeURIComponent(
          escape(window.atob(token2.split('.')[1]))
        )
        this.userId = JSON.parse(userString).userId
        var params = {
          deviceUDID: user.deviceUDID,
          currentPage: this.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.phonepage.orderColume,
          orderRule: this.phonepage.orderRule,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          type: this.formInline.info,
          status: this.formInline.status,
          isSend: this.formInline.send,
          searchKey: this.formInline.value1,
          searchValue: this.formInline.searchContent,
          exportField: [
            'appName',
            'username',
            'talker',
            'content',
            'type',
            'words',
            'sensitiveName',
            'status',
            'isSend',
            'time'
          ]
        }
        const res = await this.$axios.postExport(
          '/httpExport/deviceHistory/chats/exportExcel',
          // '/httpServe/deviceHistory/chats/exportExcel123',
          params,
          false,
          this.userId
        )
        console.log(res, '信息导出---')
        const link = document.createElement('a')
        console.info(new Date())

        // let fileName = res.headers["content-disposition"].split("=")[1];
        var fileName = ' Chats_' + Date.parse(new Date()) + '.xls'
        // console.log(fileName);
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', decodeURI(fileName))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else if (this.formInline.value == 4) {
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        // console.log('网络信息导出')
        let token2 = sessionStorage.getItem('Authorization')
          ? sessionStorage.getItem('Authorization')
          : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A'
        let userString = decodeURIComponent(
          escape(window.atob(token2.split('.')[1]))
        )
        this.userId = JSON.parse(userString).userId
        var params = {
          deviceUDID: user.deviceUDID,
          currentPage: this.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.phonepage.orderColume,
          orderRule: this.phonepage.orderRule,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          status: this.formInline.status,
          searchKey: this.formInline.value1,
          searchValue: this.formInline.searchContent,
          exportField: ['url', 'title', 'type', 'createTime']
        }
        const res = await this.$axios.postExport(
          '/httpExport/deviceHistory/browser/exportExcel',
          // '/httpServe/deviceHistory/chats/exportExcel123',
          params,
          false,
          this.userId
        )
        console.log(res, '网络信息导出---')
        const link = document.createElement('a')
        console.info(new Date())

        // let fileName = res.headers["content-disposition"].split("=")[1];
        var fileName = ' Browser_' + Date.parse(new Date()) + '.xls'
        // console.log(fileName);
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', decodeURI(fileName))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else if (this.formInline.value == 5) {
        let user = JSON.parse(window.sessionStorage.getItem('user'))
        // console.log('输入信息导出')
        let token2 = sessionStorage.getItem('Authorization')
          ? sessionStorage.getItem('Authorization')
          : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A'
        let userString = decodeURIComponent(
          escape(window.atob(token2.split('.')[1]))
        )
        this.userId = JSON.parse(userString).userId
        var params = {
          currentPage: this.currentPage,
          pageSize: this.page.pageSize,
          orderColume: this.page.orderColume,
          orderRule: this.page.orderRule,
          deviceUDID: user.deviceUDID,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          type: this.formInline.info,
          status: this.formInline.status,
          searchKey: this.formInline.value1,
          searchValue: this.formInline.searchContent,
          exportField: [
            'appName',
            'deviceName',
            'loginName',
            'groupFullName',
            'content',
            'words',
            'sensitiveName',
            'status',
            'createTime'
          ]
        }
        const res = await this.$axios.postExport(
          '/httpExport/deviceHistory/input/exportExcel',
          // '/httpServe/deviceHistory/chats/exportExcel123',
          params,
          false,
          this.userId
        )
        console.log(res, '输入信息导出---')
        const link = document.createElement('a')
        console.info(new Date())

        // let fileName = res.headers["content-disposition"].split("=")[1];
        var fileName = ' Input_' + Date.parse(new Date()) + '.xls'
        // console.log(fileName);
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', decodeURI(fileName))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    changeType() {}
  }
}
</script>

<style lang="scss" scoped>
.mt15 {
  margin: 0 0 10px 12px;
}
.phone {
  padding-left: 10px;
}
</style>
