<template>
  <div style="height: 700px; overflow: auto">
    <!-- 20为安卓   10为ios -->
    <el-table size="small" stripe fit ref="multipleTable" tooltip-effect="dark" class="sort_table" style="width: 99%; margin: 8px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :data="form">
      <el-table-column label="序号" :show-overflow-tooltip="true" width="155" type="index" prop="userInfo.userName">
      </el-table-column>
      <el-table-column label="应用名称" :show-overflow-tooltip="true" width="290" prop="appName" v-if="user.osId == 20">
      </el-table-column>
      <el-table-column label="应用名称" :show-overflow-tooltip="true" width="290" prop="name" v-if="user.osId == 10">
      </el-table-column>
      <el-table-column label="应用ID" :show-overflow-tooltip="true" width="290" prop="packageName" v-if="user.osId == 20">
      </el-table-column>
      <el-table-column label="应用ID" :show-overflow-tooltip="true" width="290" prop="identifier" v-if="user.osId == 10">
      </el-table-column>
      <el-table-column label="版本" :show-overflow-tooltip="true" width="290" prop="versionName" v-if="user.osId == 20">
      </el-table-column>
      <el-table-column label="版本" :show-overflow-tooltip="true" width="290" prop="shortVersion" v-if="user.osId == 10">
      </el-table-column>
      <el-table-column label="大小" :show-overflow-tooltip="true" width="290" prop="appSize" v-if="user.osId == 20">
      </el-table-column>
      <el-table-column label="大小" :show-overflow-tooltip="true" width="290" prop="bundleSize" v-if="user.osId == 10">
        <template v-slot="{ row }">
          <template v-if="row.bundleSize == '0B'"> N/A </template>
          <template v-else> {{row.bundleSize}} </template>
        </template>
      </el-table-column>
      <el-table-column label="应用类型" :show-overflow-tooltip="true" prop="preventScreenshot">
        <template v-slot="{ row }">
          <template v-if="row.preventScreenshot == 0"> 防护应用 </template>
          <template v-else> 普通应用 </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      user: {
        osId: ''
      }
    }
  },

  created() {},

  mounted() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    this.user = user
    setTimeout(() => {
      console.log(this.user.osId)
    }, 3000)
    this.getlist(user)
  },

  methods: {
    async getlist(user) {
      var param = {
        deviceId: user.id,
        deviceUDID: user.deviceUDID,
        osType: user.osId == 20 ? 'Android' : 'iOS'
        // status: user.status,
        // rowNumber: 10,
      }
      // console.log(data);
      const res = await this.$axios.post(
        // "api/webSoftware/deviceSoftwareInfo.do",
        'httpServe/webSoftware/deviceSoftwareInfo',
        param,
        true
      )
      console.log(res)
      this.form = res.data
    }
  }
}
</script>

<style lang="scss" scoped></style>
