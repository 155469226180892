<template>
  <div class="bigbox" style="margin-top: 30px; margin-left: 60px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="设备名称：" prop="deviceName">
        <el-input
          v-model="ruleForm.deviceName"
          maxlength="20"
          style="width: 270px"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名：" prop="userInfo">
        <el-input
          :disabled="true"
          size="small"
          v-model="ruleForm.userInfo"
          style="width: 270px"
        ></el-input>
        <el-button
          @click="subscriber"
          type="primary"
          size="small"
          style="margin-left: 10px; border-radius: 15px"
          icon="el-icon-plus"
          >用户</el-button
        >
      </el-form-item>
      <el-form-item label="设备归属：" prop="belong">
        <el-select
          size="small"
          style="width: 270px"
          v-model="ruleForm.belong"
          placeholder="请选择"
          @change="selechan"
        >
          <!-- <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option> -->
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="安装类型：">
        <el-checkbox v-model="ruleForm.appType"
          ><span style="color: #606266">必装应用</span></el-checkbox
        >
      </el-form-item> -->
      <el-form-item label="操作系统：">
        <!-- <el-radio-group v-model="form.resource"> -->
        <el-radio label="20" v-model="ruleForm.osId" disabled>Android</el-radio>
        <el-radio label="10" v-model="ruleForm.osId" disabled>iOS</el-radio>
        <!-- </el-radio-group> -->
      </el-form-item>
      <!-- <el-form-item label="应用简介：">
        <el-input type="textarea" v-model="ruleForm.memo" style="width: 215px"></el-input>
      </el-form-item> -->
      <el-form-item label="IMEI：" prop="imei" v-if="ruleForm.osId == 20">
        <el-input
          v-model="ruleForm.imei"
          style="width: 270px"
          size="small"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="UDID：" prop="deviceUDID" v-if="ruleForm.osId == 10">
        <el-input
          v-model="ruleForm.deviceUDID"
          style="width: 270px"
          size="small"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="submitForm('ruleForm')" type="primary"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 保存弹框 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!-- 用户 -->
    <Eldialog
      @handleClose="handleClose"
      :title="'选择用户'"
      :dialogVisible="dialogVisible"
      @heightCustom="heightCustom"
      @determine="joinType"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      :btnTitle="'加入'"
    >
      <subscriber @aaa="handleClose" :dictData="dataFrom" />
      <div style="display: flex; width: 565px; justify-content: space-between">
        <!-- <p style="margin-top: 15px">
          共<b>{{ this.dataTolCount }}</b
          >条记录
        </p> -->
        <!-- <p>
          <el-button
            @click="joinType"
            style="margin-left: 540px"
            type="primary"
            size="small"
            >加入</el-button
          >
        </p> -->
      </div>
    </Eldialog>
  </div>
</template>
<script>
// 弹框
import Eldialog from "@/components/elDialog";
import dialogInfo from "@/components/promptMessage/index";
// 用户
import subscriber from "./subscriber.vue";
export default {
  components: { Eldialog, dialogInfo, subscriber },
  props: {
    androidData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      numberid: 1,
      titleInfo: "保存成功",
      visibaelFlag: false,
      queryObj: {
        manage: 1,
        // selectcont: "",
        belong: "",
      },
      options: [],
      osId: "20",
      ruleForm: {
        deviceName: "", //设备名称
        userInfo: "", //用户名
        belong: "", //设备归属
        osId: "20", //操作系统
        imei: "", //IMEL
        ownerId: "",
        deviceUDID: "",
        id:"",
        ids: "",
      },
      id: "",
      rules: {
        userInfo: [{ required: true, message: "请选择用户", trigger: "blur" }],
        // belong: [{ required: true, message: "请选择应用类别", trigger: "change" }],
        // department: [{ required: true, message: "请输入建设单位", trigger: "blur" }],
        deviceName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          // {
          //   validator: (rule, value, callback) => {
          //     if (/^[\u4e00-\u9fa5\w]+$/.test(value) == false) {
          //       callback(new Error('不支持空格及特殊字符'))
          //     } else {
          //       callback()
          //     }
          //   },
          //   trigger: 'blur' //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          // },
          { min: 1, max: 20, message: "最长输入20个字符", trigger: "blur" },
        ],
        // names: [
        //   { required: true, message: "请选择用户", trigger: "blur" },
        //   //   { min: 1, max: 20, message: "最长输入20个字符", trigger: "blur" },
        // ],
        // imei: [
        //   { required: true, message: "请输入IMEL", trigger: "blur" },
        //   { min: 1, max: 17, message: "最长输入17个字符", trigger: "blur" },
        // ],
        // deviceUDID: [
        //   { required: true, message: "请输入UDID", trigger: "blur" },
        //   {
        //     validator: (rule, value, callback) => {
        //       if (
        //         !/^[a-fA-F0-9]{8}-[a-fA-F0-9]{16}$/.test(value) == true ||
        //         !/^[a-f0-9]{40}$/.test(value) == true
        //       ) {
        //         callback(new Error("UDID必须是正确的UDID"));
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
        //   },
        // ],
      },
      options2: [
        {
          value: "1",
          label: "企业",
        },
        {
          value: "2",
          label: "个人",
        },
      ],
      path: "",
      dataFrom: {}, //应用详情组件
      dialogVisible: false, //详情弹框默认--关闭
      deviced: {},
    };
  },
  mounted() {
    // devicedeit   ruleForm
    let deviced = JSON.parse(window.sessionStorage.getItem("devicedeit"));
    console.log(deviced, "编辑内容");
    this.ruleForm.ids = deviced.id;
    this.ruleForm.deviceName = deviced.deviceName;
    this.ruleForm.ownerId = deviced.ownerId;
    this.ruleForm.userInfo = `${deviced.userInfo.loginName}(${deviced.userInfo.userName})`;
    this.ruleForm.belong = deviced.belong+'';
    // this.ruleForm.belong = deviced.belong == "1" ? "企业" : "个人";
    //   this.ruleForm.osId = res.data.deviceOs.id;
    this.ruleForm.osId = deviced.osId + "";
    //   this.ruleForm.osId = res.data.osId=20?"Android":"ios";
    // console.log(this.ruleForm.osId);
    this.ruleForm.imei = deviced.imei;
    this.ruleForm.deviceUDID = deviced.deviceUDID;
  },
  methods: {
    // 用户
    subscriber() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.visibaelFlag = false;
      this.dialogVisible = false;
    },
    heightCustom() {
      this.height.height = this.num;
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false;
    },
    // 保存按钮
    async saveSubmit() {
      if (window.sessionStorage.getItem("ws")) {
        this.ruleForm.ownerId = window.sessionStorage.getItem("ws");
        this.id = window.sessionStorage.getItem("ws");
        window.sessionStorage.removeItem("ws");
      }
      let params = {
        ids: [this.ruleForm.ids],
        deviceName: this.ruleForm.deviceName,
        osId: this.ruleForm.osId,
        belong: this.ruleForm.belong,
        ownerId: Number(this.ruleForm.ownerId),
        imei: this.ruleForm.imei,
      };
      console.log(params);
      const res = await this.$axios.post(
        "/httpServe/device/update",
        params,
        true
      );
      console.log(res);
      if (res.code == 200) {
        var flag = 1;
        this.$emit("sunline", flag);
      }
      if (res.code == 101) {
        // this.titleInfo = "保存失败，该设备名称已存在";
        // this.visibaelFlag = true;
        this.$message({
          message: '保存失败，该设备名称已存在',
          type: 'error',
          offset:100
        });
      }
      if (res.data == 49) {
        // this.titleInfo = "IMEI已存在";
        this.ruleForm = {};
        // this.visibaelFlag = true;
        this.$message({
          message: 'IMEI已存在',
          type: 'error',
          offset:100
        });
      }
      if (res.data == 1) {
        this.$refs.ruleForm.resetFields();
        //   this.ruleForm.memo = "";
        this.ruleForm.appType = false;
        this.$emit("sunline");
      }
      //   }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveSubmit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    selechan(el) {
      this.numberid = el;
    },
    handleAvatarSuccess1() {},
    // 加入
    joinType() {
      var data = JSON.parse(window.sessionStorage.getItem("irem"));
      // this.ruleForm.deviceName=
      this.ruleForm.userInfo = `${data.loginName}(${data.userName})`;
      // this.ruleForm.belong=
      // this.ruleForm.osId=
      // this.ruleForm.imei=
      // this.ruleForm.ownerId=
      // this.ruleForm.memo=

      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .box-corn {
  overflow: hidden !important;
}
::v-deep #detailBox .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  /* height: 469px !important; */
  overflow: hidden !important;
  margin-right: 12px !important;
}
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
<style>
/* .el-tree-node__content>label.el-checkbox {
    margin-right: -104px !important;
} */
</style>